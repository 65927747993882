export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getGoals(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/driver/goals/index?paginate&page=" + (params.page ?? 1) + (params.filter ?? ''))
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getActionTypes() {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/driver/goals/action-types/index")
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteGoal(_, goalId) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/driver/goals/destroy/" + goalId
                ).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        updateGoal(_, data) {
            return new Promise((resolve, reject) => {
                let newData = new FormData
                newData.append('title', data.newGoal.title)
                newData.append('title_button', data.newGoal.title_button)
                newData.append('count', data.newGoal.count)
                newData.append('action_type', data.newGoal.action_type)
                if (data.newGoal.start_image)
                    newData.append('start_image', data.newGoal.start_image)
                if (data.newGoal.end_image)
                    newData.append('end_image', data.newGoal.end_image)
                if (data.newGoal.qr_code)
                    newData.append('qr_code', data.newGoal.qr_code)
                window.axios.post("/admin/driver/goals/update/" + data.id, newData).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        createGoal(_, data) {
            return new Promise((resolve, reject) => {
                let newData = new FormData
                newData.append('title', data.title)
                newData.append('title_button', data.title_button)
                newData.append('action_type', data.action_type)
                newData.append('count', data.count)
                newData.append('start_image', data.start_image)
                newData.append('end_image', data.end_image)
                newData.append('qr_code', data.qr_code)

                window.axios.post("/admin/driver/goals/create", newData).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
    },
}
