export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getAdmins() {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/admin/crud/index")
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getPaginatedAdmins(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/admin/crud/index?paginate&page=" + (params.page ?? 1) + (params.filter ?? ''))
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        createAdmin({commit, dispatch}, data) {
            return new Promise((resolve, reject) => {
                window.axios.post("/admin/admin/crud/create", {
                    name: data.name,
                    email: data.email,
                    password: data.password,
                    role_id: data.role_id,
                }).then((res) => {
                    commit('setLoading', false)
                    dispatch('getProfile')
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        updateAdmin({dispatch}, data) {
            return new Promise((resolve, reject) => {
                window.axios.post("/admin/admin/crud/update/" + data.id, {
                    name: data.newAdmin.name,
                    email: data.newAdmin.email,
                    password: data.newAdmin.password,
                    role_id: data.newAdmin.role_id,
                }).then((res) => {
                    dispatch('getProfile')
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteAdmin({dispatch}, adminId) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/admin/crud/destroy/" + adminId
                ).then((res) => {
                    dispatch('getProfile')
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        showAdminDetails({commit}, adminId) {
            return new Promise((resolve, reject) => {
                return window.axios.get("/admin/admin/crud/show/" + adminId).then((res) => {
                    resolve(res)
                    commit('setLoading', false)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    },
}
