<template>
  <div v-if="trip" class="mt-16">
    <!--SNACK BAR  -->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" class="snackbar-p"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <!--CANCELLATION CHARGE DIALOG-->
    <v-dialog v-model="chargeCancellationDialog" centered min-width="25vw" width="500px">
      <v-card>
        <v-card-title class="delete-dialog-text">
          Cancellation Charge
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="cancelChargeAmount" label="Amount"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="text-center">
              <v-btn rounded @click="chargeCancellation" color="#50cd89" width="100%" class="white--text">
                Submit
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn rounded @click="chargeCancellationDialog = false" color="#F1416C" width="100%" class="white--text">
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row class="mt-16 ml-1 mr-1" v-can="['show_trip']">
      <v-col cols="12">
        <div class="text-center title-container">Trip Details</div>
        <v-row class="mlr-1">
          <v-col cols="12" class="pa-0">
            <v-btn text @click="$router.go(-1)" class="pa-0">
              <v-icon x-large color="#00b2ff">mdi-arrow-left</v-icon>
              &nbsp; Back
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mx-5">
          <v-col cols="12">
            <div class="mt-5 mb-10">
              <h2 class="section-title">Basic Information</h2>
              <v-divider></v-divider>
            </div>
            <v-row>
              <v-col cols="12" xl="4" lg="4" md="6" sm="12">
                <span class="title-font-size">Origin Address:</span> &nbsp;{{ trip.origin_address }}
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="6" sm="12">
                <span class="title-font-size">Destination Address:</span> &nbsp;{{ trip.destination_address }}
              </v-col>
              <v-col v-if="trip.states" cols="12" xl="4" lg="4" md="6" sm="12">
                <span class="title-font-size">Status:</span> &nbsp;{{ getStateName(trip.states) }}
              </v-col>
              <v-col v-if="trip.price" cols="12" xl="4" lg="4" md="6" sm="12">
                <span class="title-font-size"> Price:</span> &nbsp;{{ trip.price }}
              </v-col>
              <v-col v-if="trip.tip" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Tip:</span> &nbsp;{{ trip.tip }}
              </v-col>
              <v-col v-if="trip.eta" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">ETA:</span> {{ trip.eta }} sec ({{ trip.eta | momentDuration }})
              </v-col>
              <v-col v-if="trip.distance" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Distance:</span> &nbsp;{{ trip.distance / 1000 }} &nbsp; Km
              </v-col>
              <v-col v-if="trip.start_time" cols="12" xl="4" lg="4" md="6" sm="12">
                <span class="title-font-size">Start Time:</span> &nbsp;{{ trip.start_time }}
              </v-col>
              <v-col v-if="trip.end_time" cols="12" xl="4" lg="4" md="6" sm="12">
                <span class="title-font-size"> End Time:</span> &nbsp;{{ trip.end_time }}
              </v-col>
              <v-col v-if="trip.surge_multiplier" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Surge Multiplier:</span> &nbsp;{{ trip.surge_multiplier }}
              </v-col>
              <v-col v-if="trip.rate_from_passenger" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Rate From Passenger:</span> &nbsp;{{ trip.rate_from_passenger }}
              </v-col>
              <v-col v-if="trip.rate_from_driver" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Rate From Driver:</span> &nbsp;{{ trip.rate_from_driver }}
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="12"
                     v-if="trip.trip_details && trip.trip_details.date_time_accepted">
                <span class="title-font-size">Accepted at : </span> &nbsp;{{ trip.trip_details.date_time_accepted }}
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="12"
                     v-if="trip.trip_details && trip.trip_details.date_time_pickup">
                <span class="title-font-size">Pickup at : </span> &nbsp;{{ trip.trip_details.date_time_pickup }}
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="12"
                     v-if="trip.trip_details && trip.trip_details.date_time_arrive">
                <span class="title-font-size">Arrive at : </span> &nbsp;{{ trip.trip_details.date_time_arrive }}
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="12"
                     v-if="trip.trip_details && trip.trip_details.date_time_completed">
                <span class="title-font-size">Completed at : </span> &nbsp;{{ trip.trip_details.date_time_completed }}
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="12"
                     v-if="trip.trip_details && trip.trip_details.date_time_cancel_by_driver">
                <span class="title-font-size">Cancel by driver at : </span>
                &nbsp;{{ trip.trip_details.date_time_cancel_by_driver }}
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="12"
                     v-if="trip.trip_details && trip.trip_details.date_time_cancel_by_passenger">
                <span class="title-font-size">Cancel by passenger at : </span>
                &nbsp;{{ trip.trip_details.date_time_cancel_by_passenger }}
              </v-col>
            </v-row>
            <div class="mt-5 mb-10">
              <h2 class="section-title">Passenger Information</h2>
              <v-divider></v-divider>
            </div>
            <v-row>
              <v-col cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Name:</span> &nbsp;{{ trip.user.name }}
              </v-col>
              <v-col v-if="trip.user.phone_number" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Phone Number:</span> &nbsp;{{ trip.user.phone_number }}
              </v-col>
              <v-col v-if="trip.user.email" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Email:</span> &nbsp;{{ trip.user.email }}
              </v-col>
              <v-col v-if="trip.pm_last_four" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Payment Last Four Number:</span> &nbsp;{{ trip.user.pm_last_four }}
              </v-col>
              <v-col v-if="trip.last_payment_option_id" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Last Payment Option Id:</span> &nbsp;{{
                  trip.user.last_payment_option_id
                }}
              </v-col>
              <v-col v-if="trip.trial_ends_at" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Trial Ends At:</span> &nbsp;{{ trip.user.trial_ends_at.substring(0, 10) }}
              </v-col>
              <v-col v-if="trip.user.trial_ends_at" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Trial Ends At:</span> &nbsp;{{ trip.user.trial_ends_at.substring(0, 10) }}
              </v-col>
              <v-col v-if="trip.user.created_at" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Created At:</span> &nbsp;{{ trip.user.created_at }}
              </v-col>
              <v-col v-if="trip.user.updated_at" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Updated At:</span> &nbsp;{{ trip.user.updated_at }}
              </v-col>
            </v-row>
            <div v-if="trip.driver" class="mt-5 mb-10">
              <h2 class="section-title">Driver Information</h2>
              <v-divider></v-divider>
            </div>
            <v-row v-if="trip.driver">
              <v-col cols="12" xl="2" lg="2" md="12" sm="12">
                <v-col v-if="trip.driver.headshot_picture" cols="12">
                  <v-card class="item-card">
                    <div class="item-image-container">
                      <img class="item-image" :src="trip.driver.headshot_picture" :alt="'driver headshot picture'">
                    </div>
                  </v-card>
                </v-col>
              </v-col>
              <v-col cols="12" xl="10" lg="10" md="12" sm="12">
                <v-row class="mt-16">
                  <v-col v-if="trip.driver.active" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span v-if="trip.driver.active===true" class="title-font-size">Online<span
                        class="mt-1 online-dot">●</span></span><span
                      v-if="trip.driver.active===false" class="title-font-size">Offline<span
                      class="mt-1 offline-dot">●</span></span>
                  </v-col>
                  <v-col v-if="trip.driver.status" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span v-if="trip.driver.status===true"><span class="title-font-size">Status:</span> Active</span>
                    <span v-if="trip.driver.status===false"><span class="title-font-size">Status:</span> Inactive</span>
                  </v-col>
                  <v-col cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Full Name:</span> &nbsp;{{
                      trip.driver.first_name
                    }}&nbsp;{{ trip.driver.last_name }}
                  </v-col>
                  <v-col v-if="trip.driver.nick_name" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Nick Name:</span> &nbsp;{{ trip.driver.nick_name }}
                  </v-col>
                  <v-col v-if="trip.driver.phone_number" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Phone Number:</span> &nbsp;{{ trip.driver.phone_number }}
                  </v-col>
                  <v-col cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Alternate Phone Number:</span>
                    &nbsp;{{ trip.driver.alternate_phone_number }}
                  </v-col>
                  <v-col v-if="trip.driver.email" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Email:</span> &nbsp;{{ trip.driver.email }}
                  </v-col>
                  <v-col v-if="trip.driver.address" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Address:</span> &nbsp;{{ trip.driver.address }}
                  </v-col>
                  <v-col v-if="trip.driver.emergency_contact_name" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Emergency Contact Name:</span>
                    &nbsp;{{ trip.driver.emergency_contact_name }}
                  </v-col>
                  <v-col v-if="trip.driver.emergency_contact_phone_number" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Emergency Contact Phone Number:</span>
                    &nbsp;{{ trip.driver.emergency_contact_phone_number }}
                  </v-col>
                  <v-col v-if="trip.driver.created_at" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Created At:</span> &nbsp;{{ trip.driver.created_at }}
                  </v-col>
                  <v-col v-if="trip.driver.updated_at" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Updated At:</span> &nbsp;{{ trip.driver.updated_at }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div v-if="trip.vehicle_type" class="mt-5 mb-10">
                  <h2 class="section-title">Vehicle Information</h2>
                  <v-divider></v-divider>
                </div>
                <v-row v-if="trip.vehicle_type">
                  <v-col cols="12">
                    Vehicle Type: {{ trip.vehicle_type.title }}
                  </v-col>
                  <v-col cols="12">
                    Base Rate: {{ trip.p_base_rate }}
                  </v-col>
                  <v-col cols="12">
                    Price Per Kilometer:&nbsp;{{ trip.p_per_kilometer }}
                  </v-col>
                  <v-col cols="12">
                    Price Per Minute:&nbsp;{{ trip.p_per_minute }}
                  </v-col>
                  <v-col cols="12" v-if="trip.vehicle_type.created_at">
                    Created At: &nbsp;{{ trip.vehicle_type.created_at }}
                  </v-col>
                  <v-col cols="12" v-if="trip.vehicle_type.updated_at">
                    Updated At: &nbsp;{{ trip.vehicle_type.updated_at }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <div v-if="trip.receipt" class="mt-5 mb-10">
                  <h2 class="section-title">Receipt Information</h2>
                  <v-divider></v-divider>
                </div>
                <v-row v-if="trip.receipt" dense>
                  <v-col cols="6">
                    Total Price (GST included / Tips not included): ${{ trip.receipt.total_price }}
                  </v-col>
                  <v-col cols="12">
                    Tip: ${{ trip.receipt.tip }}
                  </v-col>
                  <v-col cols="12">
                    Driver Share (GST Included): ${{ trip.receipt.driver_share }} (GST: ${{ trip.receipt.driver_gst }})
                  </v-col>
                  <v-col cols="12">
                    Company Share (GST Included): ${{ trip.receipt.company_share }} (GST ${{
                      trip.receipt.company_gst
                    }})
                  </v-col>
                  <v-col cols="12">
                    Partner Share (GST Included): ${{ trip.receipt.partner_share }}
                  </v-col>
                  <v-col cols="12">
                    Municipal License recovery fee: ${{ trip.receipt.p_fee_lmm }}
                  </v-col>
                  <v-col cols="12">
                    Provincial License recovery fee : ${{ trip.receipt.p_fee_ptb }}
                  </v-col>
                  <v-col cols="12">
                    Vancouver curbside congestion management fee:
                    ${{ trip.receipt.p_tax_ccmp_pickup + trip.receipt.p_tax_ccmp_dropoff }}
                    <br>
                    ---Pickup: ${{ trip.receipt.p_tax_ccmp_pickup }}
                    <br>
                    ---Dropoff: ${{ trip.receipt.p_tax_ccmp_dropoff }}
                  </v-col>

                </v-row>
              </v-col>
            </v-row>
            <!--        Trip Rout On Map-->
            <div v-if="trip.origin_lat" class="mt-5 mb-10">
              <v-row style="width: 100%; margin: 0">
                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <h2 class="section-title">
                    Trip Route
                  </h2>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12"
                       style="text-align: right; margin-right: 0; padding-right: 0">
                  <v-btn v-can="'cancellation_charge'" color="#F1416C" class="white--text" rounded
                         @click="chargeCancellationDialog = true">Charge Cancellation
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </div>
            <v-row class="mb-16">
              <v-col cols="12">
                <h3>Hints</h3>
                <v-row>
                  <v-col>1: Accepted</v-col>
                  <v-col>2: Pickup</v-col>
                  <v-col>3: Arrive</v-col>
                  <v-col>4: Completed</v-col>
                  <v-col>5: Cancelled by Driver</v-col>
                  <v-col>6: Cancelled by Passenger</v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="map-container">
                <GmapMap
                    :clickable="true"
                    :center="center"
                    :zoom="11"
                    map-type-id="terrain"
                    style="width: 100%; height: 100%"
                    ref="myMap"
                    :options="mapOptions">
                  <DirectionRendererSingle
                      travelMode="DRIVING"
                      :origin="{lat: trip.origin_lat, lng: trip.origin_lng}"
                      :destination="{lat: trip.destination_lat, lng: trip.destination_lng}"
                  />
                  <gmap-marker
                      v-if="trip.trip_details && trip.trip_details.loc_lat_accepted"
                      :position="{lat: trip.trip_details.loc_lat_accepted, lng: trip.trip_details.loc_lng_accepted}"
                      :draggable="false"
                      :options="{label: {
                    text: '1',
                    fontSize: '12px',
                    fontWeight: 'Bold',
                    color: 'white'
                  }}"
                      :animation="2">
                  </gmap-marker>
                  <gmap-marker
                      v-if="trip.trip_details &&  trip.trip_details.loc_lat_pickup"
                      :position="{lat: trip.trip_details.loc_lat_pickup, lng: trip.trip_details.loc_lng_pickup}"
                      :draggable="false"
                      :options="{label: {
                    text: '2',
                    fontSize: '12px',
                    fontWeight: 'Bold',
                    color: 'white'
                  }}"
                      :animation="2">
                  </gmap-marker>
                  <gmap-marker
                      v-if="trip.trip_details && trip.trip_details.loc_lat_arrive"
                      :position="{lat: trip.trip_details.loc_lat_arrive, lng: trip.trip_details.loc_lng_arrive}"
                      :draggable="false"
                      :options="{label: {
                    text: '3',
                    fontSize: '12px',
                    fontWeight: 'Bold',
                    color: 'white'
                  }}"
                      :animation="2">
                  </gmap-marker>
                  <gmap-marker
                      v-if="trip.trip_details &&  trip.trip_details.loc_lat_completed"
                      :position="{lat: trip.trip_details.loc_lat_completed, lng: trip.trip_details.loc_lng_completed}"
                      :draggable="false"
                      :options="{label: {
                    text: '4',
                    fontSize: '12px',
                    fontWeight: 'Bold',
                    color: 'white'
                  }}"
                      :animation="2">
                  </gmap-marker>
                  <gmap-marker
                      v-if="trip.trip_details && trip.trip_details.loc_lat_cancel_by_driver"
                      :position="{lat: trip.trip_details.loc_lat_cancel_by_driver, lng: trip.trip_details.loc_lng_cancel_by_driver}"
                      :draggable="false"
                      :options="{label: {
                    text: '5',
                    fontSize: '12px',
                    fontWeight: 'Bold',
                    color: 'white'
                  }}"
                      :animation="2">
                  </gmap-marker>
                  <gmap-marker
                      v-if="trip.trip_details &&  trip.trip_details.loc_lat_cancel_by_passenger"
                      :position="{lat: trip.trip_details.loc_lat_cancel_by_passenger, lng: trip.trip_details.loc_lng_cancel_by_passenger}"
                      :draggable="false"
                      :options="{label: {
                    text: '6',
                    fontSize: '12px',
                    fontWeight: 'Bold',
                    color: 'white'
                  }}"
                      :animation="2">
                  </gmap-marker>

                </GmapMap>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </div>
  <div v-else class="mt-16">
    trip does not exist.
  </div>
</template>

<script>
import DirectionRendererSingle from "@/components/GoogleMap/DirectionRendererSingle";
import {gmapApi} from "vue2-google-maps";
import {getAdminPermissions} from "@/middleware/hasPermission";

export default {
  name: "ShowTripDetails",
  components: {DirectionRendererSingle},
  data() {
    return {
      trip: null,
      center: {lat: 49.252646732280425, lng: -123.02589291692055},
      mapOptions: {
        disableDefaultUI: true,
        zoomControl: true,
      },
      chargeCancellationDialog: false,
      cancelChargeAmount: null,
    }
  },
  mounted() {
    let adminPermissions = getAdminPermissions()
    if (adminPermissions.includes('show_trip')) {
      this.showTripDetails()
    }
  },
  computed: {
    google: gmapApi
  },
  methods: {
    showTripDetails() {
      this.$store.dispatch('showTripDetails', this.$route.params.id).then((res) => {
        this.trip = res.data.data
        console.log(this.trip)
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
    getStateName(state) {
      switch (state) {
        case 'idle':
          return 'idle';
        case 'searching_ride':
          return 'Searching For Ride';
        case 'wait_for_ride':
          return 'Waiting For Ride';
        case 'in_trip':
          return 'In Trip';
        case 'trip_finished':
          return 'Trip Finished';
        case 'cancelled_by_user':
          return 'Cancelled By User';
        case 'cancelled_by_driver':
          return 'Cancelled By Driver';
        default:
          return state;
      }
    },
    chargeCancellation() {
      // this.$store.dispatch('createDriver', this.driver).then(() => {
      //   this.getDrivers()
      //   this.createOrUpdateDriverDialog = false
      //   this.openSnackbar('New Driver Created Successfully', '#50cd89')
      // }).catch((err) => {
      //   if (err.response.status === 422) {
      //     this.showValidationErrors(err.response.data.errors)
      //   } else {
      //     this.openSnackbar(err.response.data.message, '#F1416C')
      //   }
      // });
      window.axios.post('admin/user/charge/cancellation', {
        user_id: this.trip.user_id,
        trip_id: this.trip.id,
        amount: this.cancelChargeAmount
      }).then(() => {
        this.cancelChargeAmount = null
        this.chargeCancellationDialog = false;
        this.openSnackbar('Cancellation Charge Applied', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    }
  },
}
</script>
<style scoped>
.section-title {
  width: fit-content;
}

.online-dot {
  color: #50cd89;
  font-size: 20px;
}

.offline-dot {
  color: #F1416C;
  font-size: 20px;
}

.item-card {
  padding: 20px 10px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.item-image-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.item-image {
  height: fit-content;
  width: 100%;
  border-radius: 15px;
}

.title-font-size {
  font-size: 18px
}

.map-container {
  height: 500px;
}
</style>