export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getPaginatedLostItems(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/trip/lost-item/index?paginate&page=" + (params.page ?? 1) + (params.filter ?? ''))
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteLostItem(_, lostItemId) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/trip/lost-item/destroy/" + lostItemId
                ).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        showLostItemDetails({commit}, lostItemId) {
            return new Promise((resolve, reject) => {
                return window.axios.get("/admin/trip/lost-item/show/" + lostItemId).then((res) => {
                    resolve(res)
                    commit('setLoading', false)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    },
}
