<template>
  <div>
    <login-admin></login-admin>
  </div>
</template>

<script>

import LoginAdmin from '@/components/Auth/LoginAdmin.vue'

export default {
  name: "AuthAdmin",
  components: {
    LoginAdmin
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
