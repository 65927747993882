<template>
  <vehicle-types-crud></vehicle-types-crud>
</template>

<script>
import VehicleTypesCrud from "@/components/Settings/VehicleTypes/VehicleTypesCrud";

export default {
  name: "VehicleTypes",
  components: {VehicleTypesCrud}
}
</script>

<style scoped>

</style>