<template>
  <v-row class="mt-16 mx-1">
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Financial Report
        </v-card-title>
        <v-card-text>
          <!--          FILTERS-->
          <v-row>
            <v-col cols="1">
              <v-menu
                  v-model="date_picker_status.start"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="input.date_start"
                      label="Start Date"
                      readonly
                      v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="input.date_start"
                               @input="filter('start')">
                  <v-btn
                      text color="primary" @click="clearDate('start')">Clear
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1">
              <v-menu
                  v-model="date_picker_status.finish"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="input.date_finish"
                      label="End Date"
                      readonly
                      v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="input.date_finish"
                               @input="filter('finish')">
                  <v-btn
                      text color="primary" @click="clearDate('finish')">Clear
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  v-model="input.passenger_needle"
                  label="Filter by passengers(name, phone, email)"
                  v-debounce:450ms="filter"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  v-model="input.driver_needle"
                  label="Filter by drivers(name, phone, email)"
                  v-debounce:450ms="filter"
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn class="mt-4" color="#ffa600" @click="clearFilters()">
                <v-icon color="white">mdi-eraser</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!--          RESULT-->
          <v-row v-if="report && report.c_trips_count > 0">
            <v-col cols="6">
              <v-simple-table dense>
                <template v-slot:default>
                  <caption class="text-left">Financials
                    <v-divider></v-divider>
                  </caption>
                  <thead>
                  <tr>
                    <th class="text-left font-weight-bold">
                      Particulars
                    </th>
                    <th class=" font-weight-bold">
                      Amounts in $
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="text-left font-weight-bold">Total revenue (GST and Tips included)</td>
                    <td>${{ report.s_total_price }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">APT Rides Share</td>
                    <td>${{ report.s_company_share }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">APT Rides GST</td>
                    <td>${{ report.s_company_gst }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Drivers Share(GST Included)</td>
                    <td>${{ report.s_driver }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Drivers Tip</td>
                    <td>${{ report.s_tips }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Partner Share(GST Included)</td>
                    <td>{{ report.s_partner_share }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Municipal License recovery fee</td>
                    <td>{{ report.s_p_fee_lmm }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Provincial License recovery fee</td>
                    <td>{{ report.s_p_fee_ptb }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Vancouver curbside congestion management fee</td>
                    <td>{{ report.s_ccmp }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="6">
              <v-simple-table dense>
                <template v-slot:default>
                  <caption class="text-left">Stats
                    <v-divider></v-divider>
                  </caption>
                  <tbody>
                  <tr>
                    <td class="text-left">Completed Trips</td>
                    <td>{{ report.c_trips_count }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Passengers</td>
                    <td>{{ report.c_users_count }}</td>
                  </tr>
                  <tr>
                    <td class="text-left">Drivers</td>
                    <td>{{ report.c_drivers_count }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <template v-else>
            <v-banner class="align-self-center" dark
                      color="secondary"
                      rounded
                      single-line>
              No records to report!
            </v-banner>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const date = new Date();

export default {
  name: "FinancialReport",
  data() {
    return {
      date_picker_status: {
        start: false,
        finish: false,
      },
      input: {
        date_start: new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substring(0, 10),
        date_finish: date.toISOString().substring(0, 10),
        passenger_needle: null,
        driver_needle: null,
      },
      report: null,
    }
  },
  mounted() {
    this.getFinancialReport()
  },
  methods: {
    getFinancialReport() {
      window.axios.post('admin/transaction/financial/report', null).then((res) => {
        this.report = res.data.data
      })
    },
    clearDate(type) {
      if (type === 'start') {
        this.input.date_start = null
        this.date_picker_status.start = false
      }
      if (type === 'finish') {
        this.input.date_finish = null
        this.date_picker_status.finish = false
      }
      this.getFinancialReport()
    },
    filter(type) {
      if (type === 'start') {
        this.date_picker_status.start = false
      }
      if (type === 'finish') {
        this.date_picker_status.finish = false
      }
      console.log('salam')
      window.axios.post('admin/transaction/financial/report', this.input).then((res) => {
        this.report = res.data.data
      })
    },
    clearFilters() {
      this.input.date_start = null
      this.input.driver_needle = null;
      this.input.passenger_needle = null;
      this.input.date_finish = null
      this.getFinancialReport()
    }
  }
}
</script>

<style scoped>

</style>