<template>
  <div>
    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Services Log</div>
        <!--SNACK BAR  -->
        <div class="text-center">
          <v-snackbar
              multi-line
              width="500px"
              centered
              top
              v-model="snackbarStatus"
              :color="snackbarColor"
          >
            <p v-html="snackbarText" class="snackbar-p"></p>
            <template v-slot:action="{ attrs }">
              <v-btn
                  fab
                  text
                  v-bind="attrs"
                  @click="snackbarStatus = false"
              >
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>
        <!--DELETE USER DIALOG-->
        <v-dialog v-model="showExceptionDialog" v-if="selectedLog" centered min-width="30vw" width="550px">
          <v-card>
            <v-card-title class="delete-dialog-text">
              {{ selectedLog.service }}
              <v-spacer></v-spacer>
              <v-icon color="#F1416C" @click="showExceptionDialog=false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="text-center">
                  {{ selectedLog.exception }}
                </v-col>

                <v-col cols="12">
                  <v-divider style="width: 10vw"></v-divider>
                  created at : &nbsp; {{ selectedLog.created_at}}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!--DELETE Services Notification DIALOG-->
        <v-dialog v-model="deleteServicesNotificationDialog" centered min-width="25vw" width="500px">
          <v-card>
            <v-card-title class="delete-dialog-text">
              Are you sure about deleting services notifications ?
            </v-card-title>
            <v-card-text>
              <v-row class="mt-4">
                <v-col cols="6" class="text-center">
                  <v-btn block rounded @click="deleteServicesNotification" color="#F1416C" width="100%"
                         class="white--text">
                    Delete
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteServicesNotificationDialog = false" color="#50cd89" width="100%"
                         class="white--text">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <services-filter v-on:filter-applied="applyFilter"></services-filter>
        <!-- SERVICES TABLE-->
        <v-row>
          <v-col cols="12">
            <v-btn v-can="'delete_log'" color="#F1416C" class="white--text mb-4"
                   @click="deleteServicesNotificationDialog=true">
              Delete all logs
            </v-btn>
            <v-data-table
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @update:sort-by="applySort"
                @update:sort-desc="applySort"
                :custom-sort="(items) => items"
                align="center"
                :headers="headers"
                :items="services.data"
                :items-per-page="10"
                class="elevation-5"
                hide-default-footer>
              <template
                  v-slot:[`item.exception`]="{ item }">
                <span>{{ item.exception.substring(0, 60) + "..." }}</span>
              </template>
              <template
                  v-slot:[`item.created_at`]="{ item }">
                <span>{{ item.created_at }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>Show Exception
                  <template v-slot:activator="{ on }">
                    <v-btn v-can="'show_log'" v-on="on" class="mx-2" fab color="#00b2ff" x-small elevation="0"
                           @click="exceptionDialog(item)">
                      <v-icon color="white">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
          <v-col col="3"></v-col>
          <v-col cols="6">
            <v-pagination
                color="#00b2ff"
                v-model="services.current_page"
                :length="services.last_page"
                circle
                @input="getServicesNotification"
            ></v-pagination>
          </v-col>
          <v-col col="3"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ServicesFilter from "@/components/ServicesNotification/ServicesFilter";

export default {
  name: "ServicesNotification",
  components: {ServicesFilter},
  data() {
    return {
      sortBy: null,
      sortDesc: false,
      sortParams: null,
      created_at: null,
      selectedLog: null,
      showExceptionDialog: false,
      deleteServicesNotificationDialog: false,
      filterParams: null,
      services: {
        data: []
      },
      headers: [
        {text: '#', value: 'id', align: 'center'},
        {text: 'Service Name', value: 'service', align: 'center'},
        {text: 'Service Code', value: 'code', align: 'center'},
        {text: 'Exception', value: 'exception', align: 'center'},
        {text: 'Created at', value: 'created_at', align: 'center'},
        {text: 'Actions', value: 'actions', align: 'center', sortable: false},
      ],
    }
  },
  mounted() {
    this.getServicesNotification()
  },
  methods: {
    applySort() {
      if (this.sortBy && this.sortDesc !== undefined) {
        let sortDirection = this.sortDesc ? 'desc' : 'asc';
        this.sortParams = `&sort[field]=${this.sortBy}&sort[direction]=${sortDirection}`
      } else {
        this.sortParams = null;
      }
      this.getServicesNotification()
    },
    makeQueryParameters() {
      if (this.filterParams != null && this.sortParams != null) {
        return this.filterParams + this.sortParams
      } else if (this.filterParams != null) {
        return this.filterParams;
      } else if (this.sortParams != null) {
        return this.sortParams
      }
      return null
    },
    exceptionDialog(item) {
      this.selectedLog = item
      this.showExceptionDialog = true
    },
    async getServicesNotification() {
      await this.$store.dispatch("getServicesNotification", {
        page: this.services.current_page,
        filter: this.makeQueryParameters()
      }).then((res) => {
        this.services = res
      }).catch((err) => {
        console.log(err.response.data)
      })
    },
    deleteServicesNotification() {
      this.$store.dispatch('deleteServicesNotification').then(() => {
        this.getServicesNotification()
        this.deleteServicesNotificationDialog = false
        this.openSnackbar('Logs Deleted Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
    applyFilter(params) {
      this.services.current_page = 1
      this.filterParams = this.objectParametersToString(params)
      this.getServicesNotification()
    }
  }
}
</script>

<style scoped>

</style>