export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getRoles() {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/role/crud/index")
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getPaginatedRoles(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/role/crud/index?paginate&page=" + (params.page ?? 1) + (params.filter ?? ''))
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        createRole({dispatch}, data) {
            return new Promise((resolve, reject) => {
                window.axios.post("/admin/role/crud/create", {
                    name: data.name,
                    description:data.description,
                    permissionsIds:data.permissionsIds,
                }).then((res) => {
                    dispatch('getProfile')
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        updateRole({dispatch}, data) {
            return new Promise((resolve, reject) => {
                window.axios.post("/admin/role/crud/update/" + data.id, {
                    name: data.newRole.name,
                    description:data.newRole.description,
                    permissionsIds:data.newRole.permissionsIds,
                }).then((res) => {
                    dispatch('getProfile')
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteRole({dispatch}, roleId) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/role/crud/destroy/" + roleId
                ).then((res) => {
                    dispatch('getProfile')
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        // PERMISSIONS
        getPermissions() {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/permission/index")
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
}
