<template>
  <div>
    <!--GUIDE DIALOG-->
    <v-dialog v-model="guideDialog" width="500px">
      <v-card rounded color="white" class="text--black">
        <v-card-title>
          <img src="../../assets/earth-mark.svg" width="35px" height="35px" alt="help"/>
          Map Guide
        </v-card-title>
        <v-card-text>
          <ul>
            <li class="mb-4">Red Routes <span style="color:red;">_______</span> : User Routes</li>
            <li class="mb-4">Blue Routes <span style="color:blue;">_______</span> : Driver Routes</li>
            <li class="mb-4">ORG Marker <img style="margin-bottom: -17px" src="../../assets/ORG.png" width="51"
                                             height="51" alt="ORG"/> : Origin of Trip
            </li>
            <li class="mb-4">DST Marker <img style="margin-bottom: -17px" src="../../assets/DST.png" width="51"
                                             height="51" alt="DST"/> : Destination of Trip
            </li>
            <li class="mb-4">Driver Marker <img style="margin-bottom: -17px" src="../../assets/Driver-icon-with-id.png"
                                                width="51" height="51" alt="Driver Marker"/> : Number on Car is Driver
              ID
            </li>
            <li class="mb-4">Green Car <img style="margin-bottom: -17px" src="../../../public/img/green-car.png"
                                          alt="Green Car"> : Driver is in Trip
            </li>
            <li class="mb-4">Blue Car <img style="margin-bottom: -17px" src="../../../public/img/blue-car.png"
                                             alt="Blue Car"> : Driver is going to Passenger
            </li>
            <li class="mb-4">Black Car <img style="margin-bottom: -17px" src="../../../public/img/black-car.png"
                                            alt="Black Car"> : Driver is not in Trip
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--CONTENT-->
    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container mb-0">Live Trips Management <span class="online-dot">●</span></div>
        <div class="text-center" style="margin-bottom: -34px">Drivers ({{ drivers.length }})</div>
        <v-row>
          <v-col cols="12" xl="3" lg="3" md="3" sm="12" class="scrollable">
            <active-trips v-on:handle-select-trip="handleSelectTrip"
                          :trips="trips"
                          ref="activeTripsComponent">
            </active-trips>
          </v-col>

          <v-col cols="12" xl="9" lg="9" md="9" sm="12" class="mt-6 map-container">

            <v-row v-if="directionMode" id="trip-detail-card">
              <v-col class="trip-detail-card-col">
                <v-row>
                  <v-col cols="11">
                    <v-row>
                      <v-col v-if="selected.driver.name && selected.driver.phone" class="trip-detail-col" cols="12" xl="3" lg="4">
                        <span class="font-size-14">Driver : </span> {{ selected.driver.name }} |
                        {{ selected.driver.phone }}
                      </v-col>
                      <v-col v-if="selected.passenger.name && selected.passenger.phone" class="trip-detail-col" cols="12" xl="3" lg="4">
                        <span class="font-size-14">Passenger : </span> {{ selected.passenger.name }} |
                        {{ selected.passenger.phone }}
                      </v-col>
                      <v-col v-if="selected.driver.vehicle" class="trip-detail-col" cols="12" xl="3" lg="4" md="6">
                        <span class="font-size-14">Vehicle : </span> {{ selected.driver.vehicle }}
                      </v-col>
                      <v-col v-if="selected.trip.state" class="trip-detail-col" cols="12" xl="3" lg="4" md="6">
                        <span class="font-size-14">Trip State : </span> {{ selected.trip.state }}
                      </v-col>
                      <v-col v-if="selected.trip.start_time" class="trip-detail-col" cols="12" xl="3" lg="4" md="6">
                        <span class="font-size-14">Trip Start Time : </span> {{ selected.trip.start_time }}
                      </v-col>
                      <v-col v-if="selected.trip.emergency_by" class="trip-detail-col" cols="12" xl="3" lg="4" md="6"
                             style="text-align: center">
                        <div class="emergency-title">
                          Emergency Request by <u>{{ selected.trip.emergency_by.toUpperCase() }}</u>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn small color="#dc143c" @click="exitFromDirectionMode">
                          <span style="color: white">Exit Direction Mode</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-btn x-small color="#8bdcff" @click="guideDialog = true" class="mb-2 btn-on-map-top-right" fab>
              <v-icon color="white">mdi-help</v-icon>
            </v-btn>

            <GmapMap
                :clickable="true"
                :center="center"
                :zoom="zoom"
                map-type-id="terrain"
                :style="{width: '100%', height: mapHeight}"
                ref="gmap"
                :options="mapOptions">
              <DirectionRenderer
                  v-for="(option, index) in driverDirectionOptions"
                  :key="index + 'direction-renderer-driver'"
                  :travelMode="option.travelMode"
                  :origin="option.origin"
                  :destination="option.destination"
                  :waypoints="option.waypoints"
                  :optimizeWaypoints="false"
                  :preserveViewport="true"
                  color="blue"
              />
              <DirectionRenderer
                  v-for="(option, index) in userDirectionOptions"
                  :key="index + 'direction-renderer-user'"
                  :travelMode="option.travelMode"
                  :origin="option.origin"
                  :destination="option.destination"
                  :waypoints="option.waypoints"
                  :optimizeWaypoints="false"
                  :preserveViewport="true"
                  color="red"
              />

              <gmap-marker
                  :position="origin"
                  :draggable="false"
                  :options="{label: {
                    text: 'ORG',
                    fontSize: '12px',
                    fontWeight: 'Bold',
                    color: 'white'
                  }}"
                  :animation="2">
              </gmap-marker>
              <gmap-marker
                  :position="destination"
                  :draggable="false"
                  :options="{label: {
                    text: 'DST',
                    fontSize: '12px',
                    fontWeight: 'Bold',
                    color: 'white'
                  }}"
                  :animation="2">
              </gmap-marker>

              <gmap-marker
                  v-for="(driverWayPointsMarker, index) in driverWayPointsMarkers"
                  :position="driverWayPointsMarker.location"
                  :key="index + 'driver-waypoint-marker'"
                  :label="driverWayPointsMarker.label"
                  :icon="{
                    path: 'M14,7.5c0,3.5899-2.9101,6.5-6.5,6.5S1,11.0899,1,7.5S3.9101,1,7.5,1S14,3.9101,14,7.5z',
                    fillColor: '#9fb2ff',
                    fillOpacity: 1,
                    strokeWeight: 0,
                    scale: 0.75,
                  }"
                  :animation="2">
              </gmap-marker>
              <gmap-marker
                  v-for="(userWayPointsMarker, index) in userWayPointsMarkers"
                  :position="userWayPointsMarker.location"
                  :key="index + 'user-waypoint-marker'"
                  :label="userWayPointsMarker.label"
                  :icon="{
                    path: 'M14,7.5c0,3.5899-2.9101,6.5-6.5,6.5S1,11.0899,1,7.5S3.9101,1,7.5,1S14,3.9101,14,7.5z',
                    fillColor: '#ff9f9f',
                    fillOpacity: 1,
                    strokeWeight: 0,
                    scale: 0.75,
                  }"
                  :animation="2">
              </gmap-marker>

              <gmap-cluster>
                <gmap-marker v-for="(marker, index) in markers"
                             :position="{lat: marker.last_driver_location.latitude, lng: marker.last_driver_location.longitude}"
                             :icon="{url: getCarImage(marker)}"
                             @click="center = {lat: marker.last_driver_location.latitude, lng: marker.last_driver_location.longitude};"
                             :label='String(marker.id)'
                             :options="{label: {
                               text: String(marker.id),
                               className: 'driver-marker-class',
                               fontSize: '20px',
                               fontWeight: 'Bold',
                               color: 'white'
                             }}"
                             :key="index"
                             :animation="2"
                ></gmap-marker>
              </gmap-cluster>
            </GmapMap>

          </v-col>

<!--          <v-col cols="12" xl="3" lg="3" md="3" sm="12" class="scrollable">-->
<!--            <active-drivers v-on:handle-select-driver="handleSelectDriver"-->
<!--                            v-on:handle-filter-driver="handleFilterDriver"-->
<!--                            :drivers="drivers"></active-drivers>-->
<!--          </v-col>-->
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
window.Pusher = require('pusher-js');
import {gmapApi} from 'vue2-google-maps';
import ActiveTrips from "@/components/LiveTrips/ActiveTrips";
// import ActiveDrivers from "@/components/LiveTrips/ActiveDrivers";
import DirectionRenderer from "@/components/GoogleMap/DirectionRenderer";

export default {
  name: "LiveTrips",
  components: {ActiveTrips, /*ActiveDrivers,*/ DirectionRenderer},
  data() {
    return {
      guideDialog: false,

      pusher: null,

      id: null,
      trips: [],
      drivers: [],
      center: {lat: 49.252646732280425, lng: -123.02589291692055},
      zoom: 12,
      markers: [],

      mapOptions: {
        disableDefaultUI: true,
        zoomControl: true,
      },

      directionMode: false,
      driverDirectionOptions: [],
      userDirectionOptions: [],
      driverWayPointsMarkers: [],
      userWayPointsMarkers: [],

      origin: null,
      destination: null,

      selected: {
        driver: {
          id: null,
          name: null,
          phone: null,
          vehicle: null,
        },
        passenger: {
          name: null,
          phone: null,
        },
        trip: {
          state: null,
          start_time: null,
          emergency_by: null,
          trip_id: null
        },
      },

      mapHeight: '100%'
    }
  },

  async mounted() {
    await this.connectToWs()
  },

  methods: {
    connectToWs() {
      this.pusher = new window.Pusher("myKey", {
        cluster: "mt1",
        wsHost: process.env.VUE_APP_WS_HOST,
        wsPort: process.env.VUE_APP_WS_PORT,
        forceTLS: process.env.VUE_APP_FORCE_TLS !== 'false',
        encrypted: true,
        disableStats: true,
        auth: {
          headers: {
            Authorization: 'Bearer ' + this.$cookies.get('token')
          }
        },
        authorizer: (channel) => {
          return {
            authorize: (socketId, callback) => {
              window.axios.post(process.env.VUE_APP_BACKEND_BASE_URL + 'broadcasting/auth', {
                socket_id: socketId,
                channel_name: channel.name
              })
                  .then(response => {
                    window.axios.get('admin/trip/live')
                    callback(false, response.data);
                  })
                  .catch(error => {
                    callback(true, error);
                  });
            }
          };
        },
      });
      let channel = this.pusher.subscribe("presence-Admin");

      channel.bind("trip.admin", (data) => {
        this.drivers = data.data.drivers
        this.trips = data.data.trips
        this.markers = this.drivers.filter((driver) => {
          return driver.last_driver_location && driver.last_driver_location.latitude && driver.last_driver_location.longitude
        })
        this.trips.forEach((trip) => {
          if (trip.trip.trip_id == this.selected.trip.trip_id) {
            this.handleSelectTrip(trip)
          }
        })
        this.drivers.forEach((driver) => {
          if (driver.id == this.selected.driver.id) {
            this.handleSelectDriver(driver)
          }
        })
        console.log(data)
      });
    },

    handleSelectTrip(trip) {
      console.log('handle select trip')
      this.directionMode = true
      this.clearAllRoutes()
      this.origin = {
        lat: trip.trip.origin_latitude,
        lng: trip.trip.origin_longitude,
      }
      this.destination = {
        lat: trip.trip.destination_latitude,
        lng: trip.trip.destination_longitude,
      }
      let bounds = new this.google.maps.LatLngBounds();
      bounds.extend(this.origin);
      bounds.extend(this.destination);
      this.$refs.gmap.$mapPromise.then((map) => {
        map.fitBounds(bounds);
      })
      this.drawDirectionsOnMap(trip.driver_locations, 'driver')
      if (trip.trip.emergency_by) {
        this.drawDirectionsOnMap(trip.user_locations, 'user')
      }
      this.markers = this.drivers.filter((item) => {
        return item.id === trip.trip.driver_id &&
            item.last_driver_location &&
            item.last_driver_location.latitude &&
            item.last_driver_location.longitude
      });
      this.selected.driver.name = trip.trip.driver_name
      this.selected.driver.phone = trip.trip.driver_phone
      if (this.markers[0]) {
        this.selected.driver.vehicle =
            (this.markers[0].vehicle_type ? (this.markers[0].vehicle_type.title + ' - ') : '')
            + (this.markers[0].driver_attachment ? (this.markers[0].driver_attachment.vehicle_make + ' ') : '')
            + (this.markers[0].driver_attachment ? this.markers[0].driver_attachment.model : '')
      }
      this.selected.trip.emergency_by = trip.trip.emergency_by
      this.selected.trip.start_time = trip.trip.start_time
      this.selected.trip.state = this.$refs.activeTripsComponent.getStatusName(trip.trip.states)
      this.selected.passenger.name = trip.trip.user_name
      this.selected.passenger.phone = trip.trip.user_phone
      this.selected.trip.trip_id = trip.trip.trip_id
      this.$nextTick(() => {
        this.setMapHeight()
      })
    },

    handleSelectDriver(driver) {
      console.log('handle select driver')
      if (driver.trip_status === 'wait_for_ride' || driver.trip_status === 'in_trip') {
        let trip = this.trips.filter(item => item.trip.driver_id === driver.id)
        if (trip.length) {
          this.handleSelectTrip(trip[0])
          return
        }
      } else {
        this.directionMode = true
        this.clearAllRoutes()
        this.selected.driver.id = driver.id
        this.selected.driver.name = driver.first_name + ' ' + driver.last_name
        this.selected.driver.phone = driver.phone
        this.selected.driver.vehicle =
            (driver.vehicle_type ? (driver.vehicle_type.title + ' - ') : '')
            + (driver.driver_attachment ? (driver.driver_attachment.vehicle_make + ' ') : '')
            + (driver.driver_attachment ? driver.driver_attachment.model : '')
        this.selected.trip.emergency_by = null
        this.selected.trip.start_time = null
        this.selected.trip.state = null
        this.selected.trip.trip_id = null
        this.selected.passenger.name = null
        this.selected.passenger.phone = null
        this.$nextTick(() => {
          this.setMapHeight()
        })
      }
      if (driver.last_driver_location && driver.last_driver_location.latitude && driver.last_driver_location.longitude) {
        this.markers = [driver];
        this.$refs.gmap.$mapPromise.then((map) => {
          map.setZoom(16);
          map.setCenter({lat: driver.last_driver_location.latitude, lng: driver.last_driver_location.longitude});
        })
      }
    },

    handleFilterDriver(drivers) {
      console.log('handler filter driver')
      this.markers = drivers.filter((item) => {
        return item.last_driver_location &&
            item.last_driver_location.latitude &&
            item.last_driver_location.longitude
      });
    },

    drawDirectionsOnMap(locations, type) {
      console.log(locations, type)
      console.log('draw direction on map')
      if (locations.length) {
        let wayPointsMarkers = []
        let directionOptions = []

        /* Make Markers */
        for (let i = 0; i < locations.length; i++) {
          let tmp = {
            location: {
              lat: locations[i].latitude,
              lng: locations[i].longitude
            },
            label: null
          }
          wayPointsMarkers.push(tmp)
        }

        /* Divide Locations in several parts */
        let parts = [];
        for (let i = 0, max = 25 - 1; i < locations.length; i = i + max)
          parts.push(locations.slice(i, i + max + 1));

        /* Make Route Data foreach part*/
        for (let i = 0; i < parts.length; i++) {
          let waypointsData = [];
          for (let j = 1; j < parts[i].length - 1; j++) {
            waypointsData.push({
              location: {
                lat: parts[i][j].latitude,
                lng: parts[i][j].longitude,
              }, stopover: false
            });
          }
          directionOptions.push({
            origin: {
              lat: parts[i][0].latitude,
              lng: parts[i][0].longitude,
            },
            destination: {
              lat: parts[i][parts[i].length - 1].latitude,
              lng: parts[i][parts[i].length - 1].longitude,
            },
            waypoints: waypointsData,
            travelMode: 'DRIVING'
          })
        }

        if (type === 'driver') {
          this.driverDirectionOptions = directionOptions
          this.driverWayPointsMarkers = wayPointsMarkers
        }
        if (type === 'user') {
          this.userDirectionOptions = directionOptions
          this.userWayPointsMarkers = wayPointsMarkers
        }
      }
    },

    exitFromDirectionMode() {
      console.log('exit from direction mode')
      this.directionMode = false
      this.selected = {
        driver: {
          id: null,
          name: null,
          phone: null,
          vehicle: null,
        },
        passenger: {
          name: null,
          phone: null,
        },
        trip: {
          state: null,
          start_time: null,
          emergency_by: null,
          trip_id: null
        },
      }
      this.clearAllRoutes()
      this.markers = this.drivers.filter((driver) => {
        return driver.last_driver_location && driver.last_driver_location.latitude && driver.last_driver_location.longitude
      })
      this.$nextTick(() => {
        this.setMapHeight('100%')
      })
      console.log('exited from direction mode, directionMode: ', this.directionMode)
    },

    clearAllRoutes() {
      this.origin = null
      this.destination = null
      this.driverDirectionOptions = []
      this.userDirectionOptions = []
      this.driverWayPointsMarkers = []
      this.userWayPointsMarkers = []
    },

    getCarImage(marker) {
      if (marker.trip_status === 'wait_for_ride') {
        return '/img/blue-car.png'
      } else if (marker.trip_status === 'in_trip') {
        return '/img/green-car.png'
      } else {
        return '/img/black-car.png'
      }
    },

    setMapHeight(value = null) {
      if (value) {
        this.mapHeight = value
      } else {
        let detailCardEl = document.getElementById('trip-detail-card')
        let mapEl = document.getElementsByClassName('vue-map-container')[0]
        this.mapHeight = '100%';
        this.$nextTick(() => {
          this.mapHeight = (mapEl.offsetHeight - detailCardEl.offsetHeight) + 'px'
        })
      }
    },
  },

  computed: {
    google: gmapApi,
  },

  beforeDestroy() {
    this.pusher.disconnect()
  },

  watch: {
    '$route.query.trip_id': {
      handler: function(trip_id) {
        if (trip_id) {
          if (this.trips.length) {
            let trip = this.trips.filter(item => item.trip.trip_id == trip_id)
            this.handleSelectTrip(trip[0])
          } else {
            this.selected.trip.trip_id = trip_id
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}

</script>

<style scoped>
.scrollable {
  overflow-y: scroll;
  height: 85vh;
}

.online-dot {
  font-size: 30px;
  color: lawngreen;
}

.btn-on-map-top-left {
  box-shadow: none !important;
  letter-spacing: normal;
  position: absolute;
  z-index: 99;
  padding: 15px !important;
  left: 20px;
  top: 20px;
}

.btn-on-map-top-right {
  box-shadow: none !important;
  letter-spacing: normal;
  position: absolute;
  z-index: 3;
  padding: 15px !important;
  right: 20px;
  top: 20px;
}

.map-container {
  color: #013e5d;
  height: 85vh;
  position: relative;
}

.font-size-14 {
  font-size: 14px !important;
}

.trip-detail-col {
  font-size: 13px !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 5px !important;
}

.emergency-title {
  justify-content: center !important;
  border: 3px solid #DC143C;
  border-radius: 15px;
  padding: 2px 5px;
  color: #DC143C;
  margin-bottom: -5px;
  font-size: 13px;
  width: 100%;
  text-align: center;
}

.trip-detail-card-col {
  background-color: white;
  margin: 10px;
}
</style>