import Vue from "vue";

export function getAdminPermissions() {
    return Vue.$cookies.get('permissions')
        ? Vue.$cookies.get('permissions').split(',')
        : []
}

export function checkPermissions(to, next, middleware) {
    if (middleware && getAdminPermissions().includes(middleware)) {
        return next();
    } else if (middleware && Vue.$cookies.get('token')) {
        return next('/unauthorized');
    } else if (to.meta.requiresAuth && !Vue.$cookies.get('token')) {
        return next('/login');
    }
    return next();
}