import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";
import roleModule from "./modules/role";
import adminModule from "./modules/admin";
import userModule from "./modules/user";
import driverModule from "./modules/driver";
import vehicleTypeModule from "./modules/vehicle-type";
import allowedAreaModule from "./modules/allowed-area";
import hotZoneModule from "./modules/hot-zone";
import pickupAreaModule from "./modules/pickup-area";
import dropoffAreaModule from "./modules/dropoff-area";
import driverInitDataModule from "./modules/driver-init-data";
import userInitDataModule from "./modules/user-init-data";
import settingModule from "./modules/setting"
import tripModule from "./modules/trip"
import servicesNotificationModule from "./modules/sercives-notification"
import actionsStatusModule from "./modules/actions-status"
import notificationsModule from "./modules/notification"
import transactionModule from "./modules/transaction"
import activitiesModule from "./modules/activities"
import goalModule from "./modules/goal"
import lostItemModule from "./modules/lost-item"

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    token: null,
    admin: null,
    register:null,
    auth: null,
    loading: null,
  },
  getters: {
    getAdmin (state) {
      return state.admin
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setAdmin(state, admin) {
      state.admin = admin;
    },
    setAuth(state, status) {
      state.auth = status
    },
    setLoading(state, condition) {
      state.loading = condition
    },
  },
  actions: {
    loginAdmin({commit}, authData) {
      return new Promise((resolve, reject) => {
        window.axios.post("/auth/admin/login", {
          email: authData.email,
          password: authData.password,
        }).then((res) => {
          const pluck = (arr, key) => arr.map(i => i[key]);

          Vue.$cookies.set('token', 'Bearer ' + res.data.data.token, 60 * 60 * 24 * 30)
          Vue.$cookies.set('permissions', pluck(res.data.data.admin.role.permissions, 'name'))

          commit('setToken', res.data.data.token);
          commit('setAuth', true);
          commit('setAdmin', res.data.data.admin);

          resolve(res)
        }).catch((err) => {
          commit('setAdmin', null);
          commit('setToken', null);
          commit('setAuth', false);
          reject(err)
        })
      })
    },
    forgetPassword(_,data){
      return new Promise((resolve, reject) => {
        window.axios.post("/auth/admin/forget-password", {
          email: data.email,
        }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    resetPassword(_,data){
      return new Promise((resolve, reject) => {
        window.axios.post("/auth/admin/reset-password", {
          email: data.email,
          token:data.token,
          new_password:data.new_password,
          new_password_confirmation:data.new_password_confirmation,
        }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    signOut({commit}) {
      let token = Vue.$cookies.get('token')
      window.axios.get("/auth/admin/logout", {
        headers: {Authorization: token}
      }).then(() => {
        Vue.$cookies.remove('token')
        Vue.$cookies.remove('permissions')
        commit('setToken', null);
        commit('setAuth', false);
        router.push('/login')
      }).catch(error =>
          console.log(error.response)
      )
    },
    getProfile({commit}) {
      return new Promise((resolve, reject) => {
        window.axios.get("/admin/profile/get")
            .then((res) => {
              const pluck = (arr, key) => arr.map(i => i[key]);
              Vue.$cookies.set('permissions', pluck(res.data.data.role.permissions, 'name'))

              commit('setAdmin', res.data.data);
              commit('setAuth', true)

              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
      })
    },
    updateProfile(_, data) {
      return new window.axios.post("/admin/profile/update", {
        name: data.admin.name,
        email: data.admin.email,
      }).then(() => {
      })
    },
    changePassword(_, data) {
      return new window.axios.post("/admin/profile/change-password", {
        old_password: data.admin.old_password,
        new_password: data.admin.new_password,
        new_password_confirmation: data.admin.new_password_confirmation,
      }).then(() => {

      })
    },
  },
  modules: {
    roleModule,
    adminModule,
    driverModule,
    userModule,
    vehicleTypeModule,
    allowedAreaModule,
    hotZoneModule,
    pickupAreaModule,
    dropoffAreaModule,
    driverInitDataModule,
    userInitDataModule,
    settingModule,
    tripModule,
    servicesNotificationModule,
    actionsStatusModule,
    notificationsModule,
    transactionModule,
    activitiesModule,
    goalModule,
    lostItemModule
  }
})
