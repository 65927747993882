export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getActivities(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/activity/log/index?paginate&page=" + (params.page ?? 1) + (params.filter ?? ''))
                    .then((res) => {
                        resolve(res.data.data)
                    }).catch((err) => {
                    console.log(err.response)
                    reject(err)
                })
            })
        },
        deleteActivities(_, data) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/activity/log/delete-all", data).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        ShowActivitiesDetails({commit}, activityId) {
            return new Promise((resolve, reject) => {
                return window.axios.get("/admin/activity/log/show/" + activityId).then((res) => {
                    resolve(res)
                    commit('setLoading', false)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    },
}
