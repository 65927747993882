<template>
  <v-app v-if="!loading">
    <v-main>
      <navigation-bar v-if="$store.state.auth"></navigation-bar>
      <router-view/>
    </v-main>

    <v-overlay
        :value="$store.state.loading"
        :opacity="0.8"
        style="z-index: 999"
    >
      <v-progress-circular
          :size="100"
          :width="7"
          color="#00b2ff"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>
<script>
import Vue from "vue";
import NavigationBar from "@/components/General/NavigationBar";


export default {
  name: 'App',
  components: {NavigationBar},
  data: () => ({
    loading: true,
  }),

  async mounted() {
    this.checkAuth()
    this.checkLoading()
  },

  methods: {
    checkAuth() {
      let token = Vue.$cookies.get('token')

      if (token) {
        this.$store.dispatch("getProfile")
            .then(() => {
              if (this.$route.path === '/login') {
                this.$router.push('/home')
              }
            })
            .catch(() => {
              if (this.$route.path !== '/login') {
                this.$router.push('/login')
              }
            })
      }
    },
    checkLoading() {
      this.loading = this.$store.state.loading
    }
  },
}
</script>

<style>
.v-snack__wrapper {
  border-radius: 25px !important;
}

.v-btn {
  letter-spacing: normal;
  text-transform: none !important;
  box-shadow: none !important;
}

#app {
  background-color: #FDF9F3 !important;
}

.rounded-25 {
  border-radius: 25px !important;
}

.rounded-15 {
  border-radius: 15px !important;
}

.blue-on-hover:hover {
  color: #00b2ff !important;
}

.delete-dialog-text {
  font-size: medium !important;
}

.title-container {
  color: #161853;
  font-family: Roboto, serif;
  font-size: x-large;
  min-height: 5vh;
  margin-bottom: 15px;
}

* {
  font-family: Roboto, serif;
}

th {
  text-align: center !important;
}

td {
  text-align: center !important;
}

.mlr-1 {
  margin-left: 1px;
  margin-right: 1px
}
</style>
