export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getSettingIndex() {
            return new Promise((resolve, reject) => {
                window.axios.get("/setting/crud/index")
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateSetting(_, data) {
            return new Promise((resolve, reject) => {
                data.forEach(item => {
                    window.axios.post("/setting/crud/update/" + item.id, {
                        value: item.value,
                    }).then((res) => {
                        resolve(res.data.message)
                    }).catch((err) => {
                        reject(err)
                    })
                })
            });
        },
    },
}