<template>
  <div>
    <trips-index></trips-index>
  </div>
</template>

<script>
import TripsIndex from "@/components/Trips/TripsIndex";

export default {
  name: "TripsInfo",
  components: {TripsIndex},
}
</script>

<style scoped>

</style>