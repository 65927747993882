export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getVehicleTypes() {
            return new Promise((resolve, reject) => {
                window.axios.get("/setting/vehicle-type/index?paginate")
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getPaginatedVehicleTypes(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("/setting/vehicle-type/index?paginate&page=" + (params.page ?? 1) + (params.filter ?? ''))
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        createVehicleType(_, data) {
            return new Promise((resolve, reject) => {
                let newData = new FormData
                if (data.title)
                    newData.append('title', data.title)
                newData.append('base_rate', data.base_rate)
                newData.append('price_per_kilometer', data.price_per_kilometer)
                newData.append('price_per_minute', data.price_per_minute)
                if (data.image)
                    newData.append('image', data.image)
                if (data.description)
                    newData.append('description', data.description)
                window.axios.post("/setting/vehicle-type/create", newData).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        updateVehicleType(_, data) {
            return new Promise((resolve, reject) => {
                let newData = new FormData()
                if (data.newVehicleType.title)
                    newData.append('title', data.newVehicleType.title)
                if (data.newVehicleType.description)
                    newData.append('description', data.newVehicleType.description)
                newData.append('base_rate', data.newVehicleType.base_rate)
                newData.append('price_per_kilometer', data.newVehicleType.price_per_kilometer)
                newData.append('price_per_minute', data.newVehicleType.price_per_minute)
                if (data.newVehicleType.image) {
                    newData.append('image', data.newVehicleType.image)
                }
                window.axios.post("/setting/vehicle-type/update/" + data.id, newData).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteVehicleType(_, vehicleTypeId) {
            return new Promise((resolve, reject) => {
                window.axios.get("/setting/vehicle-type/destroy/" + vehicleTypeId
                ).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    },
}
