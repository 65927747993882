<template>
  <div>
    <forget-password></forget-password>
  </div>
</template>

<script>
import ForgetPassword from "@/components/Auth/ForgetPassword";

export default {
  name: "ForgetPasswordAdmin",
  components: {ForgetPassword}
}
</script>

<style scoped>

</style>