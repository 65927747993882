import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthAdmin from "@/views/Auth/AuthAdmin";
import UsersSetting from "@/views/Users/UsersSetting";
import AdminsSetting from "@/views/Admins/AdminsSetting";
import DriversSetting from "@/views/Drivers/DriversSetting";
import HomePage from "@/views/Home/HomePage";
import RolesSetting from "@/views/Roles/RolesSetting";
import AdminProfile from "@/views/Profile/AdminProfile";
import VehicleTypes from "@/views/Settings/VehicleTypes";
import AllowedAreas from "@/views/Settings/AllowedAreas";
import {checkPermissions} from "@/middleware/hasPermission"
import UnAuthorized from "@/views/Auth/UnAuthorized";
import ForgetPasswordAdmin from "@/views/Auth/ForgetPasswordAdmin"
import ResetPasswordAdmin from "@/views/Auth/ResetPasswordAdmin";
import HotZones from "@/views/Settings/HotZones";
import PickupArea from "@/views/Settings/PickupArea";
import DropoffArea from "@/views/Settings/DropoffArea";
import UserInitData from "@/views/Settings/UserInitData";
import DriverInitData from "@/views/Settings/DriverInitData";
import Setting from "@/views/Settings/Setting";
import ServicesNotification from "@/views/Settings/ServicesNotification";
import Trips from "@/views/Trips/Trips";
import LiveTrips from "@/views/LiveTrips/LiveTrips";
import NotificationCrud from "@/components/Notification/NotificationCrud";
import testSocket from "@/views/Test/TestSocket";
import ShowDriverDetails from "@/views/Drivers/ShowDriverDetails";
import ShowAdminDetails from "@/views/Admins/ShowAdminDetails";
import ShowUserDetails from "@/views/Users/ShowUserDetails";
import ShowTripDetails from "@/views/Trips/ShowTripDetails";
import TransactionsIndex from "@/components/Transactions/TransactionsIndex";
import ActivitiesIndex from "@/components/Activities/ActivitiesIndex";
import ShowUserTransactions from "@/views/Users/ShowUserTransactions";
import FinancialReport from "@/components/Transactions/FinancialReport";
import ShowTransactionDetails from "@/views/Transactions/ShowTransactionDetails";
import GoalsSetting from "@/views/Goals/GoalsSetting";
import ApplicationVersions from "@/views/Settings/ApplicationVersions";
import LostItem from "@/views/LostItem/LostItem";
import ShowLostItemDetails from "@/components/LostItem/ShowLostItemDetails";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '/',
        redirect: '/home',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/home',
        name: 'HomePage',
        component: HomePage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/profile',
        name: 'AdminProfile',
        component: AdminProfile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'AuthAdmin',
        component: AuthAdmin,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/forget-password',
        name: 'ForgetPasswordAdmin',
        component: ForgetPasswordAdmin,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPasswordAdmin',
        component: ResetPasswordAdmin,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/admins',
        name: 'AdminsSetting',
        component: AdminsSetting,
        meta: {
            middleware: 'index_admin',
            requiresAuth: true
        }
    },
    {
        path: '/users',
        name: 'UsersSetting',
        component: UsersSetting,
        meta: {
            middleware: 'index_user',
            requiresAuth: true
        }
    },
    {
        path: '/goals',
        name: 'GoalsSetting',
        component: GoalsSetting,
        meta: {
            middleware: 'index_goal',
            requiresAuth: true
        }
    },
    {
        path: '/transactions',
        name: 'TransactionsIndex',
        component: TransactionsIndex,
        meta: {
            middleware: 'index_transaction',
            requiresAuth: true
        }
    },
    {
        path: '/financial-report',
        name: 'FinancialReport',
        component: FinancialReport,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/passenger-transactions/:id',
        name: 'ShowUserTransactions',
        component: ShowUserTransactions,
        meta: {
            middleware: 'user_transactions',
            requiresAuth: true
        }
    },
    {
        path: '/activities',
        name: 'ActivitiesIndex',
        component: ActivitiesIndex,
        meta: {
            middleware: 'index_activity',
            requiresAuth: true
        }
    },
    {
        path: '/drivers',
        name: 'DriversSetting',
        component: DriversSetting,
        meta: {
            middleware: 'index_driver',
            requiresAuth: true
        }
    },
    {
        path: '/driver-details/:id',
        name: 'ShowDriverDetails',
        component: ShowDriverDetails,
        meta: {
            middleware: 'show_driver',
            requiresAuth: true
        }
    },
    {
        path: '/admin-details/:id',
        name: 'ShowAdminDetails',
        component: ShowAdminDetails,
        meta: {
            middleware: 'show_admin',
            requiresAuth: true
        }
    },
    {
        path: '/passenger-details/:id',
        name: 'ShowUserDetails',
        component: ShowUserDetails,
        meta: {
            middleware: 'show_user',
            requiresAuth: true
        }
    },
    {
        path: '/trip-details/:id',
        name: 'ShowTripDetails',
        component: ShowTripDetails,
        meta: {
            middleware: 'show_trip',
            requiresAuth: true
        }
    },
    {
        path: '/transaction-details/:id',
        name: 'ShowTransactionDetails',
        component: ShowTransactionDetails,
        meta: {
            middleware: 'show_transaction',
            requiresAuth: true
        }
    },
    {
        path: '/roles',
        name: 'RolesSetting',
        component: RolesSetting,
        meta: {
            middleware: 'index_role',
            requiresAuth: true
        }
    },
    {
        path: '/settings/vehicle-types',
        name: 'VehicleTypes',
        component: VehicleTypes,
        meta: {
            middleware: 'index_vehicle_type',
            requiresAuth: true
        }
    },
    {
        path: '/settings/allowed-areas',
        name: 'AllowedAreas',
        component: AllowedAreas,
        meta: {
            middleware: 'index_allowed_area',
            requiresAuth: true
        }
    },
    {
        path: '/settings/hot-zones',
        name: 'HotZones',
        component: HotZones,
        meta: {
            middleware: 'index_hot_zone',
            requiresAuth: true
        }
    },
    {
        path: '/settings/pickup-area',
        name: 'PickupArea',
        component: PickupArea,
        meta: {
            middleware: 'index_ccmp_pickup_area',
            requiresAuth: true
        }
    },
    {
        path: '/settings/dropoff-area',
        name: 'DropoffArea',
        component: DropoffArea,
        meta: {
            middleware: 'index_ccmp_dropoff_area',
            requiresAuth: true
        }
    },
    {
        path: '/services/notification',
        name: 'ServicesNotification',
        component: ServicesNotification,
        meta: {
            middleware: 'index_log',
            requiresAuth: true
        }
    },
    {
        path: '/live-trips',
        name: 'LiveTrips',
        component: LiveTrips,
        meta: {
            requiresAuth: true,
            middleware: 'live_trip',
        }
    },
    {
        path: '/notifications',
        name: 'NotificationCrud',
        component: NotificationCrud,
        meta: {
            middleware: 'index_notification',
            requiresAuth: true
        }
    },
    {
        path: '/settings/setting',
        name: 'SettingValue',
        component: Setting,
        meta: {
            middleware: 'index_setting',
            requiresAuth: true
        }
    },
    {
        path: '/settings/driver-init-data',
        name: 'DriverInitData',
        component: DriverInitData,
        meta: {
            middleware: 'show_driver_initial_data',
            requiresAuth: true
        }
    },
    {
        path: '/settings/user-init-data',
        name: 'UserInitData',
        component: UserInitData,
        meta: {
            middleware: 'show_user_initial_data',
            requiresAuth: true
        }
    },
    {
        path: '/settings/application-versions',
        name: 'ApplicationVersions',
        component: ApplicationVersions,
        meta: {
            middleware: 'index_setting',
            requiresAuth: true
        }
    },
    {
        path: '/trips',
        name: 'TripsInfo',
        component: Trips,
        meta: {
            middleware: 'index_trip',
            requiresAuth: true
        }
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: UnAuthorized,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/test-socket',
        name: 'testSocket',
        component: testSocket,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/lost-items',
        name: 'lostItem',
        component: LostItem,
        meta: {
            middleware: 'index_lostItem',
            requiresAuth: true
        }
    },
    {
        path: '/lost-item-details/:id',
        name: 'ShowLostItemDetails',
        component: ShowLostItemDetails,
        meta: {
            middleware: 'show_lostItem',
            requiresAuth: true
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    let middleware = to.meta.middleware;
    return checkPermissions(to, next, middleware)
});


export default router
