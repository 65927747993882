import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import vueDebounce from 'vue-debounce'
import {getAdminPermissions} from './middleware/hasPermission'

import GmapCluster from "vue2-google-maps/dist/components/cluster";

Vue.component('GmapCluster', GmapCluster);
Vue.use(vueDebounce)
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false

import VueGeolocation from 'vue-browser-geolocation'

Vue.use(VueGeolocation)

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_KEY,
        libraries: ["places", "drawing", "geometry", "visualization"],
        region: 'canada',
        language: 'EN',
    },
    installComponents: true
});
const moment = require('moment')
Vue.use(require('vue-moment'), {
    moment
})
Vue.filter('momentDuration', function (value) {
    if (!value) return ''
    let duration = Vue.moment.duration(value, "seconds");
    let str = "";
    if (duration.days() > 1) str = str + Math.floor(duration.days()) + "d ";
    if (duration.hours() > 1) str = str + Math.floor(duration.hours()) + "h ";
    if (duration.minutes() > 1) str = str + Math.floor(duration.minutes()) + "m ";
    if (duration.seconds() > 1) str = str + Math.floor(duration.seconds()) + "s ";
    return str;
})

Vue.config.productionTip = false

Vue.mixin({
    data() {
        return {
            snackbarStatus: false,
            snackbarText: '',
            snackbarColor: 'success',
            mobile: window.innerWidth <= 700,
            baseURL: process.env.VUE_APP_BASE_URL,
        }
    },
    methods: {
        openSnackbar(text, color = 'success') {
            this.snackbarStatus = true
            this.snackbarText = text
            this.snackbarColor = color
        },
        showValidationErrors(errors) {
            let errorText = ''
            Object.entries(errors).forEach((err) => {
                err[1].forEach((item) => {
                    errorText = errorText + item + '<br>'
                })
            })
            this.openSnackbar(errorText, '#F1416C')
        },
        objectParametersToString(params) {
            let stringedParams = '';
            Object.entries(params).forEach((param) => {
                if (param[1] || param[1] === false) {
                    if (Array.isArray(param[1]) && param[1].length) {
                        param[1].forEach((item) => {
                            stringedParams = stringedParams + '&' + param[0] + '[]' + '=' + item
                        })
                    } else {
                        stringedParams = stringedParams + '&' + param[0] + '=' + param[1]
                    }
                }
            })

            return stringedParams;
        }
    },
    watch: {},
})

Vue.directive('can', {
    bind: function (el, binding) {
        if (Array.isArray(binding.value) && binding.value.length) {
            let foundedPermissions = 0
            binding.value.forEach((item) => {
                if (getAdminPermissions().includes(item)) {
                    foundedPermissions = foundedPermissions + 1
                }
            })
            if (foundedPermissions != binding.value.length) {
                el.style.display = "none";
            }
        } else if (!getAdminPermissions().includes(binding.value)) {
            el.style.display = "none";
        }
    }
})


new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app')
