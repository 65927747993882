<template>
  <div v-if="user" class="mt-16">
    <v-row class="mt-16 ml-1 mr-1" v-can="['show_user']">
      <v-col cols="12">
        <div class="text-center title-container">Passenger Details</div>
        <v-row class="mlr-1">
          <v-col cols="12" class="pa-0">
            <!--            <v-btn text v-if="this.$router.history._startLocation===('/transactions')"-->
            <!--                   @click="$router.replace('/transactions')" class="pa-0"></v-btn>-->
            <v-btn text @click="$router.go(-1)" class="pa-0">
              <v-icon x-large color="#00b2ff">mdi-arrow-left</v-icon>
              &nbsp; Back
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mx-5">
          <v-col cols="12">
            <v-row>
              <!-- personal info -->
              <v-col cols="12" xl="6" lg="6" md="12" sm="12">
                <div class="mt-5 mb-10">
                  <h2 class="section-title">Personal Information</h2>
                  <v-divider></v-divider>
                </div>
                <v-row>
                  <v-col v-if="user.profile_image" cols="12" xl="4" lg="4" md="12" sm="12">
                    <v-card class="item-card">
                      <div class="item-image-container">
                        <img class="item-image" :src="user.profile_image" :alt="'passenger profile picture'">
                      </div>
                    </v-card>
                  </v-col>
                  <v-col class="mt-6" cols="12" xl="8" lg="8" md="12" sm="12">
                    <v-col cols="8">
                      <span v-if="user.name" class="title-font-size">Full Name:</span> &nbsp;{{ user.name }}
                    </v-col>
                    <v-col cols="8">
                      <span class="title-font-size">Phone Number:</span> &nbsp;{{ user.phone_number }}
                    </v-col>
                    <v-col cols="8">
                      <span class="title-font-size">GetStream Token:</span> &nbsp;{{ user.get_stream_token }}
                    </v-col>
                    <v-col cols="8">
                      <span class="title-font-size">Email:</span> &nbsp;{{ user.email }}
                    </v-col>
                    <v-col cols="8">
                      <span class="title-font-size">Average Rate:</span>
                      <span v-if="user.average_rate">
                        {{ user.average_rate }}
                      </span>
                      <span v-else>
                        has not any rate
                      </span>&nbsp;
                    </v-col>
                    <v-col cols="8">
                      <span class="title-font-size">Number of rates:</span>
                      <span v-if="user.count_rate">
                        {{ user.count_rate }}
                      </span>
                      <span v-else>
                        has not any rate
                      </span>
                    </v-col>
                    <v-col cols="8">
                      <span class="title-font-size">GetStream Token:</span> &nbsp;{{ user.get_stream_token }}
                    </v-col>
                    <v-col cols="8">
                      <span class="title-font-size">Created At:</span> &nbsp;{{ user.created_at }}
                    </v-col>
                    <v-col cols="8">
                      <span v-if="user.updated_at" class="title-font-size">Updated At:</span>
                      &nbsp;{{ user.updated_at }}
                    </v-col>
                    <v-col cols="8">
                      <span class="title-font-size">Device Info:</span> {{ user.device_info }}
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
              <!--payment info-->
              <v-col cols="12" xl="6" lg="6" md="12" sm="12">
                <div v-if="user.pm_type" class="mt-5 mb-10">
                  <h2 class="section-title">Payment Information</h2>
                  <v-divider></v-divider>
                </div>
                <v-col class="mt-7">
                  <v-col v-if="user.pm_type" cols="12">
                    <span class="title-font-size">Payment Type:</span> &nbsp;{{ user.pm_type }}
                  </v-col>
                  <v-col v-if="user.pm_last_four" cols="12">
                    <span class="title-font-size">Payment Last Four Number:</span> &nbsp;{{ user.pm_last_four }}
                  </v-col>
                  <v-col v-if="user.last_payment_option_id" cols="12">
                    <span class="title-font-size">Last Payment Option Id :</span> &nbsp;{{
                      user.last_payment_option_id
                    }}
                  </v-col>
                  <v-col v-if="user.trial_ends_at" cols="12">
                    <span class="title-font-size">Trial Ends At:</span> &nbsp;{{ user.trial_ends_at.substring(0, 10) }}
                  </v-col>
                </v-col>
              </v-col>
            </v-row>
          </v-col>

        </v-row>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import {getAdminPermissions} from "@/middleware/hasPermission";

export default {
  name: "ShowUserDetails",
  data() {
    return {
      user: null,
    }
  },
  mounted() {
    let adminPermissions = getAdminPermissions()
    if (adminPermissions.includes('show_user')) {
      this.showUserDetails()
    }
  },
  methods: {
    showUserDetails() {
      this.$store.dispatch('showUserDetails', this.$route.params.id).then((res) => {
        this.user = res.data.data
        console.log(this.user)
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  },
}
</script>

<style scoped>
.item-card {
  padding: 20px 10px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.item-image-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.item-image {
  height: fit-content;
  width: 100%;
  border-radius: 15px;

}

.title-font-size {
  font-size: 18px
}
</style>