<template>
  <div class="mt-16">
    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Transaction Details</div>
        <v-row class="mlr-1">
          <v-col cols="12" class="pa-0">
            <v-btn text @click="$router.replace('/users')" class="pa-0">
              <v-icon x-large color="#00b2ff">mdi-arrow-left</v-icon>
              &nbsp; Passengers List
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="transaction" class="mx-5">
          <v-col cols="12">
            <!-- personal info -->
            <div class="mt-5 mb-10">
              <h2 class="section-title">Payment Information</h2>
              <v-divider></v-divider>
            </div>
            <!--            <v-row>-->

            <!--              <v-col cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span v-if="transaction.amount" class="title-font-size">Amount:</span> &nbsp;{{-->
            <!--                  transaction.amount-->
            <!--                }}-->
            <!--              </v-col>-->
            <!--              <v-col cols="8" v-if="transaction.type">-->
            <!--                <span class="title-font-size">Type:</span> &nbsp;{{ transaction.type }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.payment_intent_id" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Payment Intent ID:</span>-->
            <!--                &nbsp;{{ transaction.payment_intent_id }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.balance_transaction" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Transaction Balance:</span> &nbsp;{{ transaction.balance_transaction }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.card_brand" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Card Brand:</span> &nbsp;{{ transaction.card_brand }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.card_country" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Card Country:</span> &nbsp;{{ transaction.card_country }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.card_last4" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Card Last Four Numbers:</span> &nbsp;{{ transaction.card_last4 }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.currency" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Currency:</span> &nbsp;{{ transaction.currency }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.payment_method_id" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Payment Method ID:</span> &nbsp;{{ transaction.payment_method_id }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.charge_id" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Charge ID:</span> &nbsp;{{ transaction.charge_id }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.refund_id" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Refund ID:</span> &nbsp;{{ transaction.refund_id }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.created_at" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Created At:</span> &nbsp;{{ transaction.created_at.substring(0, 10) }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.updated_at" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Updated At:</span> &nbsp;{{ transaction.updated_at.substring(0, 10) }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.trip.states" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Trip Status:</span> &nbsp;{{ transaction.trip.states }}-->
            <!--              </v-col>-->
            <!--              &lt;!&ndash;                      <v-col v-if="transaction.price" cols="12" xl="3" lg="3" md="6" sm="12">&ndash;&gt;-->
            <!--              &lt;!&ndash;                        <span class="title-font-size">Price:</span> &nbsp;{{ transaction.price }}&ndash;&gt;-->
            <!--              &lt;!&ndash;                      </v-col>&ndash;&gt;-->
            <!--              <v-col v-if="transaction.trip.start_time" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Start Time:</span> &nbsp;{{-->
            <!--                  transaction.trip.start_time.substring(0, 10)-->
            <!--                }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.trip.end_time" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">End Time:</span> &nbsp;{{ transaction.trip.end_time.substring(0, 10) }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.trip.distance" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Distance:</span> &nbsp;{{ transaction.trip.distance / 1000 }}Km-->
            <!--              </v-col>-->

            <!--              <v-col v-if="transaction.trip.origin_address" cols="12" xl="3" lg="6" md="6" sm="12">-->
            <!--                <span class="title-font-size">Origin Address:</span> &nbsp;{{ transaction.trip.origin_address }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.trip.destination_address" cols="12" xl="6" lg="6" md="6" sm="12">-->
            <!--                <span class="title-font-size">Destination Address:</span>-->
            <!--                &nbsp;{{ transaction.trip.destination_address }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.trip.surge_multiplier" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Surge Multiplier:</span> &nbsp;{{ transaction.trip.surge_multiplier }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.trip.eta" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">ETA:</span> &nbsp;{{ transaction.trip.eta }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.trip.tip" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Tip:</span> &nbsp;{{ transaction.trip.tip }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.trip.rate_from_passenger" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Rate From Passenger:</span>-->
            <!--                &nbsp;{{ transaction.trip.rate_from_passenger }}-->
            <!--              </v-col>-->
            <!--              <v-col v-if="transaction.trip.rate_from_driver" cols="12" xl="3" lg="3" md="6" sm="12">-->
            <!--                <span class="title-font-size">Rate From Driver:</span> &nbsp;{{ transaction.trip.rate_from_driver }}-->
            <!--              </v-col>-->

            <!--            </v-row>-->
            <v-data-table
                align="center"
                :headers="headers"
                :items="transaction.data"
                :items-per-page="10"
                class="elevation-5"
                hide-default-footer>
              <template
                  v-slot:[`item.created_at_date`]="{ item }">
                <span>{{ item.created_at}}</span>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <v-pagination
                color="#00b2ff"
                v-model="transaction.current_page"
                :length="transaction.last_page"
                circle
                @input="showUserTransactions"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import {getAdminPermissions} from "@/middleware/hasPermission";

export default {
  name: "ShowUserTransactions",
  data() {
    return {
      transaction: null,
      user: null,
      headers: [
        {text: 'Type', value: 'type', align: 'center'},
        {text: 'Payment Intent ID', value: 'payment_intent_id', align: 'center'},
        {text: 'Balance Transaction', value: 'balance_transaction', align: 'center'},
        {text: 'Card Brand', value: 'card_brand', align: 'center'},
        {text: 'Card Country', value: 'card_country', align: 'center'},
        {text: 'Charge ID', value: 'charge_id', align: 'center'},
        {text: 'Refund ID', value: 'refund_id', align: 'center'},
        {text: 'Trip ID', value: 'trip.id', align: 'center'},
        {text: 'Customer Id', value: 'customer_id', align: 'center'},
        {text: 'Payment Type', value: 'type', align: 'center'},
        {text: 'Card Last 4', value: 'card_last4', align: 'center'},
        {text: 'Currency', value: 'currency', align: 'center'},
        {text: 'Created At', value: 'created_at_date', align: 'center'},
      ],
    }
  },
  mounted() {
    let adminPermissions = getAdminPermissions()
    if (adminPermissions.includes('user_transactions')) {
      this.showUserTransactions()
    }
  },
  methods: {
    showUserTransactions() {
      this.$store.dispatch('showUserTransactions', this.$route.params.id).then((res) => {
        console.log(res)
        this.transaction = res.data.data
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  },
}
</script>

<style scoped>
.section-title {
  width: fit-content;
}

.item-card {
  padding: 20px 10px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.title-font-size {
  font-size: 18px
}
</style>