<template>
  <v-row class="ma-16">
    <v-col cols="12">
      <v-text-field label="Event" v-model="event"></v-text-field>
      <v-btn :disabled="!(this.event)" @click="connectToWebSocket">Connect</v-btn>
    </v-col>
    <v-col cols="12">
      <h3>Received Messages</h3>
      <ul>
        <li v-for="(message, index) in messages" :key="index">{{ message }}</li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
//window.Pusher = require('pusher-js');

export default {
  name: 'TestSocket',

  data() {
    return {
      axios: null,

      pusher: null,
      channel: null,

      event: 'admin.testEvent',

      messages: [],
    }
  },

  methods: {
    async connectToWebSocket() {
      await this.initialAxios()
      await this.initialSocket()
      await this.listenToChannels()
    },

    initialAxios() {
      this.axios = axios
      this.axios.defaults.baseURL = "http://localhost:8000/";
      this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
      this.axios.defaults.headers.post['Content-Type'] = 'application/json';
    },

    initialSocket() {
      // this.pusher = new window.Pusher("myKey", {
      //   cluster: "mt1",
      //   wsHost: 'localhost',
      //   wsPort: 6001,
      //   forceTLS: false,
      //   encrypted: true,
      //   disableStats: true,
      //   auth: {
      //     headers: {
      //       Authorization: 'Bearer ' + this.$cookies.get('token')
      //     }
      //   },
      //   authorizer: (channel) => {
      //     return {
      //       authorize: (socketId, callback) => {
      //         axios.post('/api/broadcasting/auth', {
      //           socket_id: socketId,
      //           channel_name: channel.name
      //         })
      //             .then(response => {
      //               callback(false, response.data);
      //             })
      //             .catch(error => {
      //               callback(true, error);
      //             });
      //       }
      //     };
      //   },
      // });
      // this.channel = this.pusher.subscribe("private-Admin." + Number.parseInt(this.$store.state.admin.id));
    },

    listenToChannels() {
      this.channel.bind(this.event, (data) => {
        console.log('New Data Received From Channel')
        this.messages.push(data)
      });

      this.channel.bind("server.messageReceived", (data) => {
        console.log('Your Message Received On Server')
        this.messages.push(data)
      });
    },

    // sendDataToChannel() {
    //   this.channel.trigger('client-admin-test-event', {
    //     token: this.$cookies.get('token'),
    //     channel: 'private-Admin.' + this.$store.state.admin.id,
    //     message: JSON.stringify({data: 'test'})
    //   });
    // }
  },
}
</script>
