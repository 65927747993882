<template>
  <div>
    <drivers-crud></drivers-crud>
  </div>
</template>

<script>
import DriversCrud from '@/components/Drivers/DriversCrud'

export default {
  name: "DriversSetting",
  components: {
    DriversCrud
  }
}
</script>

<style scoped>

</style>