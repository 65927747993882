<template>
  <v-row class="mb-10 mt-10 rounded-15 mlr-1">
    <v-col cols="12" xl="3" lg="3" md="3">
      <v-text-field label="Search Method Or API"
                    v-model="needle">
      </v-text-field>
    </v-col>
    <v-col cols="12" xl="3" lg="3" md="3">
      <v-text-field label="Search Admin"
                    v-model="needle_admin">
      </v-text-field>
    </v-col>
    <v-col cols="12" xl="3" lg="3" md="3">
      <v-select v-can="'index_permission'"
                color="#00b2ff"
                label="Permissions"
                v-model="action"
                :items="actions"
                clearable
                multiple
                item-text="name"
                item-value="value">
      </v-select>
    </v-col>
    <v-col cols="12">
      <v-btn rounded @click="search" color="#00b2ff"
             class="white--text">
        Search
      </v-btn>
      <v-btn rounded @click="reset" color="#ffa600"
             class="white--text ml-3">
        Reset
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ActivitiesFilter",
  data(){
    return{
      needle:null,
      needle_admin:null,
      action:[],
      actions:[{name: 'create', value:'create'},{name: 'delete', value:'delete'},{name: 'update', value:'update'},{name: 'seen', value:'seen'}]
    }
  },
  methods:{
    search() {
      this.$emit('filter-applied', {
        needle: this.needle,
        needle_admin: this.needle_admin,
        action: this.action,
      })
    },
    reset() {
      this.needle = null
      this.needle_admin=null
      this.action = []
      this.search()
    }
  }
}
</script>

<style scoped>

</style>