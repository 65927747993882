<template>
  <div>
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-row class="mt-16 align-content-center">
      <v-col cols="12">
        <v-row class="mt-16 justify-center align-content-center">
          <v-col cols="12" xl="4" lg="4" md="6" sm="12" class="pl-10 pr-10">
            <v-toolbar-title class="title-styles font-weight-bold" style="text-align: center; font-size: x-large">
              Reset Password
            </v-toolbar-title>
            <v-text-field
                dense
                rounded
                filled
                hide-details
                v-model="admin.new_password"
                class="elevation-0 mt-12"
                :rules="rules.required"
                label="New Password"
                type="password"
            ></v-text-field>
            <v-text-field
                dense
                rounded
                filled
                hide-details
                v-model="admin.new_password_confirmation"
                class="elevation-0 mt-12"
                :rules="rules.required"
                label="New Password Confirmation"
                type="password"
            ></v-text-field>
            <v-btn class="mt-12 white--text"
                   block
                   x-large
                   color="#00b2ff"
                   rounded @click="resetPassword()">
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      admin: {
        new_password_confirmation: null,
        new_password: null,
      },
      rules: {
        required: [(value) => !!value || "this field is required"]
      }
    }
  },
  methods: {
    resetPassword() {
      this.$store.dispatch("resetPassword", {
        new_password: this.admin.new_password,
        new_password_confirmation: this.admin.new_password_confirmation,
        token: this.$route.query.token,
        email: this.$route.query.email,
      }).then(() => {
        this.openSnackbar('Password Changed Successfully', '#50cd89')
        setTimeout(() => {
          this.$router.push('/login')
        }, 3000)
      }).catch((err) => {
        console.log(err)
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  },
  mounted() {
    if (this.$route.query.token === null || this.$route.query.email === null) {
      this.openSnackbar('Your reset password link is not valid', '#50cd89')
    }
  }
}
</script>

<style scoped>
.btn-design {
  text-decoration-color: white !important;
  height: 3vh !important;
  font-size: xx-large;
}

.font-color {
  color: #00b2FF;
  cursor: pointer;
}

.text-decor {
  color: #A1A5B7;
  font-size: medium;
}
</style>