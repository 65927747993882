<template>
  <div>
    <!--SNACK BAR  -->
    <v-row>
      <v-col class="text-center">
        <v-snackbar
            multi-line
            width="500px"
            centered
            top
            v-model="snackbarStatus"
            :color="snackbarColor"
        >
          <p v-html="snackbarText" class="snackbar-p"></p>
          <template v-slot:action="{ attrs }">
            <v-btn
                fab
                text
                v-bind="attrs"
                @click="snackbarStatus = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Trips</div>
        <!-- SEARCH-->
        <trips-filter v-if="$route.path==='/trips'" v-can="'index_trip'" :drivers="drivers" :users="users"
                      v-on:filter-applied="applyFilter"></trips-filter>
        <!-- TRIPS TABLE-->
        <v-data-table
            align="center"
            :headers="headers"
            :items="trips.data"
            :custom-sort="(items) => items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @update:sort-by="applySort"
            @update:sort-desc="applySort"
            :items-per-page="10"
            class="elevation-5"
            hide-default-footer>
          <template
              v-slot:[`item.driver.full_name`]="{ item }">
            <span v-if="item.driver">{{ item.driver.first_name }}</span> <span
              v-if="item.driver">{{ item.driver.last_name }}</span><span v-if="item.driver"> | {{
              item.driver.phone_number
            }}</span>
          </template>
          <template
              v-slot:[`item.user.name`]="{ item }">
            <span v-if="item.user">{{ item.user.name }}</span><span
              v-if="item.user"> | {{ item.user.phone_number }}</span>
          </template>
          <template
              v-slot:[`item.states`]="{ item }">
            <span>{{ item.states }}</span><span v-if="item.states==='cancelled_by_driver'">- Reason:
          {{
              item.cancel_reason
            }}
          </span>
            <span v-if="item.states==='wait_for_ride' || item.states==='in_trip' ">
            <br>
            <v-btn small color="warning" @click="finish(item.id)">Finish Manually</v-btn>
          </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn v-can="'show_trip'" class="mx-2" fab color="#00b2ff" x-small elevation="0"
                   @click="$router.push('/trip-details/' + item.id)">
              <v-icon color="white">mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col col="3"></v-col>
      <v-col cols="6">
        <v-pagination
            color="#00b2ff"
            v-model="trips.current_page"
            :length="trips.last_page"
            circle
            @input="TripsIndex"
        ></v-pagination>
      </v-col>
      <v-col col="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import TripsFilter from "@/components/Trips/TripsFilter";
import {getAdminPermissions} from "@/middleware/hasPermission";

export default {
  name: "TripsIndex",
  components: {TripsFilter},
  data() {
    return {
      sortBy: null,
      sortDesc: false,
      sortParams: null,
      trips: {
        data: []
      },
      headers: [
        {text: 'Origin Address', value: 'origin_address', align: 'center'},
        {text: 'Destination Address', value: 'destination_address', align: 'center'},
        {text: 'Status', value: 'states', align: 'center'},
        {text: 'Start Time', value: 'start_time', align: 'center'},
        {text: 'Passenger', value: 'user.name', align: 'center'},
        {text: 'Driver', value: 'driver.full_name', align: 'center'},
        {text: 'Vehicle Type', value: 'vehicle_type.title', align: 'center'},
        {text: 'Actions', value: 'actions', align: 'center', sortable: false},
      ],


      trip: {
        origin_address: null,
        destination_address: null,
        states: null,
        start_time: null,
        user: {
          name: null,
        },
        driver: {
          first_name: null,
          last_name: null,
        },
        origin: {
          coordinates: {}
        },
        destination: {
          coordinates: {}
        },
        vehicle_type: {
          title: null,
        },
        cancel_reason: null,
      },
      origins: [],
      destinations: [],
      drivers: [],
      users: [],
      selectedTrip: null,
      user_id: null,
      UserStates: [],
      filterParams: null,
    }
  },
  mounted() {
    let adminPermissions = getAdminPermissions()
    if (adminPermissions.includes('index_trip')) {
      this.TripsIndex()
    }
  },
  methods: {
    finish(tripId) {
      window.axios.get('/admin/trip/finish/' + tripId).then(() => {
        this.TripsIndex()
      }).catch((err) => {
        this.openSnackbar(err.response.data.message, '#F1416C')
      })
    },
    applySort() {
      if (this.sortBy && this.sortDesc !== undefined) {
        let sortDirection = this.sortDesc ? 'desc' : 'asc';
        this.sortParams = `&sort[field]=${this.sortBy}&sort[direction]=${sortDirection}`
      } else {
        this.sortParams = null;
      }
      this.TripsIndex()
    },
    makeQueryParameters() {
      if (this.filterParams != null && this.sortParams != null) {
        return this.filterParams + this.sortParams
      } else if (this.filterParams != null) {
        return this.filterParams;
      } else if (this.sortParams != null) {
        return this.sortParams
      }
      return null
    },
    TripsIndex() {
      this.$store.dispatch('getTripsIndex', {
        page: this.trips.current_page,
        filter: this.makeQueryParameters()
      }).then((res) => {
        this.trips = res
      }).catch((err) => {
        console.log(err.response.data)
      })
    },
    applyFilter(params) {
      this.trips.current_page = 1
      this.filterParams = this.objectParametersToString(params)
      this.TripsIndex()
    },
  }
}
</script>

<style scoped>

</style>