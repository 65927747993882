<template>
  <div>
    <v-app-bar
        class="nav-size"
        absolute
        color="#00b2ff"
        elevate-on-scroll>
      <div style="width: 120px">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" style="color: white"></v-app-bar-nav-icon>
      </div>
      <v-spacer></v-spacer>
      <v-app-bar-title>
        <h1 style="color: white; font-family: Capriola,serif">APT RIDES</h1>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <div style="width: 120px">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab v-bind="attrs" v-on="on" text class="profile-menu-btn mx-2" @click="raisedNotifications = false">
              <v-icon :color="raisedNotifications ? 'error' : 'white'" :size="raisedNotifications ? '40px' : '30px'">
                mdi-bell
              </v-icon>
              <span v-if="emergencyNotifs.length" style="color: darkred; font-size: 18px">{{
                  emergencyNotifs.length
                }}</span>
            </v-btn>
          </template>
          <v-card width="470px">
            <v-card-text>
              <div v-for="(emergencyNotif, index) in emergencyNotifs" :key="index + 'notif'">
                <v-row class="my-1" style="align-items: center">
                  <v-col cols="9">
                    Emergency Request By {{ emergencyNotif.request_by.toUpperCase() }} :
                    {{
                      emergencyNotif.request_by === 'driver' ?
                          emergencyNotif.driver.first_name + ' ' + emergencyNotif.driver.last_name :
                          emergencyNotif.user.name
                    }}
                  </v-col>
                  <v-col cols="3" style="display: flex; justify-content: center">
                    <v-btn :to="'live-trips?trip_id=' + emergencyNotif.trip.id" small color="#50cd89">Show</v-btn>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab v-bind="attrs" v-on="on" text class="profile-menu-btn">
              <v-icon color="white" size="35px">mdi-account</v-icon>
            </v-btn>
          </template>
          <v-card width="275px">
            <v-card-title>
              <v-row>
                <v-col cols="3" class="mt-2">
                  <img v-if="$store.state.admin.profile_image" :src="$store.state.admin.profile_image" width="100%">
                  <img v-else src="../../../public/img/avatar.png" width="100%">
                </v-col>
                <v-col cols="9" class="align-self-center">
                  <span>{{ $store.state.admin.name }}</span><br>
                  <span class="profile-menu-email">{{ $store.state.admin.email }}</span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-btn class="blue-on-hover" width="100%" text to="/profile">My Profile</v-btn>
                  <v-btn class="blue-on-hover" width="100%" text @click="signOut">Sign Out</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>
    <!--    SIDE-BAR-->
    <v-navigation-drawer
        v-model="drawer"
        fixed
        top
        temporary
        left
        height="100vh"
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            active-class="info--text text--accent-4"
        >
          <v-list-item to="/home">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item to="/live-trips" v-can="'live_trip'">
            <v-list-item-icon>
              <v-icon>mdi-gesture-double-tap</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Live Trips</v-list-item-title>
          </v-list-item>
          <v-list-item to="/users" v-can="'index_user'">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Passengers</v-list-item-title>
          </v-list-item>
          <v-list-item to="/drivers" v-can="'index_driver'">
            <v-list-item-icon>
              <v-icon>mdi-card-account-details</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Drivers</v-list-item-title>
          </v-list-item>

          <v-list-item to="/trips" v-can="'index_trip'">
            <v-list-item-icon>
              <v-icon>mdi-road-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Trips</v-list-item-title>
          </v-list-item>
          <v-list-item to="/transactions" v-can="'index_transaction'">
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Transactions</v-list-item-title>
          </v-list-item>
          <v-list-item to="/financial-report">
            <v-list-item-icon>
              <v-icon>mdi-poll</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Financial Report</v-list-item-title>
          </v-list-item>
          <v-list-item to="/goals" v-can="'index_goal'">
            <v-list-item-icon>
              <v-icon>mdi-flag-checkered</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Goals</v-list-item-title>
          </v-list-item>
          <v-list-item to="/lost-items" v-can="'index_lostItem'">
            <v-list-item-icon>
              <v-icon>mdi-blinds-horizontal-closed</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Lost Items</v-list-item-title>
          </v-list-item>
          <v-list-item to="/notifications" v-can="'index_notification'">
            <v-list-item-icon>
              <v-icon>mdi-bell-badge</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Notifications</v-list-item-title>
          </v-list-item>
          <v-list-group >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Settings</v-list-item-title>
            </template>
            <v-list-item to="/settings/vehicle-types" v-can="'index_vehicle_type'">
              <v-list-item-title class="sub-list-item-title">Vehicle Types</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-car</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item to="/settings/allowed-areas" v-can="'index_allowed_area'">
              <v-list-item-title class="sub-list-item-title">Allowed Areas</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-map-check</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item to="/settings/hot-zones" v-can="'index_hot_zone'">
              <v-list-item-title class="sub-list-item-title">Hot Zones</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-fire</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item to="/settings/pickup-area" v-can="'index_ccmp_pickup_area'">
              <v-list-item-title class="sub-list-item-title">Pickup Areas</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-map-check</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item to="/settings/dropoff-area" v-can="'index_ccmp_dropoff_area'">
              <v-list-item-title class="sub-list-item-title">Drop-Off Areas</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-map-check</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item to="/settings/user-init-data" v-can="'show_user_initial_data'">
              <v-list-item-title class="sub-list-item-title">Passenger Settings</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-account-cog-outline</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item to="/settings/driver-init-data" v-can="'show_driver_initial_data'">
              <v-list-item-title class="sub-list-item-title">Driver Settings</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-car-side</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item to="/settings/setting" v-can="'index_setting'">
              <v-list-item-title class="sub-list-item-title">General Settings</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-cog-outline</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
          <v-list-group >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title>System</v-list-item-title>
            </template>
            <v-list-item to="/admins" v-can="'index_admin'">
              <v-list-item-title class="sub-list-item-title">Admins</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-account-tie</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item to="/activities" v-can="'index_activity'">
              <v-list-item-title class="sub-list-item-title">Admin Activities</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-electron-framework</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item to="/roles" v-can="'index_role'">
              <v-list-item-title class="sub-list-item-title">Roles</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-key</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item to="/settings/application-versions" v-can="'index_setting'">
              <v-list-item-title class="sub-list-item-title">Application Versions</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-cellphone-key</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item @click="goToLogs()">
              <v-list-item-title class="sub-list-item-title">System/Service Log</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-calculator-variant-outline</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
          <v-list-item @click="signOut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
window.Pusher = require('pusher-js');

export default {
  name: "NavigationBar",
  data: () => ({
    emergencyNotifs: [],
    raisedNotifications: false,
    drawer: false,
    group: null,
    watch: {
      group() {
        this.drawer = false
      },
    },
  }),
  mounted() {
    this.connectToWS()
  },
  methods: {
    goToLogs() {
      window.open( process.env.VUE_APP_LOG_URL);
    },
    signOut() {
      this.$store.dispatch("signOut")
    },

    connectToWS() {
      this.pusher = new window.Pusher("myKey", {
        cluster: "mt1",
        wsHost: process.env.VUE_APP_WS_HOST,
        wsPort: process.env.VUE_APP_WS_PORT,
        forceTLS: process.env.VUE_APP_FORCE_TLS !== 'false',
        encrypted: true,
        disableStats: true,
        auth: {
          headers: {
            Authorization: 'Bearer ' + this.$cookies.get('token')
          }
        },
        authorizer: (channel) => {
          return {
            authorize: (socketId, callback) => {
              window.axios.post(process.env.VUE_APP_BACKEND_BASE_URL + 'broadcasting/auth', {
                socket_id: socketId,
                channel_name: channel.name
              })
                  .then(response => {
                    window.axios.get('admin/trip/sos/start')
                    callback(false, response.data);
                  })
                  .catch(error => {
                    callback(true, error);
                  });
            }
          };
        },
      });
      let channel = this.pusher.subscribe("presence-Admin");

      channel.bind("trip.admin.emergency", (data) => {
        //console.log(data)
        let count = this.emergencyNotifs.length
        this.emergencyNotifs = data.data
        if (count < this.emergencyNotifs.length) {
          this.raisedNotifications = true
        }
      });
    }
  }
}
</script>
<style scoped>
.nav-size {
  max-height: 17vh;
}

.profile-menu-email {
  font-size: 10px !important;
  color: #7c808d;
}

.profile-menu-btn {
  width: 48px !important;
  height: 48px !important;
  margin-right: -12px
}

.sub-list-item-title {
  margin-left: 57px !important;
}
</style>
