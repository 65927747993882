<template>
  <div>
    <!--  SNACK BAR  -->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-row class="mt-16">
      <v-col cols="12">
        <div class="ml-1 mr-1 text-center title-container">Profile</div>
        <v-row class="mt-10 align-content-center" v-if="admin">
          <v-col class="text-center align-content-center">
            <v-row class="justify-center">
              <v-col cols="5" class="px-16">
                <v-text-field label="Name" v-model="admin.name"></v-text-field>
                <v-text-field label="Email" v-model="admin.email"></v-text-field>
                <v-btn color="#00b2ff" rounded block class="mt-4 white--text" @click="updateProfile()">Edit</v-btn>
                <v-expansion-panels class="mt-15 text-center">
                  <v-expansion-panel
                      class="my-0"
                  >
                    <v-expansion-panel-header class="my-0">
                      Change Password
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="my-0">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                              type="password"
                              color="#00b2ff"
                              class="ml-5"
                              v-model="admin.old_password"
                              label="Old Password"
                          ></v-text-field>
                          <v-text-field
                              type="password"
                              color="#00b2ff"
                              class="ml-5"
                              v-model="admin.new_password"
                              label="New Password"
                          ></v-text-field>
                          <v-text-field
                              type="password"
                              color="#00b2ff"
                              class="ml-5"
                              v-model="admin.new_password_confirmation"
                              label="New Password Confirmation"
                          ></v-text-field>
                          <v-btn color="#ffa600" rounded block class="px-16 white--text" @click="changePassword()">
                            Update
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-row class="mt-16">
        <v-col cols="12" class="text-center">
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AdminProfile",
  data() {
    return {
      admin: null,
      old_password: null,
      new_password: null,
      new_password_confirmation: null,
      permissionsIds: [],
      permissions: null,
    }
  },
  async mounted() {
    await this.$store.dispatch("getProfile").then(() => {
      this.admin = this.$store.state.admin
    }).catch((err) => {
      console.log(err.response.data)
    })
  },
  methods: {
    updateProfile() {
      this.$store.dispatch('updateProfile', {admin: this.admin}).then(() => {
        this.openSnackbar(this.admin.name + ' Updated Successfully', '#50cd89')
      }).catch((err) => {
        console.log(err)
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
    changePassword() {
      this.$store.dispatch('changePassword', {admin: this.admin}).then(() => {
        this.openSnackbar(this.admin.name + ' Password Changed Successfully', '#50cd89')
      }).catch((err) => {
        console.log(err)
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
  }
}
</script>

<style scoped>
.span-text-size {
  font-size: small;
}
</style>