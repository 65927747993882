export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getDrivers(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/driver/crud/index" + (params ?? ''))
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getPaginatedDrivers(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/driver/crud/index?paginate&page=" + (params.page ?? 1) + (params.filter ?? ''))
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        createDriver(_, data) {
            return new Promise((resolve, reject) => {
                let newData = new FormData
                if (data.email)
                    newData.append('email', data.email)
                if (data.phone_number)
                    newData.append('phone_number', data.phone_number)
                if (data.vehicle_type_id)
                    newData.append('vehicle_type_id', data.vehicle_type_id)
                if (data.available_vehicle_types && data.available_vehicle_types.length>0)
                    newData.append('available_vehicle_types', data.available_vehicle_types)
                if (data.first_name)
                    newData.append('first_name', data.first_name)
                if (data.last_name)
                    newData.append('last_name', data.last_name)
                if (data.nick_name)
                    newData.append('nick_name', data.nick_name)
                if (data.address)
                    newData.append('address', data.address)
                if (data.emergency_contact_name)
                    newData.append('emergency_contact_name', data.emergency_contact_name)
                if (data.alternate_phone_number)
                    newData.append('alternate_phone_number', data.alternate_phone_number)
                if (data.emergency_contact_phone_number)
                    newData.append('emergency_contact_phone_number', data.emergency_contact_phone_number)
                if (data.headshot_picture)
                    newData.append('headshot_picture', data.headshot_picture)
                if (data.stripe_id)
                    newData.append('stripe_id', data.stripe_id)
                data.driver_attachment.car_pictures.forEach((item) => {
                    newData.append('car_pictures[]', item)
                })
                if (data.driver_attachment && data.driver_attachment.color)
                    newData.append('color', data.driver_attachment.color)
                if (data.driver_attachment && data.driver_attachment.model)
                    newData.append('model', data.driver_attachment.model)
                if (data.driver_attachment && data.driver_attachment.vehicle_make)
                    newData.append('vehicle_make', data.driver_attachment.vehicle_make)
                if (data.driver_attachment && data.driver_attachment.vehicle_is_electric)
                    newData.append('vehicle_is_electric', data.driver_attachment.vehicle_is_electric)
                if (data.driver_attachment && data.driver_attachment.license_plate)
                    newData.append('license_plate', data.driver_attachment.license_plate)
                if (data.driver_attachment && data.driver_attachment.commercial_inspection)
                    newData.append('commercial_inspection', data.driver_attachment.commercial_inspection)
                if (data.driver_attachment && data.driver_attachment.commercial_inspection_expire)
                    newData.append('commercial_inspection_expire', data.driver_attachment.commercial_inspection_expire)
                if (data.driver_attachment.criminal_check)
                    newData.append('criminal_check', data.driver_attachment.criminal_check)
                if (data.driver_attachment && data.driver_attachment.abstract_expire)
                    newData.append('abstract_expire', data.driver_attachment.abstract_expire)
                if (data.driver_attachment && data.driver_attachment.criminal_check_expire)
                    newData.append('criminal_check_expire', data.driver_attachment.criminal_check_expire)
                if (data.driver_attachment.driving_record)
                    newData.append('driving_record', data.driver_attachment.driving_record)
                if (data.driver_attachment && data.driver_attachment.work_permit_expire)
                    newData.append('work_permit_expire', data.driver_attachment.work_permit_expire)
                if (data.driver_attachment.work_permit)
                    newData.append('work_permit', data.driver_attachment.work_permit)
                if (data.driver_attachment.commercial_inspection_image)
                    newData.append('commercial_inspection_image', data.driver_attachment.commercial_inspection_image)
                if (data.driver_attachment.maintenance_record)
                    newData.append('maintenance_record', data.driver_attachment.maintenance_record)
                if (data.driver_attachment && data.driver_attachment.licence_number)
                    newData.append('licence_number', data.driver_attachment.licence_number)
                if (data.driver_attachment && data.driver_attachment.licence_expire)
                    newData.append('licence_expire', data.driver_attachment.licence_expire)
                if (data.driver_attachment.insurance)
                    newData.append('insurance', data.driver_attachment.insurance)
                if (data.driver_attachment && data.driver_attachment.insurance_expire)
                    newData.append('insurance_expire', data.driver_attachment.insurance_expire)
                window.axios.post("/admin/driver/crud/create", newData).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        updateDriver(_, data) {
            return new Promise((resolve, reject) => {
                let newData = new FormData
                if (data.driver.email)
                    newData.append('email', data.driver.email)
                if (data.driver.phone_number)
                    newData.append('phone_number', data.driver.phone_number)
                if (data.driver.vehicle_type_id)
                    newData.append('vehicle_type_id', data.driver.vehicle_type_id)
                if (data.driver.available_vehicle_types)
                    newData.append('available_vehicle_types', data.driver.available_vehicle_types)
                if (data.driver.first_name)
                    newData.append('first_name', data.driver.first_name)
                if (data.driver.last_name)
                    newData.append('last_name', data.driver.last_name)
                if (data.driver.nick_name)
                    newData.append('nick_name', data.driver.nick_name)
                if (data.driver.address)
                    newData.append('address', data.driver.address)
                if (data.driver.emergency_contact_name)
                    newData.append('emergency_contact_name', data.driver.emergency_contact_name)
                if (data.driver.alternate_phone_number)
                    newData.append('alternate_phone_number', data.driver.alternate_phone_number)
                if (data.driver.emergency_contact_phone_number)
                    newData.append('emergency_contact_phone_number', data.driver.emergency_contact_phone_number)
                if (data.driver.headshot_picture)
                    newData.append('headshot_picture', data.driver.headshot_picture)
                if (data.stripe_id)
                    newData.append('stripe_id', data.stripe_id)
                if (data.driver.driver_attachment.car_pictures) {
                    data.driver.driver_attachment.car_pictures.forEach((item) => {
                        newData.append('car_pictures[]', item)
                    })
                }
                if (data.driver.driver_attachment.color)
                    newData.append('color', data.driver.driver_attachment.color)
                if (data.driver.driver_attachment.model)
                    newData.append('model', data.driver.driver_attachment.model)
                if (data.driver.driver_attachment.vehicle_make)
                    newData.append('vehicle_make', data.driver.driver_attachment.vehicle_make)
                if (data.driver.driver_attachment.vehicle_is_electric)
                    newData.append('vehicle_is_electric', data.driver.driver_attachment.vehicle_is_electric)
                if (data.driver.driver_attachment.license_plate)
                    newData.append('license_plate', data.driver.driver_attachment.license_plate)
                if (data.driver.driver_attachment.commercial_inspection)
                    newData.append('commercial_inspection', data.driver.driver_attachment.commercial_inspection)
                if (data.driver.driver_attachment.commercial_inspection_expire)
                    newData.append('commercial_inspection_expire', data.driver.driver_attachment.commercial_inspection_expire)
                if (data.driver.driver_attachment.criminal_check)
                    newData.append('criminal_check', data.driver.driver_attachment.criminal_check)
                if (data.driver.driver_attachment.abstract_expire)
                    newData.append('abstract_expire', data.driver.driver_attachment.abstract_expire)
                if (data.driver.driver_attachment.criminal_check_expire)
                    newData.append('criminal_check_expire', data.driver.driver_attachment.criminal_check_expire)
                if (data.driver.driver_attachment.driving_record)
                    newData.append('driving_record', data.driver.driver_attachment.driving_record)
                if (data.driver.driver_attachment.work_permit_expire)
                    newData.append('work_permit_expire', data.driver.driver_attachment.work_permit_expire)
                if (data.driver.driver_attachment.work_permit)
                    newData.append('work_permit', data.driver.driver_attachment.work_permit)
                if (data.driver.driver_attachment.commercial_inspection_image)
                    newData.append('commercial_inspection_image', data.driver.driver_attachment.commercial_inspection_image)
                if (data.driver.driver_attachment.maintenance_record)
                    newData.append('maintenance_record', data.driver.driver_attachment.maintenance_record)
                if (data.driver.driver_attachment.licence_number)
                    newData.append('licence_number', data.driver.driver_attachment.licence_number)
                if (data.driver.driver_attachment.licence_expire)
                    newData.append('licence_expire', data.driver.driver_attachment.licence_expire)
                if (data.driver.driver_attachment.insurance)
                    newData.append('insurance', data.driver.driver_attachment.insurance)
                if (data.driver.driver_attachment.insurance_expire)
                    newData.append('insurance_expire', data.driver.driver_attachment.insurance_expire)
                window.axios.post("/admin/driver/crud/update/" + data.id, newData).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    console.log(err, 'update error')
                    reject(err)
                })
            })
        },
        deleteDriver(_, adminId) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/driver/crud/destroy/" + adminId
                ).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        showDriverDetails({commit}, driverId) {
            return new Promise((resolve, reject) => {
                return window.axios.get("/admin/driver/crud/show/" + driverId).then((res) => {
                    resolve(res)
                    commit('setLoading', false)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        payToDriver(_, data) {
            return new Promise((resolve, reject) => {
                return window.axios.post("/admin/driver/earning/pay", {
                    amount: data.payout.amount,
                    note: data.payout.note,
                    driver_id: data.driverId
                }).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        sendOnboardingLink(_, data) {
            return new Promise((resolve, reject) => {
                return window.axios.post("/admin/driver/earning/stripe", {
                    driver_id: data.driverId
                }).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}