<template>
  <roles-crud></roles-crud>
</template>

<script>
import RolesCrud from "@/components/Roles/RolesCrud";

export default {
  name: "RolesSetting",
  components: {RolesCrud}
}
</script>

<style scoped>

</style>