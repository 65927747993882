<template>
  <div>
    <!--  SNACK BAR  -->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>


    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Passengers</div>
        <!--DELETE USER DIALOG-->
        <v-dialog v-model="deleteUserDialog" v-if="selectedUser" centered min-width="25vw" width="450px">
          <v-card>
            <v-card-title class="delete-dialog-text">
              Are you sure about deleting {{ selectedUser.name }} ?
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteUser" color="#F1416C" width="100%"
                         class="white--text">
                    Delete
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteUserDialog = false" color="#50cd89" width="100%" class="white--text">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!--USER Trips INFO DIALOG-->
        <v-dialog v-model="UserTripsDialog" v-if="selectedUser" centered min-width="70vw" width="80vw">
          <v-card>
            <v-card-actions class="userTrips-close-dialog">
              <v-icon x-large color="#F1416C" @click="UserTripsDialog=false">mdi-close</v-icon>
            </v-card-actions>
            <v-card-title class="dialog-user-Trips-text">
              {{ this.selectedUser.name }}&nbsp;Trips
            </v-card-title>
            <v-card-text>
              <div>
                Phone Number:&nbsp;{{ this.selectedUser.phone_number }}<br/>Email:&nbsp;{{ this.selectedUser.email }}
              </div>
              <v-divider style="width: 18vw"></v-divider>
              <user-trips-filter class="mt-5" v-on:filter-applied="applyUserTripsFilter"></user-trips-filter>
              <!-- USER TRIPS TABLE IN DIALOG-->
              <v-row>
                <v-col cols="12">
                  <v-data-table
                      align="center"
                      :headers="userTripsHeaders"
                      :items="userTrips.data.data"
                      :items-per-page="10"
                      class="elevation-5"
                      hide-default-footer>
                  </v-data-table>
                </v-col>
                <v-col cols="12">
                  <v-pagination
                      color="#00b2ff"
                      v-model="userTrips.data.current_page"
                      :length="userTrips.data.last_page"
                      circle
                      @input="UserTripsIndex"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!--CREATE OR UPDATE DIALOG-->
        <v-dialog v-model="createOrUpdateUserDialog" min-width="20vw" width="500px" overlay-color="black">
          <v-card>
            <v-card-title v-if="selectedUser">
              <span>Edit {{ selectedUser.name }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field color="#00b2ff" label="name" v-model="newUser.name"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field color="#00b2ff" label="email" v-model="newUser.email"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field color="#00b2ff" label="phone number" v-model="newUser.phone_number"></v-text-field>
                </v-col>
                <v-col cols="6" class="text-center">

                  <v-btn rounded @click="updateUser()" color="#ffa600" width="100%"
                         class="white--text">
                    Update
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="createOrUpdateUserDialog = false"
                         color="#F1416C" width="100%" class="white--text">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!--USERS FILTER-->
        <users-filter v-on:filter-applied="applyFilter"></users-filter>
        <!--USERS TABLE-->
        <v-data-table
            align="center"
            :headers="headers"
            :items="users.data"
            :items-per-page="10"
            :custom-sort="(items) => items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @update:sort-by="applySort"
            @update:sort-desc="applySort"
            class="elevation-5"
            hide-default-footer>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              Passenger Info
              <template v-slot:activator="{ on }">
                <v-btn v-can="'show_user'" v-on="on" class="mx-2" fab color="#00b2ff" x-small elevation="0"
                       @click="$router.push('/passenger-details/' + item.id)">
                  <v-icon color="white">mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-btn v-can="'update_user'" class="mx-2" fab color="#ffa600" x-small elevation="0" @click="editUser(item)">
              <v-icon color="white">mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="mx-2" fab color="#F1416C" x-small elevation="0" @click="deleteUserPermission(item)"
                   v-can="'destroy_user'">
              <v-icon color="white">mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-tooltip bottom>
              Passenger Trips
              <template v-slot:activator="{ on }">
                <v-btn v-can="'user_trips'" v-on="on" class="mx-2" fab color="#00b2ff" x-small elevation="0"
                       @click="showUserTrips(item)">
                  <v-icon color="white">mdi-road-variant</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip bottom>
              Passenger Transactions
              <template v-slot:activator="{ on }">
                <v-btn v-can="'user_transactions'" v-on="on" class="mx-2" fab color="#50cd89" x-small elevation="0"
                       @click="$router.push('/passenger-transactions/' + item.id)">
                  <v-icon color="white">mdi-account-cash</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <v-col col="3"></v-col>
      <v-col cols="6">
        <v-pagination
            color="#00b2ff"
            v-model="users.current_page"
            :length="users.last_page"
            circle
            @input="getUsers"
        ></v-pagination>
      </v-col>
      <v-col col="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import UsersFilter from "@/components/Users/UsersFilter";
import UserTripsFilter from "@/components/Users/UserTripsFilter";

export default {
  name: "UsersCrud",
  components: {
    UsersFilter,
    UserTripsFilter
  },
  data() {
    return {
      sortBy: null,
      sortDesc: false,
      sortParams: null,
      headers: [
        {text: '#', value: 'id', align: 'center'},
        {text: 'Name', value: 'name', align: 'center'},
        {text: 'Email', value: 'email', align: 'center'},
        {text: 'Phone Number', value: 'phone_number', align: 'center'},
        {text: 'Actions', value: 'actions', align: 'center', sortable: false},
      ],
      userTripsHeaders: [
        {text: 'Origin Address', value: 'origin_address', align: 'center'},
        {text: 'Destination Address', value: 'destination_address', align: 'center'},
        {text: 'Status', value: 'states', align: 'center'},
        {text: 'Start Time', value: 'start_time', align: 'center'},
        {text: 'End Time', value: 'end_time', align: 'center'},
        {text: 'Driver Phone Number', value: 'driver.phone_number', align: 'center'},
        {text: 'Vehicle Type', value: 'vehicle_type.title', align: 'center'},
      ],
      id: [],
      userTrips: {
        data: [],
      },
      UserTripsDialog: false,
      users: {
        data: [],
      },
      createOrUpdateUserDialog: false,
      deleteUserDialog: false,
      selectedUser: null,
      newUser: {
        name: null,
        email: null,
        phone_number: null,
      },
      filterParams: null,
    }
  },
  mounted() {
    if (this.UserTripsDialog === 'true') {
      this.UserTripsIndex()
    }
    this.getUsers()
  },
  methods: {
    applySort() {
      if (this.sortBy && this.sortDesc !== undefined) {
        let sortDirection = this.sortDesc ? 'desc' : 'asc';
        this.sortParams = `&sort[field]=${this.sortBy}&sort[direction]=${sortDirection}`
      } else {
        this.sortParams = null;
      }
      this.getUsers()
    },
    makeQueryParameters() {
      if (this.filterParams != null && this.sortParams != null) {
        return this.filterParams + this.sortParams
      } else if (this.filterParams != null) {
        return this.filterParams;
      } else if (this.sortParams != null) {
        return this.sortParams
      }
      return null
    },
    async getUsers() {
      await this.$store.dispatch("getPaginatedUsers", {
        page: this.users.current_page,
        filter: this.makeQueryParameters()
      }).then((res) => {
        this.users = res
      }).catch((err) => {
        console.log(err)
      })
    },
    editUser(user) {
      this.selectedUser = user
      this.newUser.name = user.name
      this.newUser.email = user.email
      this.newUser.phone_number = user.phone_number
      this.createOrUpdateUserDialog = true
    },
    updateUser() {
      this.$store.dispatch('updateUser', {newUser: this.newUser, id: this.selectedUser.id}).then(() => {
        this.getUsers()
        this.createOrUpdateUserDialog = false
        this.openSnackbar(this.newUser.name + ' Updated Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
    deleteUserPermission(user) {
      this.selectedUser = user
      this.deleteUserDialog = true
    },
    deleteUser() {
      this.$store.dispatch('deleteUser', this.selectedUser.id).then(() => {
        this.getUsers()
        this.deleteUserDialog = false
        this.openSnackbar(this.selectedUser.name + ' Deleted Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
    showUserTrips(item) {
      this.selectedUser = item
      this.UserTripsDialog = true
      this.userTrips.data.current_page = 1
      this.UserTripsIndex()
    },
    async UserTripsIndex() {
      await this.$store.dispatch('UserTripsIndex', {
        userId: this.selectedUser.id,
        page: this.userTrips.data.current_page,
        filter: this.filterParams
      }).then((res) => {
        this.userTrips = res
      }).catch((err) => {
        console.log(err.response.data)
      })
    },
    applyFilter(params) {
      this.users.current_page = 1
      this.filterParams = this.objectParametersToString(params)
      this.getUsers()
    },
    applyUserTripsFilter(params) {
      this.users.current_page = 1
      this.filterParams = this.objectParametersToString(params)
      this.UserTripsIndex()
    },
  }
}
</script>

<style scoped>
th {
  text-align: center !important;
}

td {
  text-align: center !important;
}

.userTrips-close-dialog {
  direction: rtl;
}
</style>