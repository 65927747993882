<template>
  <div>
    <!--  SNACK BAR  -->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>


    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Vehicle Types</div>
        <!--DELETE VEHICLE TYPE DIALOG-->
        <v-dialog v-model="deleteVehicleTypeDialog" v-if="selectedVehicleType" centered min-width="20vw" width="400px">
          <v-card>
            <v-card-title class="delete-dialog-text">
              Are you sure about deleting {{ selectedVehicleType.title }} ?
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteVehicleType" color="#F1416C" width="100%"
                         class="white--text">
                    Delete
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteVehicleTypeDialog = false" color="#50cd89" width="100%"
                         class="white--text">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!--CREATE OR UPDATE DIALOG-->
        <v-dialog v-model="createOrUpdateVehicleTypeDialog" min-width="20vw" width="500px" overlay-color="black">
          <v-card>
            <v-card-title>
              <span>{{ mode === 'create' ? 'Add New Vehicle Type' : 'Edit ' + selectedVehicleType.title }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field color="#00b2ff" label="Title" v-model="newVehicleType.title"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined color="#00b2ff" label="Description"
                              v-model="newVehicleType.description"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-text-field color="#00b2ff" label="Base Rate" type="number"
                                v-model="newVehicleType.base_rate"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field color="#00b2ff" label="Price Per Kilometer" type="number"
                                v-model="newVehicleType.price_per_kilometer"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field color="#00b2ff" label="Price Per Minute" type="number"
                                v-model="newVehicleType.price_per_minute"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-file-input color="#00b2ff" label="Vehicle Image"
                                v-model="newVehicleType.image"></v-file-input>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded v-if="mode === 'create'" @click="createVehicleType()" color="#50cd89" width="100%"
                         class="white--text">
                    Create
                  </v-btn>
                  <v-btn rounded v-if="mode === 'update'" @click="updateVehicleType()" color="#ffa600" width="100%"
                         class="white--text">
                    Update
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="createOrUpdateVehicleTypeDialog = false"
                         color="#F1416C" width="100%" class="white--text">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!--ADD NEW VEHICLE TYPE BUTTON-->
        <v-row class="mlr-1" v-can="'create_vehicle_type'">
          <v-col cols="12" class="pa-0">
            <v-btn text @click="prepareCreateVehicleType()" class="pa-0">
              <v-icon x-large color="#00b2ff">mdi-plus</v-icon>
              Add New Vehicle Type
            </v-btn>
          </v-col>
        </v-row>

        <!--VEHICLE TYPES FILTER-->
        <vehicle-types-filter :roles="roles" v-on:filter-applied="applyFilter"></vehicle-types-filter>

        <v-data-table
            align="center"
            :headers="headers"
            :custom-sort="(items) => items"
            :items="vehicleTypes.data"
            :items-per-page="10"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @update:sort-by="applySort"
            @update:sort-desc="applySort"
            class="elevation-5"
            hide-default-footer>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn class="mx-2" fab color="#ffa600" x-small elevation="0"
                   @click="editVehicleType(item)" v-can="'update_vehicle_type'">
              <v-icon color="white">mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="mx-2" fab color="#F1416C" x-small elevation="0"
                   @click="deleteVehicleTypePermission(item)" v-can="'destroy_vehicle_type'">
              <v-icon color="white">mdi-trash-can-outline</v-icon>

            </v-btn>
          </template>
          <template v-slot:[`item.images`]="{ item }">
            <img alt="vehicle image" v-if="item.image" :src="item.image" width="35px">
            <img alt="vehicle image" v-else src="../../../assets/taxi-yellow.svg" width="35px">
          </template>
        </v-data-table>
      </v-col>
      <v-col col="3"></v-col>
      <v-col cols="6">
        <v-pagination
            color="#00b2ff"
            v-model="vehicleTypes.current_page"
            :length="vehicleTypes.last_page"
            circle
            @input="getVehicleTypes"
        ></v-pagination>
      </v-col>
      <v-col col="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import VehicleTypesFilter from "@/components/Settings/VehicleTypes/VehicleTypesFilter";

export default {
  name: "VehicleTypesCrud",
  components: {VehicleTypesFilter},
  data() {
    return {
      sortBy: null,
      sortDesc: false,
      sortParams: null,
      vehicleTypes: {
        data: [],
      },
      roles: [],
      createOrUpdateVehicleTypeDialog: false,
      deleteVehicleTypeDialog: false,
      selectedVehicleType: null,
      mode: 'create',
      newVehicleType: {
        title: null,
        description: null,
        base_rate: null,
        price_per_kilometer: null,
        price_per_minute: null,
        image: null,
      },
      filterParams: null,
      headers: [
        {text: '#', value: 'id', align: 'center'},
        {text: 'Title', value: 'title', align: 'center'},
        {text: 'Vehicle Image', value: 'images', align: 'center', sortable: false},
        {text: 'Description', value: 'description', align: 'center'},
        {text: 'Base Rate', value: 'base_rate', align: 'center'},
        {text: 'Price Per Kilometer', value: 'price_per_kilometer', align: 'center'},
        {text: 'Price Per Minute', value: 'price_per_minute', align: 'center'},
        {text: 'Actions', value: 'actions', align: 'center', sortable: false},
      ],
    }
  },
  mounted() {
    this.getVehicleTypes()
  },
  methods: {
    applySort() {
      if (this.sortBy && this.sortDesc !== undefined) {
        let sortDirection = this.sortDesc ? 'desc' : 'asc';
        this.sortParams = `&sort[field]=${this.sortBy}&sort[direction]=${sortDirection}`
      } else {
        this.sortParams = null;
      }
      this.getVehicleTypes()
    },
    makeQueryParameters() {
      if (this.filterParams != null && this.sortParams != null) {
        return this.filterParams + this.sortParams
      } else if (this.filterParams != null) {
        return this.filterParams;
      } else if (this.sortParams != null) {
        return this.sortParams
      }
      return null
    },
    async getVehicleTypes() {
      await this.$store.dispatch("getPaginatedVehicleTypes", {
        page: this.vehicleTypes.current_page,
        filter: this.makeQueryParameters()
      }).then((res) => {
        this.vehicleTypes = res
      }).catch((err) => {
        console.log(err.response.data)
      })
    },
    prepareCreateVehicleType() {
      this.newVehicleType = {
        title: null,
        description: null,
        base_rate: null,
        price_per_kilometer: null,
        price_per_minute: null,
        image: null,
      }
      this.createOrUpdateVehicleTypeDialog = true
      this.mode = 'create'
    },
    createVehicleType() {
      this.$store.dispatch('createVehicleType', this.newVehicleType).then(() => {
        this.getVehicleTypes()
        this.createOrUpdateVehicleTypeDialog = false
        this.openSnackbar(this.newVehicleType.title + ' Created Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
    editVehicleType(vehicleType) {
      this.selectedVehicleType = vehicleType
      this.mode = 'update'
      this.newVehicleType.title = vehicleType.title
      this.newVehicleType.description = vehicleType.description
      this.newVehicleType.base_rate = vehicleType.base_rate
      this.newVehicleType.price_per_kilometer = vehicleType.price_per_kilometer
      this.newVehicleType.price_per_minute = vehicleType.price_per_minute
      this.newVehicleType.image = null

      this.createOrUpdateVehicleTypeDialog = true
    },
    updateVehicleType() {
      this.$store.dispatch('updateVehicleType', {
        newVehicleType: this.newVehicleType,
        id: this.selectedVehicleType.id
      }).then(() => {
        this.getVehicleTypes()
        this.createOrUpdateVehicleTypeDialog = false
        this.openSnackbar(this.newVehicleType.title + ' Updated Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
    deleteVehicleTypePermission(vehicleType) {
      this.selectedVehicleType = vehicleType
      this.deleteVehicleTypeDialog = true
    },
    deleteVehicleType() {
      this.$store.dispatch('deleteVehicleType', this.selectedVehicleType.id).then(() => {
        this.getVehicleTypes()
        this.deleteVehicleTypeDialog = false
        this.openSnackbar(this.selectedVehicleType.title + ' Deleted Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
    applyFilter(params) {
      this.vehicleTypes.current_page = 1
      this.filterParams = this.objectParametersToString(params)
      this.getVehicleTypes()
    }
  }
}
</script>

<style scoped>

</style>