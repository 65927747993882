<template>
  <div>
    <!--Cancel Emergency Dialog-->
    <v-dialog v-model="cancelEmergencyDialog" centered min-width="25vw" width="600px">
      <v-card>
        <v-card-title class="delete-dialog-text">
          Are you sure you want to end emergency status for this trip ?
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="text-center">
              <v-btn rounded @click="cancelEmergency" color="#F1416C" width="100%"
                     class="white--text">
                Yes
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn rounded @click="cancelEmergencyDialog = false" color="#50cd89" width="100%" class="white--text">
                No
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="text-center">Trips ({{ getTrips.length }})</div>
    <v-row>
      <v-col cols="12">
        <active-trips-filter
            :trips="trips"
            v-on:filter-applied="applyFilter"
        >
        </active-trips-filter>
      </v-col>

      <v-col cols="12" class="pt-0" v-for="(trip, index) in getTrips" :key="index">
        <v-card :color="trip.color === 'red' ? '#DC143C' : ''" class="card-rounded">
          <v-card-title v-if="trip.trip.emergency_by" style="padding-bottom: 0!important;">
            <div class="emergency-title">
              Emergency Request by <u>{{ trip.trip.emergency_by.toUpperCase() }}</u>
            </div>
          </v-card-title>
          <v-card-text :class="[trip.trip.emergency_by ? 'emergency-text' : '']">
            <div class="mb-1 font-size-13"><span class="font-size-15">Driver : </span> {{ trip.trip.driver_name }} |
              {{ trip.trip.driver_phone }}
            </div>
            <div class="mb-1 font-size-13"><span class="font-size-15">Passenger : </span> {{ trip.trip.user_name }} |
              {{ trip.trip.user_phone }}
            </div>
            <div class="mb-1 font-size-13"><span class="font-size-15">State : </span> {{ getStatusName(trip.trip.states) }}</div>
            <div class="mb-1 font-size-13"><span class="font-size-15">Start Time : </span> {{ trip.trip.start_time }}</div>
            <v-btn small class="mr-2 mb-1 mt-1" rounded color="#00b2ff" @click="handleSelectTrip(trip)">
              Show On Map
            </v-btn>
            <v-btn small class="mt-1 mb-1" v-if="trip.trip.emergency_by" rounded color="#ffa600"
                   @click="cancelEmergencyDialog = true; selectedTrip = trip">
              End Emergency
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ActiveTripsFilter from "@/components/LiveTrips/ActiveTripsFilter";

export default {
  name: "ActiveTrips",
  components: {ActiveTripsFilter},
  props: ["trips", "statuses"],
  data() {
    return {
      filteredTrips: null,
      cancelEmergencyDialog: false,
      selectedTrip: null
    }
  },
  methods: {
    getStatusName(state) {
      switch (state) {
        case 'idle':
          return 'idle';
        case 'searching_ride':
          return 'Searching For Ride';
        case 'wait_for_ride':
          return 'Driver Going to Passenger';
        case 'in_trip':
          return 'In Trip';
        case 'trip_finished':
          return 'Trip Finished';
        case 'cancelled_by_user':
          return 'Cancelled By User';
        case 'cancelled_by_driver':
          return 'Cancelled By Driver';
        default:
          return state;
      }
    },
    applyFilter(data) {
      this.filteredTrips = data;
    },
    handleSelectTrip(trip) {
      this.$emit('handle-select-trip', trip)
    },
    cancelEmergency() {
      window.axios.get('/admin/trip/sos/end/' + this.selectedTrip.trip.emergency_id)
          .then(() => {
            this.selectedTrip = null
            this.cancelEmergencyDialog = false
          })
    }
  },
  computed: {
    getTrips() {
      return this.filteredTrips !== null ? this.filteredTrips : this.trips
    }
  }
}
</script>

<style scoped>
.font-size-15 {
  font-size: 15px;
}

.font-size-13 {
  font-size: 13px;
}

.v-card__text {
  color: black !important;
  font-size: 17px !important;
  line-height: 30px !important;
  padding: 10px !important;
}

.v-card__title {
  justify-content: center !important;
  padding: 10px !important;
}

.emergency-title {
  justify-content: center !important;
  border: 3px solid white;
  border-radius: 15px;
  padding: 5px;
  color: white;
  font-size: 15px;
  width: 100%;
  text-align: center;
}

.emergency-text {
  color: white !important;
}

.card-rounded {
  border-radius: 25px;
  -webkit-box-shadow: 0 0 10px gray !important;
  box-shadow: 0 0 20px gray !important;
}
</style>