<template>
  <div>
    <reset-password></reset-password>
  </div>
</template>

<script>
import ResetPassword from "@/components/Auth/ResetPassword";

export default {
  name: "ResetPasswordAdmin",
  components: {ResetPassword},
}
</script>

<style scoped>

</style>