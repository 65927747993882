<template>
  <div>
    <!--SNACK BAR-->
    <v-row>
      <v-col class="text-center">
        <v-snackbar
            multi-line
            width="500px"
            centered
            top
            v-model="snackbarStatus"
            :color="snackbarColor"
        >
          <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
          <template v-slot:action="{ attrs }">
            <v-btn
                fab
                text
                v-bind="attrs"
                @click="snackbarStatus = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
    <!--CREATE OR UPDATE DIALOG-->
    <v-dialog v-model="createOrUpdateVersionDialog" overlay-color="black">
      <v-card>
        <v-card-title>
          {{ mode === 'create ' ? ' Add New Version' : 'Edit ' }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3" v-if="mode === 'create'">
              <v-text-field color="#00b2ff" label="Title" v-model="version.title"></v-text-field>
            </v-col>
            <v-col cols="4" v-if="mode === 'create'">
              <v-text-field color="#00b2ff" label="EndPoint Key" v-model="version.endpoint_key"></v-text-field>
            </v-col>
            <v-col cols="3" v-if="mode === 'create'">
              <v-select color="#00b2ff" label="Type" v-model="version.type"
                        :items="versionTypes" item-text="label"
                        item-value="name">
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-checkbox class="mt-6" v-model="version.is_active" label="is active?">

              </v-checkbox>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn class="white--text" rounded x-large v-if="mode === 'create'" @click="create()" color="#50cd89">
                Create
              </v-btn>
              <v-btn class="white--text" rounded x-large v-if="mode === 'update'" @click="update()" color="#50cd89">
                Update
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row class="mt-16 ml-1 mr-1">
      <v-col class="text-center title-container" cols="12">Versions</v-col>
      <!--ADD NEW VERSION BUTTON-->
      <v-col cols="12" class="pa-0">
        <v-btn text @click="initialCreateVersion()" class="pa-0">
          <v-icon x-large color="#00b2ff">mdi-plus</v-icon>
          Add New Version
        </v-btn>
      </v-col>
      <v-col cols="12">
        <!--VERSIONS TABLE-->
        <v-data-table
            align="center"
            :headers="headers"
            :items="versions"
            :items-per-page="10"
            class="elevation-5"
            hide-default-footer
        >
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn class="mx-2" fab color="#ffa600" x-small elevation="0" @click="edit(item)">
              <v-icon color="white">mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "ApplicationVersions",
  data() {
    return {
      createOrUpdateVersionDialog: false,
      mode: 'create',
      selectedVersion: null,
      headers: [
        {text: 'Title', value: 'title', align: 'center'},
        {text: 'Type', value: 'type', align: 'center'},
        {text: 'Endpoint Key', value: 'endpoint_key', align: 'center'},
        {text: 'Api Key', value: 'api_key', align: 'center'},
        {text: 'Is Active?', value: 'is_active', align: 'center'},
        {text: 'Edit', value: 'edit', align: 'center'},
      ],
      versionTypes: [
        {label: 'passenger android', name: 'passenger_android'},
        {label: 'passenger ios', name: 'passenger_ios'},
        {label: 'driver android', name: 'driver_android'},
        {label: 'driver ios', name: 'driver_ios'},
      ],
      version: {
        title: null,
        endpoint_key: null,
        type: null,
        is_active: true
      },
      versions: []
    }
  },
  mounted() {
    this.getVersions()
  },
  methods: {
    getVersions() {
      window.axios.get('/admin/application-version/index').then((res) => {
        this.versions = res.data.data
      })
    },
    initialCreateVersion() {
      this.version = {
        title: null,
        endpoint_key: null,
        type: null,
        is_active: false
      }
      this.createOrUpdateVersionDialog = true
    },
    create() {
      window.axios.post('/admin/application-version/create', this.version).then(() => {
        this.createOrUpdateVersionDialog = false
        this.getVersions()
        this.openSnackbar('New Version Created Successfully', '#50cd89')
      }).catch((err) => {
        this.createOrUpdateVersionDialog = false
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
    edit(item) {
      this.version = item
      this.mode = 'update'
      this.createOrUpdateVersionDialog = true
    },
    update() {
      window.axios.post('/admin/application-version/update', this.version).then(() => {
        this.createOrUpdateVersionDialog = false
        this.getVersions()
        this.openSnackbar('Version Updated Successfully', '#50cd89')
        this.mode="create"
      }).catch((err) => {
        this.mode="create"
        this.createOrUpdateVersionDialog = false
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    }
  }
}
</script>

<style scoped>
</style>