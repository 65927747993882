<template>
  <v-row class="mb-10 mt-10 rounded-15 mlr-1">
    <v-col cols="12" xl="3" lg="3" md="3">
      <v-text-field label="Search"
                    v-model="needle">
      </v-text-field>
    </v-col>
    <v-col cols="12" xl="3" lg="3" md="3">
      <v-select v-can="'index_vehicle_type'"
                color="#00b2ff"
                label="Vehicle Type"
                v-model="vehicle_type_id"
                :items="vehicleTypes"
                clearable
                item-text="title" item-value="id">
      </v-select>
    </v-col>
    <v-col cols="12">
      <v-btn rounded @click="search" color="#00b2ff"
             class="white--text">
        Search
      </v-btn>
      <v-btn rounded @click="reset" color="#ffa600"
             class="white--text ml-3">
        Reset
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "DriversFilter",
  props: ['vehicleTypes'],
  data() {
    return {
      needle: null,
      vehicle_type_id: null,
    }
  },
  methods: {
    search() {
      this.$emit('filter-applied', {
        needle: this.needle,
        vehicle_type_id: this.vehicle_type_id,
      })
    },
    reset() {
      this.needle = null
      this.vehicle_type_id = null
      this.search()
    }
  }
}
</script>
