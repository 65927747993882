<template>
  <v-row class="mb-10 mt-10 rounded-15 mlr-1">
    <v-col cols="12" xl="2" lg="2" md="2">
      <v-text-field label="Search Service"
                    v-model="service"
                    item-text="name"
                    item-value="id">
      </v-text-field>
    </v-col>

    <v-col cols="12" xl="2" lg="2" md="2">
      <v-flex>
        <v-menu
            v-model="date_picker_start_time_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="start_time"
                label="Start Time"
                readonly
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="start_time"
                         @input="date_picker_start_time_menu = false"></v-date-picker>
        </v-menu>
      </v-flex>
    </v-col>
    <v-col cols="12" xl="2" lg="2" md="2">
      <v-flex>
        <v-menu
            v-model="date_picker_end_time_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="end_time"
                label="End Time"
                readonly
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="end_time"
                         @input="date_picker_end_time_menu = false"></v-date-picker>
        </v-menu>
      </v-flex>
    </v-col>
    <v-col cols="12">
      <v-btn rounded @click="search" color="#00b2ff"
             class="white--text">
        Search
      </v-btn>
      <v-btn rounded @click="reset" color="#ffa600"
             class="white--text ml-3">
        Reset
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ServicesFilter",
  data() {
    return {
      date_picker_start_time_menu: false,
      date_picker_end_time_menu: false,
      start_time: null,
      end_time: null,
      service: null,
    }
  },
  methods: {
    search() {
      this.$emit('filter-applied', {
        service: this.service,
        start_time: this.start_time,
        end_time: this.end_time
      })
    },
    reset() {
      this.service = []
      this.start_time = null
      this.end_time = null
      this.search()
    }
  }
}
</script>

<style scoped>

</style>