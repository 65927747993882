<template>
  <div v-if="lostItem" class="mt-16">
    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Lost Item Details</div>
        <v-row class="mlr-1">
          <v-col cols="12" class="pa-0">
            <v-btn text @click="$router.go(-1)" class="pa-0">
              <v-icon x-large color="#00b2ff">mdi-arrow-left</v-icon>
              &nbsp; Back
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mx-5">
          <v-col cols="12">
            <v-row>

              <!-- basic information -->
              <v-col cols="12">
                <div class="mt-5 mb-10">
                  <h2 class="section-title">Basic Information</h2>
                  <v-divider></v-divider>
                </div>
                <v-row>
                  <v-col cols="12" xl="4" lg="4" md="6" sm="12">
                    <span class="title-font-size">Created At:</span> &nbsp;{{ lostItem.created_at}}
                  </v-col>
                  <v-col cols="12" xl="4" lg="4" md="6" sm="12">
                    <span class="title-font-size">Image:</span> &nbsp;
                    <img :src="lostItem.image_url" width="100%">
                  </v-col>
                </v-row>
              </v-col>

              <!-- description -->
              <v-col cols="12">
                <div class="mt-5 mb-10">
                  <h2 class="section-title">Description</h2>
                  <v-divider></v-divider>
                </div>
                <v-row>
                  <v-col cols="12">
                    {{ lostItem.description }}
                  </v-col>
                </v-row>
              </v-col>

              <!-- driver info -->
              <v-col cols="12">
                <div class="mt-5 mb-10">
                  <h2 class="section-title">Driver Information</h2>
                  <v-divider></v-divider>
                </div>
                <v-row>
                  <v-col v-if="lostItem.driver.first_name" cols="12" xl="3" lg="3" md="12" sm="12">
                    <span class="title-font-size">First Name:</span> &nbsp;{{ lostItem.driver.first_name }}
                  </v-col>
                  <v-col v-if="lostItem.driver.last_name " cols="12" xl="3" lg="3" md="12" sm="12">
                    <span class="title-font-size">Last Name:</span> &nbsp; {{ lostItem.driver.last_name }}
                  </v-col>
                  <v-col v-if="lostItem.driver.nick_name" cols="12" xl="3" lg="3" md="12" sm="12">
                    <span class="title-font-size">Nick Name:</span> &nbsp;{{ lostItem.driver.nick_name }}
                  </v-col>
                  <v-col cols="12" xl="3" lg="3" md="12" sm="12">
                    <span class="title-font-size">Email:</span> &nbsp; {{ lostItem.driver.email }}
                  </v-col>
                  <!-- basic lostItem.driver info-->
                  <v-col cols="12" xl="3" lg="3" md="12" sm="12">
                    <span class="title-font-size">Phone Number:</span> &nbsp;{{ lostItem.driver.phone_number }}
                  </v-col>
                  <v-col v-if="lostItem.driver.address" cols="12" xl="3" lg="3" md="12" sm="12">
                    <span class="title-font-size">Address:</span> &nbsp;{{ lostItem.driver.address }}
                  </v-col>
                  <v-col v-if="lostItem.driver.alternate_phone_number" cols="12" xl="3" lg="3" md="12" sm="12">
                    <span class="title-font-size">Alternate Phone Number:</span> &nbsp;{{ lostItem.driver.alternate_phone_number }}
                  </v-col>
                  <v-col v-if="lostItem.driver.emergency_contact_phone_number" cols="12" xl="3" lg="3" md="12" sm="12">
                    <span class="title-font-size">Emergency Contact Phone Number:</span>
                    &nbsp;{{ lostItem.driver.emergency_contact_phone_number }}
                  </v-col>
                  <v-col v-if="lostItem.driver.emergency_contact_name" cols="12" xl="3" lg="3" md="12" sm="12">
                    <span class="title-font-size">Emergency Contact Name:</span> &nbsp; {{ lostItem.driver.emergency_contact_name }}
                  </v-col>
                  <v-col cols="12" xl="3" lg="3" md="12" sm="12">
                    <v-btn color="success" :to="'/driver-details/' + lostItem.driver.id">Click Here For More Information About Driver</v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <!-- trip info -->
              <v-col cols="12">
                <div class="mt-5 mb-10">
                  <h2 class="section-title">Trip Information</h2>
                  <v-divider></v-divider>
                </div>
                <v-row>
                  <v-col cols="12" xl="4" lg="4" md="6" sm="12">
                    <span class="title-font-size">Origin Address:</span> &nbsp;{{ lostItem.trip.origin_address }}
                  </v-col>
                  <v-col cols="12" xl="4" lg="4" md="6" sm="12">
                    <span class="title-font-size">Destination Address:</span> &nbsp;{{ lostItem.trip.destination_address }}
                  </v-col>
                  <v-col v-if="lostItem.trip.price" cols="12" xl="4" lg="4" md="6" sm="12">
                    <span class="title-font-size"> Price:</span> &nbsp;{{ lostItem.trip.price }}
                  </v-col>
                  <v-col v-if="lostItem.trip.tip" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Tip:</span> &nbsp;{{ lostItem.trip.tip }}
                  </v-col>
                  <v-col v-if="lostItem.trip.eta" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">ETA:</span> &nbsp;{{ lostItem.trip.eta }}
                  </v-col>
                  <v-col v-if="lostItem.trip.start_time" cols="12" xl="4" lg="4" md="6" sm="12">
                    <span class="title-font-size">Start Time:</span> &nbsp;{{ lostItem.trip.start_time }}
                  </v-col>
                  <v-col v-if="lostItem.trip.end_time" cols="12" xl="4" lg="4" md="6" sm="12">
                    <span class="title-font-size"> End Time:</span> &nbsp;{{ lostItem.trip.end_time }}
                  </v-col>
                  <v-col v-if="lostItem.trip.distance" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Distance:</span> &nbsp;{{ lostItem.trip.distance / 1000 }} &nbsp; Km
                  </v-col>
                  <v-col v-if="lostItem.trip.surge_multiplier" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Surge Multiplier:</span> &nbsp;{{ lostItem.trip.surge_multiplier }}
                  </v-col>
                  <v-col v-if="lostItem.trip.rate_from_passenger" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Rate From Passenger:</span> &nbsp;{{ lostItem.trip.rate_from_passenger }}
                  </v-col>
                  <v-col v-if="lostItem.trip.rate_from_driver" cols="12" xl="3" lg="3" md="6" sm="12">
                    <span class="title-font-size">Rate From Driver:</span> &nbsp;{{ lostItem.trip.rate_from_driver }}
                  </v-col>
                  <v-col cols="12" xl="3" lg="3" md="12" sm="12">
                    <v-btn color="success" :to="'/trip-details/' + lostItem.trip.id">Click Here For More Information About Trip</v-btn>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ShowLostItemDetails",
  data() {
    return {
      lostItem: null,
    }
  },
  mounted() {
    this.showLostItemDetails()
  },
  methods: {
    showLostItemDetails() {
      this.$store.dispatch('showLostItemDetails', this.$route.params.id).then((res) => {
        this.lostItem = res.data.data
        console.log(this.lostItem)
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  },
}
</script>

<style scoped>

</style>