export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getAllowedArea() {
            return new Promise((resolve, reject) => {
                window.axios.get("/setting/allowed-area/index")
                    .then((res) => {
                        resolve(res)
                    }).catch((err) => {
                    reject(err)
                })
            })
        },
        updateAllowedArea(_, data) {
            return new Promise((resolve, reject) => {
                window.axios.post("/setting/allowed-area/update", data).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    },
}
