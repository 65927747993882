<template>
  <div>
    <!--SNACK BAR  -->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" class="snackbar-p"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Notifications</div>
        <!--    CREATE NOTIFICATION DIALOG-->
        <v-dialog v-model="createNotificationDialog" min-width="30vw" width="600px" overlay-color="black">
          <v-card>
            <v-card-title>
              create new notification
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="9">
                  <v-text-field v-model="notification.title" label="title" color="#00b2ff"></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-checkbox class="mt-6" v-model="hasUrl" label="link"></v-checkbox>
                </v-col>
                <v-col cols="12" v-if="hasUrl">
                  <v-textarea outlined rows="2" v-model="notification.url" label="url" color="#00b2ff"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-select
                      color="#00b2ff"
                      label="choose topic"
                      v-model="notification.group"
                      :items="[{name:'drivers',value:'drivers'},{name:'passengers',value:'passengers'}]"
                      item-text="name"
                      item-value="value"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      outlined
                      rows="4"
                      v-model="notification.message"
                      color="#00b2ff"
                      label="message"
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn class="mx-2 white--text" @click="createNotificationDialog = false" color="#F1416C" width="100%"
                         rounded>
                    Cancel
                  </v-btn>
                </v-col>
                <v-col v-can="'send_notification'" cols="6" class="text-center">
                  <v-btn :disabled="sendButtonDisableStatus" class="white--text" @click="createNotification()" color="#50cd89" width="100%" rounded>Create
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!--    ADD NOTIFICATION BUTTON-->
        <v-row class="mx-1">
          <v-col cols="12" class="pa-0">
            <v-btn v-can="'send_notification'" text @click="prepareCreateNotification()" class="pa-0">
              <v-icon x-large color="#00b2ff">mdi-plus</v-icon>
              Add New Notification
            </v-btn>
          </v-col>
        </v-row>
        <!--    DATA TABLE NOTIFICATION-->
        <notification-filter v-on:filter-applied="applyFilter"></notification-filter>
        <v-data-table
            :items="notifications.data"
            :custom-sort="(items) => items"
            :headers="headers"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @update:sort-by="applySort"
            @update:sort-desc="applySort"
            :items-per-page="10"
            class="elevation-2"
            hide-default-footer
        >
        </v-data-table>
        <v-row>
          <v-col col="3"></v-col>
          <v-col cols="6">
            <v-pagination
                color="#00b2ff"
                v-model="notifications.current_page"
                :length="notifications.last_page"
                circle
                @input="getNotifications"
            ></v-pagination>
          </v-col>
          <v-col col="3"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {getAdminPermissions} from "@/middleware/hasPermission";
import NotificationFilter from "@/components/Notification/NotificationFilter";

export default {
  name: "NotificationCrud",
  components: {NotificationFilter},
  data() {
    return {
      sendButtonDisableStatus: false,
      sortBy: null,
      sortDesc: false,
      sortParams: null,
      hasUrl: false,
      notification: {
        title: null,
        message: null,
        group: null,
        url: null
      },
      notifications: {
        data: [],
      },
      createNotificationDialog: false,
      headers: [
        {text: '#', value: 'id', align: 'center'},
        {text: 'Title', value: 'title', align: 'center'},
        {text: 'Message', value: 'message', align: 'center'},
        {text: 'Group', value: 'group', align: 'center'},
        {text: 'URL', value: 'url', align: 'center'},
        {text: 'Type', value: 'type', align: 'center'},
        {text: 'Event Type', value: 'event_type', align: 'center'},
      ]
    }
  },
  mounted() {
    let adminPermissions = getAdminPermissions()
    if (adminPermissions.includes('index_notification')) {
      this.getNotifications()
    }
  },
  methods: {
    applySort() {
      if (this.sortBy && this.sortDesc !== undefined) {
        let sortDirection = this.sortDesc ? 'desc' : 'asc';
        this.sortParams = `&sort[field]=${this.sortBy}&sort[direction]=${sortDirection}`
      } else {
        this.sortParams = null;
      }
      this.getNotifications()
    },
    makeQueryParameters() {
      if (this.filterParams != null && this.sortParams != null) {
        return this.filterParams + this.sortParams
      } else if (this.filterParams != null) {
        return this.filterParams;
      } else if (this.sortParams != null) {
        return this.sortParams
      }
      return null
    },
    getNotifications() {
      this.$store.dispatch('getNotifications', {
        page: this.notifications.current_page,
        filter: this.makeQueryParameters()
      }).then((res) => {
        this.notifications = res
      })
    },
    prepareCreateNotification() {
      this.notification = {
        title: null,
        message: null,
        group: null,
      }
      this.createNotificationDialog = true
    },
    createNotification() {
      this.sendButtonDisableStatus = true
      this.$store.dispatch('createNotification', this.notification).then(() => {
        this.getNotifications()
        this.createNotificationDialog = false
        this.sendButtonDisableStatus = false
        this.openSnackbar('Notification Created Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.sendButtonDisableStatus = false
          this.createNotificationDialog = false
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
    applyFilter(params) {
      this.notifications.current_page = 1
      this.filterParams = this.objectParametersToString(params)
      this.getNotifications()
    },
  }
}
</script>

<style scoped>
.rtl {
  direction: rtl
}
</style>