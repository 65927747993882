<template>
  <div>
    <!--  SNACK BAR  -->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>


    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Admins</div>
        <!--DELETE ADMIN DIALOG-->
        <v-dialog v-model="deleteAdminDialog" v-if="selectedAdmin" centered min-width="25vw" width="450px">
          <v-card>
            <v-card-title class="delete-dialog-text">
              Are you sure about deleting {{ selectedAdmin.name }} ?
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteAdmin" color="#F1416C" width="100%"
                         class="white--text">
                    Delete
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteAdminDialog = false" color="#50cd89" width="100%" class="white--text">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!--CREATE OR UPDATE DIALOG-->
        <v-dialog v-model="createOrUpdateAdminDialog" min-width="20vw" width="500px" overlay-color="black">
          <v-card>
            <v-card-title>
              <span>{{ mode === 'create' ? 'Add New Admin' : 'Edit ' + selectedAdmin.name }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field color="#00b2ff" label="Name" v-model="newAdmin.name"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field color="#00b2ff" label="Email" v-model="newAdmin.email"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field color="#00b2ff" label="Password" v-model="newAdmin.password"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select color="#00b2ff" label="Role" v-model="newAdmin.role_id" :items="roles" item-text="name"
                            item-value="id"></v-select>
                </v-col>

                <v-col cols="6" class="text-center">
                  <v-btn rounded v-if="mode === 'create'" @click="createAdmin()" color="#50cd89" width="100%"
                         class="white--text">
                    Create
                  </v-btn>
                  <v-btn rounded v-if="mode === 'update'" @click="updateAdmin()" color="#ffa600" width="100%"
                         class="white--text">
                    Update
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="createOrUpdateAdminDialog = false"
                         color="#F1416C" width="100%" class="white--text">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!--ADD NEW ADMIN BUTTON-->
        <v-row class="mlr-1" v-can="['create_admin', 'index_role']">
          <v-col cols="12" class="pa-0">
            <v-btn text @click="prepareCreateAdmin()" class="pa-0">
              <v-icon x-large color="#00b2ff">mdi-plus</v-icon>
              Add New Admin
            </v-btn>
          </v-col>
        </v-row>

        <!--ADMINS FILTER-->
        <admins-filter :roles="roles" v-on:filter-applied="applyFilter"></admins-filter>

        <v-data-table
            align="center"
            :headers="headers"
            :items="admins.data"
            :items-per-page="10"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :custom-sort="(items) => items"
            @update:sort-by="applySort"
            @update:sort-desc="applySort"
            class="elevation-5"
            hide-default-footer>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn v-can="'show_admin'" class="mx-2" fab color="#00b2ff" x-small elevation="0"
                   @click="$router.push('/admin-details/' + item.id)">
              <v-icon color="white">mdi-eye</v-icon>
            </v-btn>
            <v-btn class="mx-2" fab color="#ffa600" x-small elevation="0" @click="editAdmin(item)"
                   v-can="['update_admin', 'index_role']">
              <v-icon color="white">mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="mx-2" fab color="#F1416C" x-small elevation="0" @click="deleteAdminPermission(item)"
                   v-can="'destroy_admin'">
              <v-icon color="white">mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col col="3"></v-col>
      <v-col cols="6">
        <v-pagination
            color="#00b2ff"
            v-model="admins.current_page"
            :length="admins.last_page"
            circle
            @input="getAdmins"
        ></v-pagination>
      </v-col>
      <v-col col="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import AdminsFilter from "@/components/Admins/AdminsFilter";
import {getAdminPermissions} from "@/middleware/hasPermission";

export default {
  name: "AdminsCrud",
  components: {AdminsFilter},
  data() {
    return {
      sortBy: null,
      sortDesc: false,
      sortParams: null,
      admins: {
        data: [],
      },
      headers: [
        {text: '#', value: 'id', align: 'center'},
        {text: 'Name', value: 'name', align: 'center'},
        {text: 'Email', value: 'email', align: 'center'},
        {text: 'Actions', value: 'actions', align: 'center',sortable: false},
      ],
      roles: [],
      createOrUpdateAdminDialog: false,
      deleteAdminDialog: false,
      selectedAdmin: null,
      mode: 'create',
      newAdmin: {
        name: null,
        email: null,
        password: null,
        role_id: null,
      },
      filterParams: null,
    }
  },
  mounted() {
    let adminPermissions = getAdminPermissions()
    if (adminPermissions.includes('index_role')) {
      this.getRoles()
    }
    this.getAdmins()
  },
  methods: {
    applySort() {
      if (this.sortBy && this.sortDesc !== undefined) {
        let sortDirection = this.sortDesc ? 'desc' : 'asc';
        this.sortParams = `&sort[field]=${this.sortBy}&sort[direction]=${sortDirection}`
      } else {
        this.sortParams = null;
      }
      this.getAdmins()
    },
    makeQueryParameters() {
      if (this.filterParams != null && this.sortParams != null) {
        return this.filterParams + this.sortParams
      } else if (this.filterParams != null) {
        return this.filterParams;
      } else if (this.sortParams != null) {
        return this.sortParams
      }
      return null
    },
    async getAdmins() {
      await this.$store.dispatch("getPaginatedAdmins", {
        page: this.admins.current_page,
        filter: this.makeQueryParameters()
      }).then((res) => {
        this.admins = res
      }).catch((err) => {
        console.log(err.response.data)
      })
    },
    async getRoles() {
      await this.$store.dispatch("getRoles").then((res) => {
        this.roles = res
      }).catch((err) => {
        console.log(err.response.data)
      })
    },
    prepareCreateAdmin() {
      this.newAdmin = {
        name: null,
        email: null,
        password: null,
        role_id: null,
      }
      this.createOrUpdateAdminDialog = true
      this.mode = 'create'
    },
    createAdmin() {
      this.$store.dispatch('createAdmin', this.newAdmin).then(() => {
        this.getAdmins()
        this.createOrUpdateAdminDialog = false
        this.openSnackbar(this.newAdmin.name + ' ' + 'Created Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
    editAdmin(admin) {
      this.selectedAdmin = admin
      this.mode = 'update'
      this.newAdmin.name = admin.name
      this.newAdmin.email = admin.email
      this.newAdmin.role_id = admin.role_id
      this.newAdmin.password = null
      this.createOrUpdateAdminDialog = true
    },
    updateAdmin() {
      this.$store.dispatch('updateAdmin', {newAdmin: this.newAdmin, id: this.selectedAdmin.id}).then(() => {
        this.getAdmins()
        this.createOrUpdateAdminDialog = false
        this.openSnackbar(this.newAdmin.name + ' Updated Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
    deleteAdminPermission(admin) {
      this.selectedAdmin = admin
      this.deleteAdminDialog = true
    },
    deleteAdmin() {
      this.$store.dispatch('deleteAdmin', this.selectedAdmin.id).then(() => {
        this.getAdmins()
        this.deleteAdminDialog = false
        this.openSnackbar(this.selectedAdmin.name + ' Deleted Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },

    applyFilter(params) {
      this.admins.current_page = 1
      this.filterParams = this.objectParametersToString(params)
      this.getAdmins()
    }
  }
}
</script>

<style scoped>

</style>