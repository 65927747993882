<template>
  <div>
    <v-row class="text-center mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container"></div>
        <img src="../../assets/home.png" width="100%" height="500px" class="home-image"
             alt="Apt Rides taxi service"/>
      </v-col>
    </v-row>
    <span class="apt-rides-home-text container">
    <h3>Online Taxi Service</h3>
    <hr class="top-line"/>
    <h1>APT RIDES</h1>
  </span>
  </div>
</template>

<script>
export default {
  name: "HomePage"
}
</script>

<style scoped>
.apt-rides-home-text {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #01141e;
}

.top-line {
  max-width: 220px;
  color: #01273a
}

.bottom-line {
  height: 1.3vh;
  max-width: 90px;
  background-color: #01273a
}

.home-image {
  max-width: 500px;
}
</style>
