"use strict";

import Vue from 'vue';
import axios from "axios";
import VueCookies from "vue-cookies";
import store from "../store"

Vue.use(VueCookies)
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let token = Vue.$cookies.get('token')
let config = {
    baseURL: process.env.baseURL || process.env.apiUrl || process.env.VUE_APP_BASE_URL,
    // timeout: 60 * 1000, // Timeout
    withCredentials: true,
    headers: {Authorization: token}
};
//console.log(config)
const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        store.commit("setLoading", true)
        return config;
    },
    function (error) {
        store.commit("setLoading", false)
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        store.commit("setLoading", false)
        // Do something with response data
        return response;
    },
    function (error) {
        store.commit("setLoading", false)
        // Do something with response error
        return Promise.reject(error);
    }
);

Plugin.install = function (Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;
