<template>
  <div>
    <users-crud></users-crud>
  </div>
</template>

<script>
import UsersCrud from '@/components/Users/UsersCrud';

export default {
  name: "UsersSetting",
  components: {
    UsersCrud
  }
}
</script>

<style scoped>

</style>