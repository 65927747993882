<template>
  <div v-if="transaction" class="mt-16">
    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Transaction Details</div>
        <v-row class="mlr-1">
          <v-col cols="12" class="pa-0">
            <v-btn text @click="$router.replace('/transactions')" class="pa-0">
              <v-icon x-large color="#00b2ff">mdi-arrow-left</v-icon>
              &nbsp; Transactions List
            </v-btn>
          </v-col>
        </v-row>
                <v-row class="mx-5">
                  <v-col cols="12">
                    <div class="mt-5 mb-10">
                      <h2 class="section-title">Payment Information</h2>
                      <v-divider></v-divider>
                    </div>
                    <v-row>
                      <v-col v-if="transaction.amount" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Amount:</span> &nbsp;{{ transaction.amount }}
                      </v-col>
                      <v-col v-if="transaction.type" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Payment Type:</span> &nbsp;
                        <span v-if="transaction.type === 'pay-card'">Card</span>
                        <span v-else-if="transaction.type === 'refund'">Refund</span>
                        <span v-else-if="transaction.type === 'tip-card'">Card (tip)</span>
                        <span v-else-if="transaction.type === 'pay-google'">Google Pay</span>
                        <span v-else-if="transaction.type === 'pay-apple'">Apple Pay</span>
                        <span v-else>{{ transaction.type }}</span>
                      </v-col>
                      <v-col v-if="transaction.payment_intent_id" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Payment Intent ID:</span>
                        &nbsp;{{ transaction.payment_intent_id }}
                      </v-col>
                      <v-col v-if="transaction.balance_transaction" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Transaction Balance:</span> &nbsp;{{ transaction.balance_transaction }}
                      </v-col>
                      <v-col v-if="transaction.card_brand" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Card Brand:</span> &nbsp;{{ transaction.card_brand }}
                      </v-col>
                      <v-col v-if="transaction.card_country" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Card Country:</span> &nbsp;{{ transaction.card_country }}
                      </v-col>
                      <v-col v-if="transaction.card_last4" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Card Last Four Numbers:</span> &nbsp;{{ transaction.card_last4 }}
                      </v-col>
                      <v-col v-if="transaction.currency" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Currency:</span> &nbsp;{{ transaction.currency }}
                      </v-col>
                      <v-col v-if="transaction.payment_method_id" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Payment Method ID:</span> &nbsp;{{ transaction.payment_method_id }}
                      </v-col>
                      <v-col v-if="transaction.charge_id" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Chatge ID:</span> &nbsp;{{ transaction.charge_id }}
                      </v-col>
                      <v-col v-if="transaction.refund_id" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Refund ID:</span> &nbsp;{{ transaction.refund_id }}
                      </v-col>
                      <v-col v-if="transaction.created_at" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Created At:</span> &nbsp;{{ transaction.created_at }}
                      </v-col>
                      <v-col v-if="transaction.updated_at" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Updated At:</span> &nbsp;{{ transaction.updated_at}}
                      </v-col>
                    </v-row>
                    <div v-if="transaction.trip" class="mt-16 mb-10">
                      <h2 class="section-title">Trip Information</h2>
                      <v-divider></v-divider>
                    </div>

                    <v-row v-if="transaction.trip">
                      <v-col v-if="transaction.trip.states" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Trip Status:</span> &nbsp;{{ transaction.trip.states }}
                      </v-col>
<!--                      <v-col v-if="transaction.price" cols="12" xl="3" lg="3" md="6" sm="12">-->
<!--                        <span class="title-font-size">Price:</span> &nbsp;{{ transaction.price }}-->
<!--                      </v-col>-->
                      <v-col v-if="transaction.trip.start_time" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Start Time:</span> &nbsp;{{ transaction.trip.start_time}}
                      </v-col>
                      <v-col v-if="transaction.trip.end_time" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">End Time:</span> &nbsp;{{ transaction.trip.end_time}}
                      </v-col>
                      <v-col v-if="transaction.trip.distance" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Distance:</span> &nbsp;{{ transaction.trip.distance/1000 }}Km
                      </v-col>

                      <v-col v-if="transaction.trip.origin_address" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Origin Address:</span> &nbsp;{{ transaction.trip.origin_address }}
                      </v-col>
                      <v-col v-if="transaction.trip.destination_address" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Destination Address:</span> &nbsp;{{ transaction.trip.destination_address }}
                      </v-col>
                      <v-col v-if="transaction.trip.surge_multiplier" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Surge Multiplier:</span> &nbsp;{{ transaction.trip.surge_multiplier }}
                      </v-col>
                      <v-col v-if="transaction.trip.eta" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">ETA:</span> &nbsp;{{ transaction.trip.eta }} sec ({{ transaction.trip.eta | momentDuration }})
                      </v-col>
                      <v-col v-if="transaction.trip.p_gst" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">GST Rate:</span> &nbsp;{{ transaction.trip.p_gst }}
                      </v-col>
                      <v-col v-if="transaction.trip.tip" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Tip:</span> &nbsp;{{ transaction.trip.tip }}
                      </v-col>
                      <v-col cols="12" xl="3" lg="3" md="6" sm="12">
                           <span class="title-font-size">Vancouver municipal fee:</span> ${{ transaction.trip.p_fee_lmm }}
                      </v-col>
                      <v-col cols="12" xl="3" lg="3" md="6" sm="12">
                           <span class="title-font-size">Provincial licensing fee:</span> ${{ transaction.trip.p_fee_ptb }}
                      </v-col>
                      <v-col cols="12" xl="3" lg="3" md="6" sm="12">
                           <span class="title-font-size">Vancouver curbside congestion management fee:</span> ${{ transaction.trip.p_tax_ccmp_pickup + transaction.trip.p_tax_ccmp_dropoff  }}
                      </v-col>
                      <v-col cols="12" xl="3" lg="3" md="6" sm="12">
                           <span class="title-font-size">Partner Percentage:</span> $ {{ transaction.trip.partner_share }}
                      </v-col>
                      <v-col v-if="transaction.trip.rate_from_passenger" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Rate From Passenger:</span> &nbsp;{{ transaction.trip.rate_from_passenger }}
                      </v-col>
                      <v-col v-if="transaction.trip.rate_from_driver" cols="12" xl="3" lg="3" md="6" sm="12">
                        <span class="title-font-size">Rate From Driver:</span> &nbsp;{{ transaction.trip.rate_from_driver }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ShowTransactionsDetails",
  data() {
    return {
      transaction: null,
    }
  },
  mounted() {
    this.ShowTransactionsDetails()
  },
  methods: {
    ShowTransactionsDetails() {
      this.$store.dispatch('ShowTransactionsDetails', this.$route.params.id).then((res) => {
        this.transaction = res.data.data
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  },
}
</script>

<style scoped>
.section-title {
  width: fit-content;
}

.title-font-size {
  font-size: 18px
}
</style>