<template>
  <v-row class="mb-10 mt-10 rounded-15 mlr-1">
    <v-col cols="12" xl="3" lg="3" md="3">
      <v-text-field label="Search"
                    v-model="needle">
      </v-text-field>
    </v-col>
    <v-col cols="12" xl="3" lg="3" md="3">
      <v-select
                color="#00b2ff"
                label="Action Types"
                v-model="action_type_ids"
                :items="action_types"
                clearable
                multiple
                item-text="label"
                item-value="name">
      </v-select>
    </v-col>
    <v-col cols="12">
      <v-btn rounded @click="search" color="#00b2ff"
             class="white--text">
        Search
      </v-btn>
      <v-btn rounded @click="reset" color="#ffa600"
             class="white--text ml-3">
        Reset
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GoalsFilter",

  props: ['action_types'],

  data() {
    return {
      needle: null,
      action_type_ids: [],
    }
  },
  methods: {
    search() {
      this.$emit('filter-applied', {
        title: this.needle,
        action_type: this.action_type_ids,
      })
    },
    reset() {
      this.needle = null
      this.action_type_ids = []
      this.search()
    }
  }
}
</script>

<style scoped>

</style>