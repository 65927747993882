export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getNotifications(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("setting/notifications/index?paginate&page=" + (params.page ?? 1) + (params.filter ?? ''))
                    .then((res) => {
                        resolve(res.data.data)
                    }).catch((err) => {
                    console.log(err.response)
                    reject(err)
                })
            })
        },
        createNotification(_, data) {
            return new Promise((resolve, reject) => {
                window.axios.post('setting/notifications/send', {
                    title: data.title,
                    message: data.message,
                    group: data.group,
                    url: data.url,
                }).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        }
    },
}
