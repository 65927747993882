<template>
  <div>
    <services-notification-index></services-notification-index>
  </div>
</template>

<script>
import ServicesNotificationIndex from "@/components/ServicesNotification/ServicesNotificationIndex"

export default {
  name: "ServicesNotification",
  components: {
    ServicesNotificationIndex
  }
}
</script>

<style scoped>

</style>