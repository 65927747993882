import {MapElementFactory} from "vue2-google-maps";

export default MapElementFactory({
    name: "DirectionRenderer",
    ctr() {
        return window.google.maps.DirectionsRenderer;
    },
    events: [],
    mappedProps: {},
    props: {
        origin: {type: [Object, Array]},
        destination: {type: [Object, Array]},
        travelMode: {type: String},
        waypoints: {type: Array},
        optimizeWaypoints: {type: Boolean},
        color: {type: String},
        preserveViewport: {type: Boolean}
    },

    afterCreate(directionsRenderer) {
        let directionsService = new window.google.maps.DirectionsService();

        this.handle(directionsService, directionsRenderer)
        this.$watch(
            () => [this.origin, this.destination, this.travelMode, this.waypoints, this.optimizeWaypoints],
            () => {
                this.handle(directionsService, directionsRenderer)
            }
        );
    },

    methods: {
        handle(directionsService, directionsRenderer) {
            let {origin, destination, travelMode, waypoints, optimizeWaypoints} = this;
            if (!origin || !destination || !travelMode || !waypoints) {
                directionsRenderer.set('directions', null);
            } else {
                directionsService.route(
                    {
                        origin,
                        destination,
                        travelMode,
                        waypoints,
                        optimizeWaypoints,
                    },
                    (response, status) => {
                        if (status !== "OK") return;
                        directionsRenderer.setDirections(response);
                        directionsRenderer.setOptions({
                            suppressMarkers: true,
                            preserveViewport: this.preserveViewport ?? true,
                            polylineOptions: {
                                strokeColor: this.color ?? 'blue'
                            }
                        });
                    }
                );
            }
        }
    }
});