<template>
  <div>
    <!--SNACK BAR  -->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" class="snackbar-p"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <!--CREATE OR UPDATE DIALOG-->
    <v-dialog fullscreen v-model="createOrUpdateDriverDialog" overlay-color="black" persistent>
      <v-card>
        <v-card-title>
          <span>{{
              mode === 'create' ? 'Add New Driver' : 'Edit ' + driver.first_name + '  ' + driver.last_name
            }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <!--saved images-->
            <v-col cols="12">
              <v-row v-if="mode === 'update'">
                <v-col cols="2">
                  <div>headshot picture</div>
                  <img width="100%" :src="selectedDriver.headshot_picture" :alt="selectedDriver.first_name">
                </v-col>
                <v-col cols="2" v-if="selectedDriver.driver_attachment.commercial_inspection_image">
                  <div>Commercial Inspection Number</div>
                  <a :href="selectedDriver.driver_attachment.commercial_inspection_image"
                     v-if="selectedDriver.driver_attachment.commercial_inspection_image.endsWith('.pdf')">Download</a>
                  <img v-else width="100%" :src="selectedDriver.driver_attachment.commercial_inspection_image"
                       :alt="selectedDriver.first_name">
                </v-col>
                <v-col cols="2" v-if="selectedDriver.driver_attachment.work_permit">
                  <div>Work permit</div>
                  <a :href="selectedDriver.driver_attachment.work_permit"
                     v-if="selectedDriver.driver_attachment.work_permit.endsWith('.pdf')">Download</a>
                  <img v-else width="100%" :src="selectedDriver.driver_attachment.work_permit"
                       :alt="selectedDriver.first_name">
                </v-col>
                <v-col cols="2" v-if="selectedDriver.driver_attachment.driving_record">
                  <div>Driving record</div>
                  <a :href="selectedDriver.driver_attachment.driving_record"
                     v-if="selectedDriver.driver_attachment.driving_record.endsWith('.pdf')">Download</a>
                  <img v-else width="100%" :src="selectedDriver.driver_attachment.driving_record"
                       :alt="selectedDriver.first_name">
                </v-col>
                <v-col cols="2" v-if="selectedDriver.driver_attachment.criminal_check">
                  <div>Criminal check</div>
                  <a :href="selectedDriver.driver_attachment.criminal_check"
                     v-if="selectedDriver.driver_attachment.criminal_check.endsWith('.pdf')">Download</a>
                  <img v-else width="100%" :src="selectedDriver.driver_attachment.criminal_check"
                       :alt="selectedDriver.first_name">
                </v-col>
                <v-col cols="2" v-if="selectedDriver.driver_attachment.maintenance_record">
                  <div>Maintenance record</div>
                  <a :href="selectedDriver.driver_attachment.maintenance_record"
                     v-if="selectedDriver.driver_attachment.maintenance_record.endsWith('.pdf')">Download</a>
                  <img v-else width="100%" :src="selectedDriver.driver_attachment.maintenance_record"
                       :alt="selectedDriver.first_name">
                </v-col>
                <v-col cols="2" v-if="selectedDriver.driver_attachment.insurance">
                  <div>insurance</div>
                  <a :href="selectedDriver.driver_attachment.insurance"
                     v-if="selectedDriver.driver_attachment.insurance.endsWith('.pdf')">Download</a>
                  <img v-else width="100%" :src="selectedDriver.driver_attachment.insurance"
                       :alt="selectedDriver.first_name">
                </v-col>
              </v-row>
            </v-col>
            <!-- basic driver info-->
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="First Name" v-model="driver.first_name"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="Last Name" v-model="driver.last_name"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="Nick Name" v-model="driver.nick_name"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="Email" v-model="driver.email"></v-text-field>
            </v-col>
            <!-- basic driver info-->
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="Phone Number" v-model="driver.phone_number"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="Alternate Phone Number"
                            v-model="driver.alternate_phone_number"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="Emergency Contact Phone Number"
                            v-model="driver.emergency_contact_phone_number"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="Emergency Contact Name"
                            v-model="driver.emergency_contact_name"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="Stripe Id"
                            v-model="driver.stripe_id"></v-text-field>
            </v-col>
            <!--car info-->
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="Vehicle Color"
                            v-model="driver.driver_attachment.color"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="Vehicle Model"
                            v-model="driver.driver_attachment.model"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="Vehicle Make"
                            v-model="driver.driver_attachment.vehicle_make"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                  v-model="driver.driver_attachment.vehicle_is_electric"
                  label="Vehicle is electric"></v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-select color="#00b2ff" label="Vehicle Type Id" v-model="driver.vehicle_type_id"
                        :items="vehicleTypes" item-text="title"
                        item-value="id">
              </v-select>
            </v-col>
            <v-col cols="8">
              Other vehicle types to offer a ride:
              <v-row no-gutters>
                <v-checkbox
                    v-for="vType in availableVehicleTypes"
                    v-model="driver.available_vehicle_types"
                    multiple
                    :key="vType.id"
                    :value="vType.id"
                    hide-details
                    class="mr-4">
                  <template v-slot:label>
                    <v-img
                        :lazy-src="vType.image"
                        max-width="50"
                        :src="vType.image"
                        class="mr-1"></v-img>
                    {{ vType.title }}
                  </template>
                </v-checkbox>
              </v-row>
            </v-col>
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="License Plate"
                            v-model="driver.driver_attachment.license_plate"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="Commercial Inspection Number"
                            v-model="driver.driver_attachment.commercial_inspection"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-flex>
                <v-menu
                    v-model="commercial_inspection_expire_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="driver.driver_attachment.commercial_inspection_expire"
                        label="Commercial Inspection Expire"
                        readonly
                        v-on="on"
                        clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="driver.driver_attachment.commercial_inspection_expire"
                                 @input="commercial_inspection_expire_menu = false" clearable>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-col>
            <v-col cols="2">
              <v-flex>
                <v-menu
                    v-model="criminal_check_expire_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="driver.driver_attachment.criminal_check_expire"
                        label="Criminal Check Expire"
                        readonly
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="driver.driver_attachment.criminal_check_expire"
                                 @input="criminal_check_expire_menu = false"></v-date-picker>
                </v-menu>
              </v-flex>
            </v-col>
            <v-col cols="2">
              <v-flex>
                <v-menu
                    v-model="abstract_expire_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="driver.driver_attachment.abstract_expire"
                        label="Abstract Expire"
                        readonly
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="driver.driver_attachment.abstract_expire"
                                 @input="abstract_expire_menu = false"></v-date-picker>
                </v-menu>
              </v-flex>
            </v-col>
            <v-col cols="2">
              <v-flex>
                <v-menu
                    v-model="work_permit_expire_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="driver.driver_attachment.work_permit_expire"
                        label="Work Permit Expire"
                        readonly
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="driver.driver_attachment.work_permit_expire"
                                 @input="work_permit_expire_menu = false"></v-date-picker>
                </v-menu>
              </v-flex>
            </v-col>
            <!--licence info-->
            <v-col cols="2">
              <v-text-field color="#00b2ff" label="licence Number"
                            v-model="driver.driver_attachment.licence_number"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-flex>
                <v-menu
                    v-model="licence_expire_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="driver.driver_attachment.licence_expire"
                        label="Licence Expire"
                        readonly
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="driver.driver_attachment.licence_expire"
                                 @input="licence_expire_menu = false"></v-date-picker>
                </v-menu>
              </v-flex>
            </v-col>
            <v-col cols="2">
              <v-flex>
                <v-menu
                    v-model="insurance_expire_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="driver.driver_attachment.insurance_expire"
                        label="Insurance Expire"
                        readonly
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="driver.driver_attachment.insurance_expire"
                                 @input="insurance_expire_menu = false"></v-date-picker>
                </v-menu>
              </v-flex>
            </v-col>
            <v-col cols="2">
              <v-file-input color="#00b2ff"
                            label="Headshot Picture" v-model="driver.headshot_picture">
              </v-file-input>
            </v-col>
            <v-col cols="2">
              <v-file-input color="#00b2ff"
                            label="Commercial Inspection Number Attachment"
                            v-model="driver.driver_attachment.commercial_inspection_image">
              </v-file-input>
            </v-col>
            <v-col cols="2">
              <v-file-input color="#00b2ff"
                            label="Work Permit Attachment" v-model="driver.driver_attachment.work_permit">
              </v-file-input>
            </v-col>
            <v-col cols="2">
              <v-file-input color="#00b2ff" label="Driving Record Attachment"
                            v-model="driver.driver_attachment.driving_record">
              </v-file-input>
            </v-col>
            <v-col cols="2">
              <v-file-input color="#00b2ff"
                            label="Criminal Check Attachment"
                            v-model="driver.driver_attachment.criminal_check">
              </v-file-input>
            </v-col>
            <v-col cols="2">
              <v-file-input color="#00b2ff"
                            label="Maintenance Record Attachment"
                            v-model="driver.driver_attachment.maintenance_record">
              </v-file-input>
            </v-col>
            <v-col cols="2">
              <v-file-input color="#00b2ff"
                            label="Insurance Attachment" v-model="driver.driver_attachment.insurance"></v-file-input>
            </v-col>
            <v-col cols="12">
              <span>Address</span>
              <GmapAutocomplete @place_changed="setPlace"></GmapAutocomplete>
              <span>If you didn't find the address you need in the dropdown, write down your address and then press enter</span>
            </v-col>
            <!-- vehicle images-->
            <v-col cols="12" class="text-center">
              <div>Add Car Pictures</div>
              <v-btn class="mx-1" @click="increase" color="success">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn class="mx-1" @click="decrease" color="warning">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-row>
                <v-col cols="3" v-for="(pic,index) in selectedDriver.driver_attachment.car_pictures" :key="index">
                  <div>{{ (parseInt(index) + parseInt(1)) }}</div>
                  <img width="100%" :src="pic" alt="car pictures">
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2" v-for="(_, index) in driver.driver_attachment.car_pictures.length" :key="index">
              <v-file-input
                  color="#00b2ff"
                  :label="'Car Picture - ' + (parseInt(index)+parseInt(1))"
                  v-model="driver.driver_attachment.car_pictures[index]">
              </v-file-input>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn large rounded v-if="mode === 'create'" @click="createDriver()" color="#50cd89" width="30%"
                     class="white--text">
                Create
              </v-btn>
              <v-btn large rounded v-if="mode === 'update'" @click="updateDriver()" color="#ffa600" width="30%"
                     class="white--text">
                Update
              </v-btn>
              <v-btn rounded @click="cancelUpdateOrCreate()"
                     color="#F1416C" width="30%" large class="white--text">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--Driver Earning-->
    <v-dialog v-model="driverEarningDialog" v-if="selectedDriverEarning && selectedDriver" centered width="80vw">
      <v-card>
        <v-card-title class="delete-dialog-text">
          Earning of {{ selectedDriver.first_name }} {{ selectedDriver.last_name }} :
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3" class="text-center">
              Daily Earning : $ {{ selectedDriverEarning.daily_earning }}
            </v-col>
            <v-col cols="3" class="text-center">
              Weekly Earning : $ {{ selectedDriverEarning.weekly_earning }}
            </v-col>
            <v-col cols="3" class="text-center">
              Monthly Earning : $ {{ selectedDriverEarning.monthly_earning }}
            </v-col>
            <v-col cols="3" class="text-center">
              Yearly Earning : $ {{ selectedDriverEarning.yearly_earning }}
            </v-col>
            <v-col cols="3" class="text-center">
              Payout Available : $ {{ selectedDriverEarning.payout_available }}
            </v-col>
            <v-col cols="12" class="text-center">
              Last Week Earning :
              <v-row>
                <v-col v-for="(earning,index) in selectedDriverEarning.last_week_earning" :key="index">
                  {{ earning.date }} : $ {{ earning.earning }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-center" v-if="selectedDriverEarning.payout_history.length">
              Payout History :
              <v-row>
                <v-col v-for="(earningHistory,index) in selectedDriverEarning.payout_history" :key="index">
                  {{ earningHistory }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--CHECK SAFETY DIALOG-->
    <v-dialog v-model="checkSafetyDialog" v-if="selectedDriverForDialog" centered min-width="25vw" width="500px">
      <v-card>
        <v-card-title class="delete-dialog-text">
          Rejected Safety Check of {{ selectedDriverForDialog.first_name }} {{ selectedDriverForDialog.last_name }} :
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" v-for="(safetyReject, index) in selectedDriverForDialog.safety_rejected" :key="index">
              Driver Rejected Safety Check on {{ safetyReject.created_at }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="text-center">
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn rounded @click="checkSafetyDialog = false" color="#50cd89" width="100%" class="white--text">
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--DRIVER ACTION DIALOG-->
    <v-dialog v-model="driverActionDialog" v-if="selectedDriverForDialog" centered min-width="25vw" width="500px">
      <v-card>
        <v-card-title class="delete-dialog-text">
          {{ selectedDriverForDialog.first_name }} {{ selectedDriverForDialog.last_name }} Actions :
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-data-table
                align="center"
                :headers="[{text: 'Action', value: 'label', align: 'center'},{text: 'Date', value: 'created_at', align: 'center'}]"
                :items="selectedDriverForDialog.driver_actions"
                class="elevation-5">
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at }}
              </template>
            </v-data-table>
          </v-row>
          <v-row>
            <v-col cols="6" class="text-center">
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn rounded @click="driverActionDialog = false" color="#50cd89" width="100%" class="white--text">
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Drivers</div>
        <!--DELETE DRIVER DIALOG-->
        <v-dialog v-model="payToDriverDialog" v-if="selectedDriver" centered min-width="25vw" width="500px">
          <v-card>
            <v-card-title class="delete-dialog-text">
              Pay to: {{ selectedDriver.first_name }} {{ selectedDriver.last_name }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Amount" type="number" v-model="payout.amount"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea label="Note" v-model="payout.note"></v-textarea>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="payToDriver()" color="#50cd89" width="100%"
                         class="white--text">
                    Pay To Driver
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="payToDriverDialog = false" color="#F1416C" width="100%" class="white--text">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!--PAY TO DRIVER DIALOG-->
        <v-dialog v-model="deleteDriverDialog" v-if="selectedDriver" centered min-width="25vw" width="500px">
          <v-card>
            <v-card-title class="delete-dialog-text">
              Are you sure about deleting {{ selectedDriver.first_name }} {{ selectedDriver.last_name }} ?
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteDriver" color="#F1416C" width="100%"
                         class="white--text">
                    Delete
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteDriverDialog = false" color="#50cd89" width="100%" class="white--text">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!--ADD NEW DRIVER BUTTON-->
        <v-row class="mlr-1" v-can="['create_driver', 'index_vehicle_type']">
          <v-col cols="12" class="pa-0">
            <v-btn v-if="$route.path==='/drivers'" text @click="resetDriver()" class="pa-0">
              <v-icon v-can="'create_driver'" x-large color="#00b2ff">mdi-plus</v-icon>
              Add New Driver
            </v-btn>
          </v-col>
        </v-row>
        <!--DRIVERS FILTER-->
        <drivers-filter v-if="$route.path==='/drivers'" v-can="['index_driver', 'show_driver']"
                        :vehicleTypes="vehicleTypes" v-on:filter-applied="applyFilter"></drivers-filter>
        <!--DRIVERS TABLE-->
        <v-data-table
            v-can="'index_driver'"
            align="center"
            :headers="headers"
            :items="drivers.data"
            :items-per-page="10"
            class="elevation-5"
            :custom-sort="(items) => items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @update:sort-by="applySort"
            @update:sort-desc="applySort"
            hide-default-footer>
          <template
              v-slot:[`item.payout-available`]="{ item }">
            $ <span>{{ item.payout_available }}</span>
          </template>
          <template
              v-slot:[`item.full_name`]="{ item }">
            <span>{{ item.first_name }}</span> <span>{{ item.last_name }}</span>
          </template>
          <template v-slot:[`item.safety_rejected`]="{ item }">
            <v-tooltip bottom>
              Date
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" fab x-small rounded color="#ffa600" @click="showSafetyRejected(item)">
                  <v-icon color="white">mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-switch
                :disabled="!canChangeStatus()"
                v-model="item.status"
                @change="changeDriverStatus(item)"
            ></v-switch>
          </template>
          <template v-if="$route.path==='/drivers'" v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              Driver Actions
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="mx-1" fab color="#50cd89" x-small elevation="0"
                       @click="openDriverActionsDialog(item)">
                  <v-icon color="white">mdi-electron-framework</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip bottom>
              Driver Info
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" v-can="'show_driver'" class="mx-1" fab color="#00b2ff" x-small elevation="0"
                       @click="$router.push('/driver-details/' + item.id)">
                  <v-icon color="white">mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-tooltip bottom>
              Earning
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="mx-1" fab color="purple" x-small elevation="0"
                       @click="getTransaction(item)">
                  <v-icon color="white">mdi-account-cash-outline</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-btn class="mx-1" fab color="#ffa600" x-small elevation="0" @click="editDriver(item)"
                   v-can="'update_driver'">
              <v-icon color="white">mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="mx-1" fab color="#F1416C" x-small elevation="0" @click="deleteDriverPermission(item)"
                   v-can="'destroy_driver'">
              <v-icon color="white">mdi-trash-can-outline</v-icon>
            </v-btn>
            <!--            <v-tooltip v-can="'pay_to_driver'" bottom>-->
            <!--              Pay To Driver-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                <v-btn v-on="on" fab x-small rounded class="mx-1" color="#50cd89" @click="payToDriverPermission(item)"-->
            <!--                       v-can="'pay_to_driver'">-->
            <!--                  <v-icon color="white">mdi-currency-usd</v-icon>-->
            <!--                </v-btn>-->
            <!--              </template>-->
            <!--            </v-tooltip>-->
            <v-tooltip v-can="'send_on_boarding'" bottom>
              Send Stripe link
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" fab x-small rounded class="mx-1" color="#000" @click="sendOnboardingLink(item)"
                       v-can="'send_on_boarding'">
                  <v-icon color="white">mdi-email</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <ConfirmDialog ref="confirm"/>
          </template>
        </v-data-table>
      </v-col>
      <v-col col="3"></v-col>
      <v-col cols="6">
        <v-pagination
            color="#00b2ff"
            v-model="drivers.current_page"
            :length="drivers.last_page"
            circle
            @input="getDrivers"
        ></v-pagination>
      </v-col>
      <v-col col="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps';
import DriversFilter from "@/components/Drivers/DriversFilter";
import {getAdminPermissions} from "@/middleware/hasPermission";
import ConfirmDialog from "@/components/General/ConfirmDialog";

export default {
  name: "DriversCrud",
  components: {ConfirmDialog, DriversFilter},
  data() {
    return {
      payToDriverDialog: false,
      sortBy: null,
      sortDesc: false,
      sortParams: null,
      fullName: null,
      payout: {
        amount: 0,
        note: null,
      },
      headers: [
        {text: 'Name', value: 'full_name', align: 'center'},
        {text: 'Email', value: 'email', align: 'center'},
        {text: 'Phone Number', value: 'phone_number', align: 'center'},
        {text: 'Vehicle Type', value: 'vehicle_type.title', align: 'center'},
        {text: 'Payout Available', value: 'payout-available', align: 'center'},
        {text: 'Safety Rejected', value: 'safety_rejected', align: 'center', sortable: false},
        {text: 'Status', value: 'status', align: 'center'},
        {text: 'Actions', value: 'actions', align: 'center', sortable: false},
      ],
      date: new Date().toISOString().substr(0, 10),
      commercial_inspection_expire_menu: false,
      abstract_expire_menu: false,
      work_permit_expire_menu: false,
      licence_expire_menu: false,
      insurance_expire_menu: false,
      criminal_check_expire_menu: false,
      drivers: {
        data: [],
      },
      vehicleTypes: [],
      createOrUpdateDriverDialog: false,
      deleteDriverDialog: false,
      selectedDriver: {
        driver_attachment: {
          commercial_inspection_image: null,
          work_permit: null,
          driving_record: null,
          criminal_check: null,
          maintenance_record: null,
          insurance: null,
          car_pictures: null,
        },
        headshot_picture: null
      },
      mode: 'create',
      picker: null,
      driver: {
        name: null,
        email: null,
        phone_number: null,
        vehicle_type_id: null,
        available_vehicle_types: [],
        first_name: null,
        last_name: null,
        nick_name: null,
        address: null,
        emergency_contact_name: null,
        alternate_phone_number: null,
        emergency_contact_phone_number: null,
        headshot_picture: null,
        stripe_id: null,
        driver_attachment: {
          car_pictures: [],
          color: null,
          model: null,
          vehicle_make: null,
          license_plate: null,
          commercial_inspection: null,
          commercial_inspection_expire: null,
          criminal_check: null,
          abstract_expire: null,
          criminal_check_expire: null,
          driving_record: null,
          work_permit_expire: null,
          work_permit: null,
          commercial_inspection_image: null,
          maintenance_record: null,
          licence_number: null,
          licence_expire: null,
          insurance: null,
          insurance_expire: null,
          vehicle_is_electric: false,
        },
      },
      filterParams: null,
      selectedDriverEarning: null,
      driverEarningDialog: false,
      selectedDriverForDialog: null,
      checkSafetyDialog: false,
      driverActionDialog: false,
    }
  },
  mounted() {
    let adminPermissions = getAdminPermissions()
    if (adminPermissions.includes('index_vehicle_type')) {
      this.getVehicleTypes()
    }
    this.getDrivers()
  },
  methods: {

    getTransaction(driver) {
      window.axios.get('admin/driver/earning/show/' + driver.id).then((res) => {
        this.selectedDriver = driver
        this.selectedDriverEarning = res.data.data
        this.driverEarningDialog = true
      })
    },
    applySort() {
      if (this.sortBy && this.sortDesc !== undefined) {
        let sortDirection = this.sortDesc ? 'desc' : 'asc';
        this.sortParams = `&sort[field]=${this.sortBy}&sort[direction]=${sortDirection}`
      } else {
        this.sortParams = null;
      }
      this.getDrivers()
    },
    makeQueryParameters() {
      if (this.filterParams != null && this.sortParams != null) {
        return this.filterParams + this.sortParams
      } else if (this.filterParams != null) {
        return this.filterParams;
      } else if (this.sortParams != null) {
        return this.sortParams
      }
      return null
    },
    async getDrivers() {
      await this.$store.dispatch("getPaginatedDrivers", {
        page: this.drivers.current_page,
        filter: this.makeQueryParameters()
      }).then((res) => {
        this.drivers = res
      }).catch(() => {
      })
    },
    async getVehicleTypes() {
      await this.$store.dispatch("getVehicleTypes").then((res) => {
        this.vehicleTypes = res.data
      }).catch(() => {
      })
    },
    cancelUpdateOrCreate() {
      this.resetDriver()
      this.createOrUpdateDriverDialog = false
    },
    async payToDriver() {
      await this.$store.dispatch('payToDriver', {
        driverId: this.selectedDriver.id,
        payout: this.payout
      }).then(() => {
        this.getDrivers()
        this.payToDriverDialog = false
        this.openSnackbar('Paid Recorded Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
    async sendOnboardingLink(driver) {

      if (await this.$refs.confirm.open("Confirm", "Are you sure you want to send stripe onboarding link to driver's email?")) {
        console.log(driver)
        await this.$store.dispatch('sendOnboardingLink', {
          driverId: driver.id
        }).then(() => {
          this.getDrivers()
          this.openSnackbar('Stripe link sent Successfully', '#50cd89')
        }).catch((err) => {
          if (err.response.status === 422) {
            this.showValidationErrors(err.response.data.errors)
          } else {
            this.openSnackbar(err.response.data.message, '#F1416C')
          }
        });
      }
    },
    payToDriverPermission(driver) {
      this.selectedDriver = driver
      this.payToDriverDialog = true
    },
    sendOnboardingLinkPermission(driver) {
      this.selectedDriver = driver
      // this.payToDriverDialog = true
    },
    resetDriver() {
      this.driver = {
        email: null,
        phone_number: null,
        vehicle_type_id: null,
        available_vehicle_types: [],
        first_name: null,
        last_name: null,
        nick_name: null,
        address: null,
        emergency_contact_name: null,
        alternate_phone_number: null,
        emergency_contact_phone_number: null,
        headshot_picture: null,
        stripe_id: null,
        driver_attachment: {
          car_pictures: [],
          color: null,
          model: null,
          vehicle_make: null,
          license_plate: null,
          commercial_inspection: null,
          commercial_inspection_expire: null,
          criminal_check: null,
          abstract_expire: null,
          criminal_check_expire: null,
          driving_record: null,
          work_permit_expire: null,
          work_permit: null,
          commercial_inspection_image: null,
          maintenance_record: null,
          licence_number: null,
          licence_expire: null,
          insurance: null,
          insurance_expire: null,
          vehicle_is_electric: false,
        },
      }
      this.selectedDriver = {
        driver_attachment: {
          commercial_inspection_image: null,
          work_permit: null,
          driving_record: null,
          criminal_check: null,
          maintenance_record: null,
          insurance: null,
          car_pictures: null,
        },
        headshot_picture: null
      }
      this.createOrUpdateDriverDialog = true
      this.mode = 'create'
      if (document.getElementsByClassName('pac-target-input').length) {
        document.getElementsByClassName('pac-target-input')[0].value = null
      }
    },
    createDriver() {
      this.$store.dispatch('createDriver', this.driver).then(() => {
        this.getDrivers()
        this.createOrUpdateDriverDialog = false
        this.openSnackbar('New Driver Created Successfully', '#50cd89')
        this.$store.commit('setLoading', false)
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
    editDriver(driver) {

      this.selectedDriver.driver_attachment.work_permit_expire = driver.driver_attachment.work_permit_expire
      this.selectedDriver.driver_attachment.work_permit = driver.driver_attachment.work_permit
      this.selectedDriver.driver_attachment.commercial_inspection_image = driver.driver_attachment.commercial_inspection_image
      this.selectedDriver.driver_attachment.driving_record = driver.driver_attachment.driving_record
      this.selectedDriver.driver_attachment.criminal_check = driver.driver_attachment.criminal_check
      this.selectedDriver.headshot_picture = driver.headshot_picture
      this.selectedDriver.driver_attachment.maintenance_record = driver.driver_attachment.maintenance_record
      this.selectedDriver.driver_attachment.insurance = driver.driver_attachment.insurance
      this.selectedDriver.driver_attachment.car_pictures = driver.driver_attachment.car_pictures
      this.selectedDriver.driver_attachment.vehicle_is_electric = driver.driver_attachment.vehicle_is_electric
      let tmpDriver = JSON.stringify(driver)
      this.driver = JSON.parse(tmpDriver)
      this.driver.driver_attachment.work_permit = null
      this.driver.driver_attachment.commercial_inspection_image = null
      this.driver.driver_attachment.driving_record = null
      this.driver.driver_attachment.criminal_check = null
      this.driver.headshot_picture = null
      this.driver.driver_attachment.maintenance_record = null,
          this.driver.driver_attachment.insurance = null
      this.driver.driver_attachment.car_pictures = []
      this.mode = 'update'
      this.createOrUpdateDriverDialog = true
      this.$store.commit('setLoading', true)
      setTimeout(() => {
        this.$store.commit('setLoading', false)
        this.$nextTick(() => {
          document.getElementsByClassName('pac-target-input')[0].value = driver.address
        })
      }, 2500)
    },
    updateDriver() {
      this.$store.dispatch('updateDriver', {
        selectedDriver: this.selectedDriver,
        driver: this.driver,
        id: this.driver.id
      }).then((res) => {
        this.resetDriver()
        this.getDrivers()
        this.createOrUpdateDriverDialog = false
        this.openSnackbar(res.data.data.first_name + ' ' + res.data.data.last_name + ' Updated Successfully', '#50cd89')
        this.$store.commit('setLoading', false)
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
    deleteDriverPermission(driver) {
      this.selectedDriver = driver
      this.deleteDriverDialog = true
    },
    deleteDriver() {
      this.$store.dispatch('deleteDriver', this.selectedDriver.id).then(() => {
        this.getDrivers()
        this.deleteDriverDialog = false
        this.openSnackbar(this.selectedDriver.first_name + ' ' + this.selectedDriver.last_name + ' Deleted Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
    applyFilter(params) {
      this.drivers.current_page = 1
      this.filterParams = this.objectParametersToString(params)
      this.getDrivers()
    },
    increase() {
      this.$set(this.driver.driver_attachment.car_pictures, this.driver.driver_attachment.car_pictures.length, null)
    },
    decrease() {
      this.$delete(this.driver.driver_attachment.car_pictures, this.driver.driver_attachment.car_pictures.length - 1)
    },
    showSafetyRejected(driver) {
      this.selectedDriverForDialog = driver
      this.checkSafetyDialog = true;
    },
    changeDriverStatus(driver) {
      window.axios.get('admin/driver/change-status/' + driver.id + '/' + (driver.status === false ? 0 : 1))
    },
    openDriverActionsDialog(driver) {
      window.axios.get('admin/driver/get-actions/' + driver.id).then((res) => {
        this.selectedDriverForDialog = res.data.data
        this.driverActionDialog = true;
      })
    },
    canChangeStatus() {
      return getAdminPermissions().includes('change_status_driver')
    },
    setPlace(place) {
      if (place.formatted_address) {
        this.driver.address = place.formatted_address
      } else {
        this.driver.address = document.getElementsByClassName('pac-target-input')[0].value
      }
      this.openSnackbar('Address Selected!', '#50cd89')
    },
    availableVehicleTypesChecked(id) {
      return this.driver.availableVehicleTypes?.includes(id);
    }
  },
  watch: {
    driverVehicleType() {
      const index = this.driver.available_vehicle_types?.indexOf(this.driver.vehicle_type_id)
      if (index > -1)
        this.driver.available_vehicle_types.splice(index, 1)
    },
  },
  computed: {
    driverVehicleType() {
      return this.driver.vehicle_type_id;
    },
    availableVehicleTypes() {
      return this.vehicleTypes?.filter(vt => vt.id !== this.driver.vehicle_type_id)
    },
    google: gmapApi,
  },
}

</script>

<style scoped>
.snackbar-p {
  margin-bottom: 0 !important;
}

.pac-target-input {
  width: 100%;
  border-bottom: 1px solid black;
}
</style>
