<template>
  <v-row class="mb-10 mt-10 rounded-15 mlr-1">
    <v-col cols="12" xl="2" lg="2" md="2">
      <v-text-field label="Search Passengers"
                    v-model="user_ids"
                    item-text="name"
                    item-value="id">
      </v-text-field>
    </v-col>
    <v-col cols="12" xl="2" lg="2" md="2">
      <v-text-field label="Search Drivers"
                    v-model="driver_ids"
                    item-text="first_name"
                    item-value="id">
      </v-text-field>
    </v-col>
    <v-col cols="12" xl="2" lg="2" md="2">
      <v-select
          color="#00b2ff"
          label="Passenger Status"
          v-model="states"
          :items="UserStates"
          clearable
          multiple
          item-text="label"
          item-value="value"
      >
      </v-select>
    </v-col>
    <v-col cols="12" xl="2" lg="2" md="2">
      <v-flex>
        <v-menu
            v-model="date_picker_start_time_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="start_time"
                label="Start Time"
                readonly
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="start_time"
                         @input="date_picker_start_time_menu = false"><v-btn
              text color="primary" @click="ClearStartDate()">Clear
          </v-btn></v-date-picker>
        </v-menu>
      </v-flex>
    </v-col>
    <v-col cols="12" xl="2" lg="2" md="2">
      <v-flex>
        <v-menu
            v-model="date_picker_end_time_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="end_time"
                label="End Time"
                readonly
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="end_time"
                         @input="date_picker_end_time_menu = false"><v-btn
              text color="primary" @click="ClearEndDate()">Clear
          </v-btn></v-date-picker>
        </v-menu>
      </v-flex>
    </v-col>
    <v-col cols="12">
      <v-btn rounded @click="search" color="#00b2ff"
             class="white--text">
        Search
      </v-btn>
      <v-btn rounded @click="reset" color="#ffa600"
             class="white--text ml-3">
        Reset
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TripsFilter",
  props: ['drivers', 'users'],
  data() {
    return {
      date_picker_start_time_menu: false,
      date_picker_end_time_menu: false,
      states: [],
      start_time: null,
      end_time: null,
      UserStates: [{label: 'Idle "doing nothing"', value: 'idle'}, {label: 'Searching Ride', value: 'searching_ride'}, {
        label: 'Wait for ride',
        value: 'wait_for_ride '
      }, {label: 'In Trip', value: 'in_trip'}, {
        label: 'Trip finished',
        value: 'trip_finished'
      }, {label: 'Cancelled by user', value: 'cancelled_by_user'}, {
        label: 'Cancelled by driver',
        value: 'cancelled_by_driver'
      }],
      user_ids: null,
      driver_ids: null,
    }
  },

  methods: {
    search() {
      this.$emit('filter-applied', {
        state: this.states,
        needle_driver: this.driver_ids,
        needle_user: this.user_ids,
        start_time: this.start_time,
        end_time: this.end_time
      })
    },
    reset() {
      this.states = []
      this.user_ids = []
      this.driver_ids = []
      this.start_time = null
      this.end_time = null
      this.search()
    },
    ClearStartDate() {
      this.start_time = null
      this.date_picker_start_time_menu = false
    },
    ClearEndDate() {
      this.end_time = null
      this.date_picker_end_time_menu = false
    }
  }
}
</script>

<style scoped>

</style>