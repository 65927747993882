export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getUsers(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/user/crud/index" + (params ?? ''))
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        console.log(err.response, 'error get users index?')
                        reject(err)
                    })
            })
        },
        getPaginatedUsers(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/user/crud/index?paginate&page=" + (params.page ?? 1) + (params.filter ?? ''))
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateUser(_, data) {
            return new Promise((resolve, reject) => {
                window.axios.post("/admin/user/crud/update/" + data.id, {
                    name: data.newUser.name,
                    email: data.newUser.email,
                    phone_number: data.newUser.phone_number,
                }).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteUser(_, userId) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/user/crud/destroy/" + userId
                ).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        UserTripsIndex(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/trip/user/" + params.userId + "?paginate&page=" + (params.page ?? 1) + (params.filter ?? ''))
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        showUserDetails({commit}, userId) {
            return new Promise((resolve, reject) => {
                return window.axios.get("/admin/user/crud/show/" + userId).then((res) => {
                    resolve(res)
                    commit('setLoading', false)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        showUserTransactions(params, userId) {
            return new Promise((resolve, reject) => {
                return window.axios.get("/admin/transaction/user/" + userId + "?paginate=&page=" + (params.page ?? 1) + (params.filter ?? '')).then((res) => {
                    console.log(res,'store')
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    },
}
