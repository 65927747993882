var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',[_c('div',{staticClass:"text-center"},[_c('v-snackbar',{attrs:{"multi-line":"","width":"500px","centered":"","top":"","color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"fab":"","text":""},on:{"click":function($event){_vm.snackbarStatus = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}],null,false,2056817375),model:{value:(_vm.snackbarStatus),callback:function ($$v) {_vm.snackbarStatus=$$v},expression:"snackbarStatus"}},[_c('p',{staticStyle:{"margin-bottom":"0!important"},domProps:{"innerHTML":_vm._s(_vm.snackbarText)}})])],1),_c('v-dialog',{attrs:{"centered":"","width":"25vw"},model:{value:(_vm.updateSettingDialog),callback:function ($$v) {_vm.updateSettingDialog=$$v},expression:"updateSettingDialog"}},[_c('v-card',[_c('v-card-actions',[_c('v-spacer'),_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){_vm.updateSettingDialog = false}}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',[_c('v-row',{staticClass:"ma-0 pa-0 text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Do you really want update configurations? ")]),_c('v-col',{staticClass:"mt-10 text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"#ffa600"},on:{"click":function($event){return _vm.update()}}},[_vm._v("Yes , i'm sure")]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"#50cd89"},on:{"click":function($event){_vm.updateSettingDialog = false}}},[_vm._v("No , close")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-16 ml-1 mr-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center title-container"},[_vm._v("Setting")])])],1),_c('v-row',{staticClass:"mt-16 mx-4"},_vm._l((_vm.settings),function(setting){return _c('v-col',{key:setting.id,attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"disabled":setting.key === 'mail_host' ? true :
          setting.key === 'mail_port' ? true : setting.key === 'mail_encryption' ? true :
          setting.key === 'mail_username' ? true : setting.key === 'mail_password' ? true  :
          setting.key === 'mail_from_address' ? true :
          setting.key === 'mail_from_name'? true : setting.key === 'ccmp_pickup_tax' ? true :
          setting.key === 'ccmp_pickup_time_start'? true : setting.key === 'ccmp_pickup_time_end' ? true :
          setting.key === 'ccmp_dropoff_tax'? true : setting.key === 'ccmp_dropoff_time_start' ? true :
          setting.key === 'ccmp_dropoff_time_end'? true : setting.key === 'ccmp_dropoff_time_start',"label":setting.key},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", $$v)},expression:"setting.value"}})],1)}),1),_c('v-row',{staticClass:"mt-16"},[_c('v-col',{staticClass:"mt-10 text-center",attrs:{"cols":"12"}},[_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('update_setting'),expression:"'update_setting'"}],attrs:{"color":"#00b2ff","width":"40vw","rounded":"","elevation":"0"},on:{"click":function($event){_vm.updateSettingDialog = true}}},[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("Edit")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }