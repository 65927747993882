export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        // getTripsIndex() {
        //     return new Promise((resolve, reject) => {
        //         window.axios.get("/admin/trip/index")
        //             .then((res) => {
        //                 resolve(res.data.data)
        //             })
        //             .catch((err) => {
        //                 reject(err)
        //             })
        //     })
        // },
        // getDrivers() {
        //     return new Promise((resolve, reject) => {
        //         window.axios.get("/admin/driver/crud/index")
        //             .then((res) => {
        //                 resolve(res.data.data)
        //             })
        //             .catch((err) => {
        //                 reject(err)
        //             })
        //     })
        // },
        // usersArea() {
        //     return new Promise((resolve, reject) => {
        //         window.axios.get("/setting/allowed-area/index")
        //             .then((res) => {
        //                 resolve(res)
        //             }).catch((err) => {
        //             reject(err)
        //         })
        //     })
        // },
        // driversArea(_, data) {
        //     return new Promise((resolve, reject) => {
        //         window.axios.post("/setting/allowed-area/update", data).then((res) => {
        //             resolve(res)
        //         }).catch((err) => {
        //             reject(err)
        //         })
        //     })
        // },
    },
}
