export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getServicesNotification(_, params) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/log/index?paginate&page=" + (params.page ?? 1) + (params.filter ?? ''))
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteServicesNotification(_, data) {
            return new Promise((resolve, reject) => {
                window.axios.get("/admin/log/delete-all", data).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    },
}
