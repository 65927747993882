<template>
  <div v-if="!loading">
    <!--  SNACK BAR  -->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <!--    Permission Update Dialog-->
    <v-dialog centered width="25vw" v-model="updateSettingDialog">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon color="error" @click="updateSettingDialog = false">
            mdi-close
          </v-icon>
        </v-card-actions>
        <v-card-text>
          <v-row class="ma-0 pa-0 text-center">
            <v-col cols="12">
              Do you really want update configurations?
            </v-col>
            <v-col cols="12" class="mt-10 text-center">
              <v-btn color="#ffa600" @click="update()" class="mx-2">Yes , i'm sure</v-btn>
              <v-btn color="#50cd89" @click="updateSettingDialog = false" class="mx-2">No , close</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Setting</div>
      </v-col>
    </v-row>
    <v-row class="mt-16 mx-4">
      <v-col cols="3" v-for="setting in settings" :key="setting.id">
        <v-text-field
            :disabled="setting.key === 'mail_host' ? true :
            setting.key === 'mail_port' ? true : setting.key === 'mail_encryption' ? true :
            setting.key === 'mail_username' ? true : setting.key === 'mail_password' ? true  :
            setting.key === 'mail_from_address' ? true :
            setting.key === 'mail_from_name'? true : setting.key === 'ccmp_pickup_tax' ? true :
            setting.key === 'ccmp_pickup_time_start'? true : setting.key === 'ccmp_pickup_time_end' ? true :
            setting.key === 'ccmp_dropoff_tax'? true : setting.key === 'ccmp_dropoff_time_start' ? true :
            setting.key === 'ccmp_dropoff_time_end'? true : setting.key === 'ccmp_dropoff_time_start' "
            v-model="setting.value" :label="setting.key">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-16">
      <v-col cols="12" class="mt-10 text-center">
        <v-btn v-can="'update_setting'" color="#00b2ff" width="40vw" rounded elevation="0"
               @click="updateSettingDialog = true">
          <v-icon color="white" small>Edit</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SettingValue",
  data() {
    return {
      updateSettingDialog: false,
      loading: true,
      settings: {},
    }
  },
  mounted() {
    this.index()
  },
  methods: {
    index() {
      this.$store.dispatch("getSettingIndex").then((res) => {
        this.settings = res
        this.loading = false
      }).catch((err) => {
        console.log(err)
      })
    },
    update() {
      this.$store.dispatch("updateSetting", this.settings).then(() => {
        this.index()
        this.updateSettingDialog = false
        this.openSnackbar('Settings Updated Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.message, '#F1416C')
        }
      });
    },
  }
}
</script>

<style scoped>

</style>