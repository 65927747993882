<template>
  <div>
    <!--  SNACK BAR  -->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>


    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Lost Items</div>

        <!--DELETE LOST ITEM DIALOG-->
        <v-dialog v-model="deleteLostItemDialog" v-if="selectedLostItem" centered min-width="25vw" width="450px">
          <v-card>
            <v-card-title class="delete-dialog-text">
              Are you sure about deleting this record ?
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteLostItem" color="#F1416C" width="100%"
                         class="white--text">
                    Delete
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteLostItemDialog = false" color="#50cd89" width="100%" class="white--text">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <lost-item-filter v-on:filter-applied="applyFilter"></lost-item-filter>

        <!--USERS TABLE-->
        <v-data-table
            align="center"
            :headers="headers"
            :items="lostItems.data"
            :items-per-page="10"
            :custom-sort="(items) => items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @update:sort-by="applySort"
            @update:sort-desc="applySort"
            class="elevation-5"
            hide-default-footer>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn class="mx-2" fab color="#F1416C" x-small elevation="0" @click="deleteLostItemPermission(item)"
                   v-can="'destroy_lostItem'">
              <v-icon color="white">mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-btn v-can="'show_lostItem'" class="mx-2" fab color="#00b2ff" x-small elevation="0"
                   @click="$router.push('/lost-item-details/' + item.id)">
              <v-icon color="white">mdi-eye</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.driver.full_name`]="{ item }">
            <v-tooltip bottom>
              Click to see Driver Details
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :to="'/driver-details/' + item.driver_id" text>
                  {{ item.driver.first_name + ' ' + item.driver.last_name }}
                </v-btn>
              </template>
            </v-tooltip>
          </template>

          <template v-slot:[`item.trip`]="{ item }">
            <v-tooltip bottom>
              Click to see Trip Details
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="mx-2" fab color="#00b2ff" x-small elevation="0"
                       @click="$router.push('/trip-details/' + item.trip_id)">
                  <v-icon color="white">mdi-road-variant</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </template>

          <template v-slot:[`item.check`]="{ item }">
            <v-btn v-if="item.check" fab x-small color="success"><v-icon class="white--text">mdi-check</v-icon></v-btn>
            <v-btn v-else fab x-small color="error"><v-icon class="white--text">mdi-close</v-icon></v-btn>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at}}
          </template>

          <template v-slot:[`item.image_url`]="{ item }">
            <img :src="item.image_url" width="50px">
          </template>

        </v-data-table>
      </v-col>
      <v-col col="3"></v-col>
      <v-col cols="6">
        <v-pagination
            color="#00b2ff"
            v-model="lostItems.current_page"
            :length="lostItems.last_page"
            circle
            @input="getLostItems"
        ></v-pagination>
      </v-col>
      <v-col col="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import LostItemFilter from "@/components/LostItem/LostItemFilter";
export default {
  name: "LostItem",
  components: {LostItemFilter},
  data() {
    return {
      sortBy: null,
      sortDesc: false,
      sortParams: null,
      headers: [
        {text: '#', value: 'id', align: 'center'},
        {text: 'Driver', value: 'driver.full_name', align: 'center'},
        {text: 'Trip', value: 'trip', align: 'center', sortable: false},
        {text: 'Checked', value: 'check', align: 'center'},
        {text: 'Date', value: 'created_at', align: 'center'},
        {text: 'Image', value: 'image_url', align: 'center', sortable: false},
        {text: 'Actions', value: 'actions', align: 'center', sortable: false},
      ],
      LostItemTripsDialog: false,
      lostItems: {
        data: [],
      },
      deleteLostItemDialog: false,
      selectedLostItem: null,
      filterParams: null,
    }
  },
  mounted() {
    this.getLostItems()
  },
  methods: {
    applySort() {
      if (this.sortBy && this.sortDesc !== undefined) {
        let sortDirection = this.sortDesc ? 'desc' : 'asc';
        this.sortParams = `&sort[field]=${this.sortBy}&sort[direction]=${sortDirection}`
      } else {
        this.sortParams = null;
      }
      this.getLostItems()
    },
    makeQueryParameters() {
      if (this.filterParams != null && this.sortParams != null) {
        return this.filterParams + this.sortParams
      } else if (this.filterParams != null) {
        return this.filterParams;
      } else if (this.sortParams != null) {
        return this.sortParams
      }
      return null
    },
    async getLostItems() {
      await this.$store.dispatch("getPaginatedLostItems", {
        page: this.lostItems.current_page,
        filter: this.makeQueryParameters()
      }).then((res) => {
        this.lostItems = res
      }).catch((err) => {
        console.log(err)
      })
    },
    deleteLostItemPermission(lostItem) {
      this.selectedLostItem = lostItem
      this.deleteLostItemDialog = true
    },
    deleteLostItem() {
      this.$store.dispatch('deleteLostItem', this.selectedLostItem.id).then(() => {
        this.getLostItems()
        this.deleteLostItemDialog = false
        this.openSnackbar('Lost Item Deleted Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
    applyFilter(params) {
      this.lostItems.current_page = 1
      this.filterParams = this.objectParametersToString(params)
      this.getLostItems()
    },
  }
}
</script>

<style scoped>
th {
  text-align: center !important;
}

td {
  text-align: center !important;
}

.lostItemTrips-close-dialog {
  direction: rtl;
}
</style>