import { render, staticRenderFns } from "./ShowLostItemDetails.vue?vue&type=template&id=bc026068&scoped=true&"
import script from "./ShowLostItemDetails.vue?vue&type=script&lang=js&"
export * from "./ShowLostItemDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc026068",
  null
  
)

export default component.exports