<template>
  <div>
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <v-row class="mt-16 align-content-center">
      <v-col cols="12">
        <v-row class="mt-8 justify-center align-content-center">
          <v-col cols="12" xl="4" lg="4" md="6" sm="12" class="pl-10 pr-10">
            <v-toolbar-title class="title-styles font-weight-bold" style="text-align: center; font-size: x-large">
              Login
            </v-toolbar-title>
            <v-text-field
                dense
                rounded
                filled
                hide-details
                v-model="admin.email"
                class="elevation-0 mt-12"
                :rules="rules.required"
                label="Email"
            ></v-text-field>
            <div @click="$router.push('/forget-password')" class="ml-2 mb-2 mt-12 reset-password-btn">Forgot Password
              ?
            </div>
            <v-text-field
                dense
                rounded
                filled
                type="Password"
                v-model="admin.password"
                hide-details
                class="elevation-0 "
                :rules="rules.required"
                label="password"
            ></v-text-field>
            <v-btn block
                   class="mt-12 white--text"
                   color="#00b2FF"
                   x-large
                   rounded @click="login()">
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "BasicRegistration",
  data() {
    return {
      admin: {
        email: null,
        password: null,
      },
      rules: {
        required: [(value) => !!value || "this field is required"]
      }
    }
  },
  methods: {
    login() {
      this.$store.dispatch("loginAdmin", {
        email: this.admin.email,
        password: this.admin.password,
      }).then(() => {
        this.$router.go(0)
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  },
}
</script>

<style scoped>
.btn {
  text-decoration-color: white !important;
  height: 5.3vh !important;
  font-size: x-large;
}

.reset-password-btn {
  background-color: transparent !important;
  cursor: pointer;
  color: #00b2ff !important;
  font-size: 14px !important;
}
</style>