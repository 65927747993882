<template>
<div>
  <v-row class="mb-10 mt-10 rounded-15 mlr-1">
    <v-col cols="12" xl="2" lg="2" md="2">
      <v-text-field label="Search Service"
                    v-model="needle">
      </v-text-field>
    </v-col>
    <v-col cols="12" xl="2" lg="2" md="2">
      <v-select :items="types"
                item-text="name"
                item-value="value"
                multiple
                clearable
                v-model="type"
                label="Type">
      </v-select>
    </v-col>
    <v-col cols="12">
      <v-btn rounded @click="search" color="#00b2ff"
             class="white--text">
        Search
      </v-btn>
      <v-btn rounded @click="reset" color="#ffa600"
             class="white--text ml-3">
        Reset
      </v-btn>
    </v-col>
  </v-row>
</div>
</template>

<script>
export default {
  name: "NotificationFilter",
  data(){
    return{
      types:[{name: 'message', value:'message'},{name: 'link', value:'link'},{name: 'event', value:'event'}],
      type:[],
      needle:null,
    }
  },
  methods: {
    search() {
      this.$emit('filter-applied', {
        needle: this.needle,
        type: this.type,
      })
    },
    reset() {
      this.needle=null
      this.type=[]
      this.search()
    },
  },
}
</script>

<style scoped>

</style>