<template>
  <v-row class="mt-16 ml-1 mr-1">
    <v-col cols="12">
      <div class="text-center title-container">Transactions</div>
      <!-- SEARCH-->
      <transactions-filter v-if="$route.path==='/transactions'" :drivers="drivers"
                           :users="users"
                           v-on:filter-applied="applyFilter"></transactions-filter>
      <!-- TRANSACTIONS TABLE-->
      <v-data-table
          align="center"
          :headers="headers"
          :items="transactions.data"
          :items-per-page="10"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @update:sort-by="applySort"
          @update:sort-desc="applySort"
          class="elevation-5"
          :custom-sort="(items) => items"
          hide-default-footer>
        <template
            v-slot:[`item.type`]="{ item }">
          <span v-if="item.type === 'pay-card'">Card
          </span>
          <span v-else-if="item.type === 'refund'">Refund
          </span>
          <span v-else-if="item.type === 'tip-card'">Card (tip)
          </span>
          <span v-else-if="item.type === 'pay-google'">Google Pay
          </span>
          <span v-else-if="item.type === 'pay-apple'">Apple Pay
          </span>
          <span v-else>
            {{ item.type }}
          </span>
        </template>
        <template
            v-slot:[`item.PassengerOrDriver`]="{ item }">
          <span v-if="item.driver">driver</span>
          <span v-if="item.user">Passenger</span>
        </template>
        <template
            v-slot:[`item.full_name`]="{ item }">
          <span v-if="item.driver">{{ item.driver.first_name }}</span> <span
            v-if="item.driver">{{ item.driver.last_name }}</span>
          <span v-if="item.user">{{ item.user.name }}</span>
        </template>

        <template
            v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at }}
        </template>

        <template v-slot:[`item.ShowTrip`]="{ item }">
          <v-tooltip v-if="item.trip" bottom>
            Trip Details
            <template v-slot:activator="{ on }">
              <v-btn v-can="'show_trip'" v-on="on" class="mx-2" fab color="#00b2ff" x-small elevation="0"
                     @click="$router.push('/trip-details/' + item.trip_id)">
                <v-icon color="white">mdi-road-variant</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            Transaction Details
            <template v-slot:activator="{ on }">
              <v-btn v-can="'show_transaction'" v-on="on" class="mx-2" fab color="#00b2ff" x-small elevation="0"
                     @click="$router.push('/transaction-details/' + item.id)">
                <v-icon color="white">mdi-eye</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </template>

        <template v-slot:[`item.ForwardToPage`]="{ item }">
          <v-tooltip v-if="item.user_id" bottom>
            Passenger Details
            <template v-slot:activator="{ on }">
              <v-btn v-can="'show_user'" v-on="on" class="mx-2" fab color="#00b2ff" x-small
                     elevation="0"
                     @click="$router.push('/passenger-details/' + item.user_id)">
                <v-icon color="white">mdi-account-eye</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip v-if="item.driver_id" bottom>
            Driver Details
            <template v-slot:activator="{ on }">
              <v-btn v-can="'show_driver'" v-on="on" class="mx-2" fab color="#00b2ff" x-small
                     elevation="0"
                     @click="$router.push('/driver-details/' + item.driver_id)">
                <v-icon color="white">mdi-account-eye</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>
    <v-col col="3"></v-col>
    <v-col cols="6">
      <v-pagination
          color="#00b2ff"
          v-model="transactions.current_page"
          :length="transactions.last_page"
          circle
          @input="getTransactionsIndex"
      ></v-pagination>
    </v-col>
    <v-col col="3"></v-col>
  </v-row>
</template>

<script>
import TransactionsFilter from "@/components/Transactions/TransactionsFilter";
import {getAdminPermissions} from "@/middleware/hasPermission";

export default {
  name: "TransactionsIndex",
  components: {TransactionsFilter},
  data() {
    return {
      sortBy: null,
      sortDesc: false,
      sortParams: null,
      transactions: {
        data: []
      },
      headers: [
        {text: '#', value: 'id', align: 'center'},
        {text: 'Type', value: 'PassengerOrDriver', align: 'center', sortable: false},
        {text: 'Name', value: 'full_name', align: 'center'},
        {text: 'Details', value: 'ForwardToPage', align: 'center', sortable: false},
        {text: 'Customer Id', value: 'customer_id', align: 'center'},
        {text: 'Amount', value: 'amount', align: 'center'},
        {text: 'Payment Type', value: 'type', align: 'center'},
        {text: 'Card Brand', value: 'card_brand', align: 'center'},
        {text: 'Card Country', value: 'card_country', align: 'center'},
        {text: 'Card Last4', value: 'card_last4', align: 'center'},
        {text: 'Currency', value: 'currency', align: 'center'},
        {text: 'Created At', value: 'created_at', align: 'center'},
        {text: 'Trip', value: 'ShowTrip', align: 'center', sortable: false},
        {text: 'Show', value: 'actions', align: 'center', sortable: false},
      ],
      drivers: null,
      users: null,
      user_id: null,
      driver_id: null,
    }
  },
  mounted() {
    let adminPermissions = getAdminPermissions()
    if (adminPermissions.includes('index_transaction')) {
      this.getTransactionsIndex()
    }
  },
  methods: {
    applySort() {
      if (this.sortBy && this.sortDesc !== undefined) {
        let sortDirection = this.sortDesc ? 'desc' : 'asc';
        this.sortParams = `&sort[field]=${this.sortBy}&sort[direction]=${sortDirection}`
      } else {
        this.sortParams = null;
      }
      this.getTransactionsIndex()
    },
    makeQueryParameters() {
      if (this.filterParams != null && this.sortParams != null) {
        return this.filterParams + this.sortParams
      } else if (this.filterParams != null) {
        return this.filterParams;
      } else if (this.sortParams != null) {
        return this.sortParams
      }
      return null
    },
    getTransactionsIndex() {
      this.$store.dispatch('getTransactionsIndex', {
        page: this.transactions.current_page,
        filter: this.makeQueryParameters()
      }).then((res) => {
        this.transactions = res
      }).catch((err) => {
        console.log(err.response.data)
      })
    },
    applyFilter(params) {
      this.transactions.current_page = 1
      this.filterParams = this.objectParametersToString(params)
      this.getTransactionsIndex()
    },
  }
}
</script>

<style scoped>

</style>