x
<template>
  <div>
    <admins-crud></admins-crud>
  </div>
</template>

<script>
import AdminsCrud from '@/components/Admins/AdminsCrud.vue'

export default {
  name: "AdminSetting",
  components: {
    AdminsCrud
  }
}
</script>

<style scoped>

</style>