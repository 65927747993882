<template>
  <div v-if="driver" class="mt-16">
    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Driver Details</div>
        <v-row class="mlr-1">
          <v-col cols="12" class="pa-0">
            <v-btn text @click="$router.go(-1)" class="pa-0">
              <v-icon x-large color="#00b2ff">mdi-arrow-left</v-icon>
              &nbsp; Back
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mx-5">
          <v-col cols="12">

            <!-- basic driver info-->
            <div class="mt-5 mb-10">
              <h2 class="section-title">Personal Information</h2>
              <v-divider></v-divider>
            </div>
            <v-row>
              <v-col v-if="driver.first_name" cols="12" xl="3" lg="3" md="12" sm="12">
                <span class="title-font-size">First Name:</span> &nbsp;{{ driver.first_name }}
              </v-col>
              <v-col v-if="driver.last_name " cols="12" xl="3" lg="3" md="12" sm="12">
                <span class="title-font-size">Last Name:</span> &nbsp; {{ driver.last_name }}
              </v-col>
              <v-col v-if="driver.nick_name" cols="12" xl="3" lg="3" md="12" sm="12">
                <span class="title-font-size">Nick Name:</span> &nbsp;{{ driver.nick_name }}
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="12" sm="12">
                <span class="title-font-size">Email:</span> &nbsp; {{ driver.email }}
              </v-col>
              <!-- basic driver info-->
              <v-col cols="12" xl="3" lg="3" md="12" sm="12">
                <span class="title-font-size">Phone Number:</span> &nbsp;{{ driver.phone_number }}
              </v-col>
              <v-col v-if="driver.address" cols="12" xl="3" lg="3" md="12" sm="12">
                <span class="title-font-size">Address:</span> &nbsp;{{ driver.address }}
              </v-col>
              <v-col v-if="driver.alternate_phone_number" cols="12" xl="3" lg="3" md="12" sm="12">
                <span class="title-font-size">Alternate Phone Number:</span> &nbsp;{{ driver.alternate_phone_number }}
              </v-col>
              <v-col v-if="driver.emergency_contact_phone_number" cols="12" xl="3" lg="3" md="12" sm="12">
                <span class="title-font-size">Emergency Contact Phone Number:</span>
                &nbsp;{{ driver.emergency_contact_phone_number }}
              </v-col>
              <v-col v-if="driver.emergency_contact_name" cols="12" xl="3" lg="3" md="12" sm="12">
                <span class="title-font-size">Emergency Contact Name:</span> &nbsp; {{ driver.emergency_contact_name }}
              </v-col>
              <v-col cols="12">
                <span class="title-font-size">Average Rate:</span>
                <span v-if="driver.average_rate">
                        {{ driver.average_rate }}
                      </span>
                <span v-else>
                        has not any rate
                      </span>&nbsp;
              </v-col>
              <v-col cols="12">
                <span class="title-font-size">Number of rates:</span>
                <span v-if="driver.count_rate">
                        {{ driver.count_rate }}
                      </span>
                <span v-else>
                        has not any rate
                      </span>
              </v-col>
              <v-col v-if="driver.credit" cols="12" xl="3" lg="3" md="12" sm="12">
                <span class="title-font-size">Credit:</span> &nbsp; {{ driver.credit }}
              </v-col>
              <v-col v-if="driver.get_stream_token" cols="12" xl="12" lg="12" md="12" sm="12">
                <span class="title-font-size">Get Stream Token:</span> &nbsp; {{ driver.get_stream_token }}
              </v-col>
              <v-col v-if="driver.device_info" cols="12" xl="12" lg="12" md="12" sm="12">
                <span class="title-font-size">Device Info:</span> &nbsp; {{ driver.device_info }}
              </v-col>
            </v-row>
            <!--vehicle info-->
            <div v-if="driver.vehicle_type" class="mt-16 mb-10">
              <h2 class="section-title">Vehicle Information</h2>
              <v-divider></v-divider>
            </div>
            <v-row v-if="driver.driver_attachment && driver.vehicle_type">
              <v-col cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">Vehicle Color:</span> &nbsp;{{ driver.driver_attachment.color }}
              </v-col>
              <v-col v-if="driver.driver_attachment.model" cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">Vehicle Model:</span> &nbsp;{{ driver.driver_attachment.model }}
              </v-col>
              <v-col v-if="driver.vehicle_type" cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">Vehicle Type:</span> &nbsp; {{ driver.vehicle_type.title }}
              </v-col>
              <v-col v-if="driver.driver_attachment.vehicle_make" cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">Vehicle Make:</span> &nbsp; {{ driver.driver_attachment.vehicle_make }}
              </v-col>
            </v-row>
            <div class="mt-16 mb-10">
              <h2 class="section-title">Supplementary Information</h2>
              <v-divider></v-divider>
            </div>
            <v-row>
              <v-col cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">License Plate:</span> &nbsp; {{ driver.driver_attachment.license_plate }}
              </v-col>
              <v-col cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">Commercial Inspection :</span>
                &nbsp;{{ driver.driver_attachment.commercial_inspection }}
              </v-col>
              <v-col v-if="driver.driver_attachment.commercial_inspection_expire" cols="12" xl="2" lg="3" md="12"
                     sm="12">
                <span class="title-font-size">Commercial Inspection Expire:</span> &nbsp;
                {{ driver.driver_attachment.commercial_inspection_expire.substring(0, 10) }}
              </v-col>
              <v-col cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">Criminal Check Expire:</span> &nbsp;
                {{ driver.driver_attachment.criminal_check_expire.substring(0, 10) }}
              </v-col>
              <v-col cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">Abstract Expire:</span> &nbsp;
                {{ driver.driver_attachment.abstract_expire.substring(0, 10) }}
              </v-col>
              <v-col cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">Work Permit Expire:</span> &nbsp;
                {{ driver.driver_attachment.work_permit_expire.substring(0, 10) }}
              </v-col>
              <!--licence info-->
              <v-col cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">License Number:</span> &nbsp;
                {{ driver.driver_attachment.licence_number }}
              </v-col>
              <v-col cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">License Expire:</span> &nbsp;
                {{ driver.driver_attachment.licence_expire.substring(0, 10) }}
              </v-col>
              <v-col cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">Insurance Expire:</span> &nbsp;
                {{ driver.driver_attachment.insurance_expire.substring(0, 10) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="driver.created_at" cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">Created At:</span> &nbsp;{{ driver.created_at }}
              </v-col>
              <v-col v-if="driver.updated_at" cols="12" xl="2" lg="3" md="12" sm="12">
                <span class="title-font-size">Updated At:</span> &nbsp;{{ driver.updated_at }}
              </v-col>
            </v-row>
            <div class="mt-16 mb-10">
              <h2 class="section-title">Attachments</h2>
              <v-divider></v-divider>
            </div>
            <v-row>
              <v-col v-if="driver.headshot_picture" cols="12" xl="2" lg="3" md="4" sm="12">
                <v-card class="item-card">
                  <div class="item-title">Headshot Picture</div>
                  <div class="item-image-container">
                    <img class="item-image" :src="driver.headshot_picture" :alt="'headshot picture image'">
                  </div>
                </v-card>
              </v-col>
              <v-col v-if="driver.driver_attachment.commercial_inspection_image" cols="12" xl="2" lg="3" md="4" sm="12">
                <v-card class="item-card">
                  <div class="item-title">Commercial Inspection</div>
                  <div class="item-image-container">
                    <a :href="driver.driver_attachment.commercial_inspection_image"
                       v-if="driver.driver_attachment.commercial_inspection_image.endsWith('.pdf')">Download</a>
                    <img v-else class="item-image" width="100%"
                         :src="driver.driver_attachment.commercial_inspection_image"
                         :alt="'Commercial Inspection Number Attachment'"></div>
                </v-card>
              </v-col>
              <v-col v-if="driver.driver_attachment.work_permit" cols="12" xl="2" lg="3" md="4" sm="12">
                <v-card class="item-card">
                  <div class="item-title">Work Permit</div>
                  <div class="item-image-container">
                    <a :href="driver.driver_attachment.work_permit"
                       v-if="driver.driver_attachment.work_permit.endsWith('.pdf')">Download</a>
                    <img v-else class="item-image" width="100%" :src="driver.driver_attachment.work_permit"
                         :alt="'work permit image'"></div>
                </v-card>
              </v-col>
              <v-col v-if="driver.driver_attachment.driving_record" cols="12" xl="2" lg="3" md="4" sm="12">
                <v-card class="item-card">
                  <div class="item-title">Driving Record</div>
                  <div class="item-image-container">
                    <a :href="driver.driver_attachment.driving_record"
                       v-if="driver.driver_attachment.driving_record.endsWith('.pdf')">Download</a>
                    <img v-else class="item-image" width="100%" :src="driver.driver_attachment.driving_record"
                         :alt="'driving record image'"></div>
                </v-card>
              </v-col>
              <v-col v-if="driver.driver_attachment.criminal_check" cols="12" xl="2" lg="3" md="4" sm="12">
                <v-card class="item-card">
                  <div class="item-title">criminal check</div>
                  <div class="item-image-container">
                    <a :href="driver.driver_attachment.criminal_check"
                       v-if="driver.driver_attachment.criminal_check.endsWith('.pdf')">Download</a>
                    <img v-else class="item-image" width="100%" :src="driver.driver_attachment.criminal_check"
                         :alt="'criminal check image'"></div>
                </v-card>
              </v-col>
              <v-col v-if="driver.driver_attachment.maintenance_record" cols="12" xl="2" lg="3" md="4" sm="12">
                <v-card class="item-card">
                  <div class="item-title">Maintenance Record</div>
                  <div class="item-image-container">
                    <a :href="driver.driver_attachment.maintenance_record"
                       v-if="driver.driver_attachment.maintenance_record.endsWith('.pdf')">Download</a>
                    <img v-else class="item-image" width="100%" :src="driver.driver_attachment.maintenance_record"
                         :alt="'maintenance record image'"></div>
                </v-card>
              </v-col>
              <v-col v-if="driver.driver_attachment.insurance" cols="12" xl="2" lg="3" md="4" sm="12">
                <v-card class="item-card">
                  <div class="item-title">Insurance</div>
                  <div class="item-image-container">
                    <a :href="driver.driver_attachment.insurance"
                       v-if="driver.driver_attachment.insurance.endsWith('.pdf')">Download</a>
                    <img v-else class="item-image" width="100%" :src="driver.driver_attachment.insurance"
                         :alt="'insurance image'">
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <div v-if="driver.driver_attachment.car_pictures" class="mt-16 mb-10">
              <h2 class="section-title">Car Pictures</h2>
              <v-divider></v-divider>
            </div>
            <v-row v-if="driver.driver_attachment.car_pictures" class="mt-5">
              <v-col cols="12" xl="2" lg="3" md="4" sm="12" v-for="(pic,index) in driver.driver_attachment.car_pictures"
                     :key="index">
                <!--                <v-card class="item-card">-->
                <div class="item-image-container">
                  <img class="item-image" width="100%" :src="pic" alt="car picture"></div>
                <!--                </v-card>-->
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {getAdminPermissions} from "@/middleware/hasPermission";

export default {
  name: "ShowDriverDetails",
  data() {
    return {
      driver: null,
    }
  },
  mounted() {
    let adminPermissions = getAdminPermissions()
    if (adminPermissions.includes('show_driver')) {
      this.showDriverDetails()
    }
  },
  methods: {
    showDriverDetails() {
      this.$store.dispatch('showDriverDetails', this.$route.params.id).then((res) => {
        this.driver = res.data.data
        console.log(this.driver)
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  },
}
</script>

<style scoped>
.item-card {
  padding: 20px 10px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.item-title {
  width: 100%;
  text-align: center;
}

.item-image-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.item-image {
  height: fit-content;
  width: 100%;
  border-radius: 15px;
}

.section-title {
  width: fit-content;
}

.title-font-size {
  font-size: 18px
}
</style>