<template>
  <div>
    <!--SNACK BAR-->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <!--MAIN CONTENT-->
    <v-row class="mt-16">
      <v-col cols="12">
        <div class="text-center title-container">Hot Zones</div>
        <!--GUIDE DIALOG-->
        <v-dialog v-model="guideDialog" width="500px">
          <v-card rounded color="white" class="text--black">
            <v-card-title><img
                src="../../assets/earth-mark.svg" width="35px" height="35px" alt="help"/>How to work
            </v-card-title>
            <v-card-text>
              <ul>
                <li>Left Click : Add a point to selected Area</li>
                <li>Right Click : Remove a point from selected Area</li>
              </ul>
              <p class="mt-6" style="text-align: justify">
                You can add new areas or select an area from already existing areas. Area color on map is same as
                area's button color
              </p>
              <p>
                The marker of each area is marked with a number that is equal to the number of each area in the
                buttons at the bottom of the page. For example, if the number on the marker is 4 and the area name
                is
                "sample_area" , the button for this area is named : <br> #4 - sample_area
              </p>
              <p>
                The eraser icon next to the area name in the buttons at the bottom of the page removes the area
                from the list of areas
              </p>
              <p>
                for deleting a point out of a area simply right click on it. You can not delete the start point of a
                area which the red marker is on it.
              </p>
              <p>
                you can click and hold on a point of an area and drag it to another location.
              </p>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!--MAP-->
        <v-row>
          <v-col cols="12" xl="12" lg="12">
            <v-btn color="#8bdcff" @click="guideDialog=true" class="mb-2 map-guide-dialog-btn"><img
                src="../../assets/story-map-earth.svg" width="35px" height="35px" alt="help"/>&nbsp;How to work
            </v-btn>
            <GmapMap
                :clickable="true"
                :center="center"
                :zoom="12"
                map-type-id="terrain"
                style="width: 100%; height: 650px"
                :options="mapOptions"
                @click="addPointToArea"
            >
              <gmap-marker
                  v-for="(marker, index) in markers"
                  :key="index + 'mm'"
                  :label='String(parseInt(index) + 1)'
                  :position="marker"
                  :draggable="false"
                  :animation="2">
              </gmap-marker>
              <GmapPolygon v-for="(path, index) in paths"
                           :key="index"
                           :paths="path"
                           :editable="true"
                           zoomControl: true,
                           @paths_changed="pathsChanged($event, index)"
                           @rightclick="deletePath($event, index)"
                           :draggable="false"
                           :ref="'polygon' + (index) "
                           :options='{
                          strokeColor: getColor(index),
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: getColor(index) ,
                          fillOpacity: 0.35,
                          title: "title",
                          label: "label",
                       }'
              ></GmapPolygon>
            </GmapMap>
          </v-col>
        </v-row>
      </v-col>

      <!--CRUD-->
      <v-col cols="12" class="mr-3 ml-3">
        <v-row class="mb-3 ml-2">
          <v-col cols="12" style="display: flex; align-items: center">
            <h4>Areas</h4>
            <v-btn v-can="'update_hot_zone'" class="ml-6" color="#00b2ff" small fab @click="prepareAddArea">
              <v-icon x-large color="white">mdi-plus</v-icon>
            </v-btn>
            <v-btn rounded v-can="'update_hot_zone'" class="mr-2" color="#50cd89" small @click="saveAreas"
                   style="right: .5rem; position:absolute;">
              Save
            </v-btn>
          </v-col>
        </v-row>
        <v-chip class="ml-2 mb-2"
                v-for="(path, index) in paths" :key="index"
                :color="getColor(index)" @click="selectPath(index)"
                style="justify-content: center">
          {{ '#' + (parseInt(index) + 1) + ' - ' + pathsData[index].name }}
          <span class="ml-2" v-if="selectedPath == index">(Selected)</span>
          <v-btn v-can="'update_hot_zone'" fab text x-small v-if="path.length">
            <v-icon @click="prepareDeleteArea(index)">mdi-eraser</v-icon>
          </v-btn>
          <v-btn v-can="'update_hot_zone'" fab text x-small>
            <v-icon @click="prepareEditArea(index)">mdi-pencil</v-icon>
          </v-btn>
        </v-chip>
      </v-col>
    </v-row>

    <!--DELETE AREA DIALOG-->
    <v-dialog v-model="deleteAreaDialog" centered width="500px">
      <v-card>
        <v-card-title class="delete-dialog-text">
          Are you sure about deleting area {{ this.selectedPath + 1 }} ?
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="text-center">
              <v-btn rounded @click="deleteArea" color="#F1416C" width="100%"
                     class="white--text">
                Delete
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn rounded @click="deleteAreaDialog = false" color="#50cd89" width="100%" class="white--text">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--ADD AREA DIALOG-->
    <v-dialog v-model="addOrEditAreaDialog" centered width="500px">
      <v-card>
        <v-card-title class="delete-dialog-text">
          <div v-if="mode === 'create'">Add new Area</div>
          <div v-else>Edit Area {{ newArea.name }}</div>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="newArea.name" label="Name"></v-text-field>
          <v-row>
            <v-col cols="6" class="text-center">
              <v-btn :disabled="!newArea.name" v-if="mode === 'create'" rounded @click="addArea" color="#50cd89"
                     width="100%" class="white--text">
                Create
              </v-btn>
              <v-btn :disabled="!newArea.name" v-else rounded @click="updateArea" color="#50cd89" width="100%"
                     class="white--text">Update
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn rounded @click="addOrEditAreaDialog = false" color="#ffa600" width="100%" class="white--text">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps';
import {getAdminPermissions} from "@/middleware/hasPermission";

export default {
  name: "HotZones",

  data() {
    return {
      paths: [],
      markers: [],
      pathsData: {},
      guideDialog: false,
      center: {lat: 49.252646732280425, lng: -123.02589291692055},
      mapOptions: {
        disableDefaultUI: true,
        zoomControl: true,
      },
      mvcPaths: null,
      selectedPath: null,
      colors: [
        '#F44336', '#9C27B0', '#03A9F4', '#009688', '#4CAF50', '#FFEB3B', '#FF9800', '#FF5722', '#E91E63',
        '#673AB7', '#3F51B5', '#2196F3', '#00BCD4', '#8BC34A', '#CDDC39', '#FFC107', '#2196F3', '#795548', '#607D8B'
      ],
      deleteAreaDialog: false,
      addOrEditAreaDialog: false,
      newArea: {
        name: null,
      },
      mode: 'create' // or edit
    }
  },

  mounted() {
    this.getAreas()
  },

  methods: {
    addPointToArea(e) {
      if (this.selectedPath !== null) {
        if (!this.paths[this.selectedPath]) {
          this.$set(this.paths, this.selectedPath, [])
        }
        if (!this.markers[this.selectedPath] || !this.markers[this.selectedPath].lat) {
          this.$set(this.markers, this.selectedPath, {lat: e.latLng.lat(), lng: e.latLng.lng()})
        }
        let updatedData = this.paths[this.selectedPath]
        updatedData.push(
            {lat: e.latLng.lat(), lng: e.latLng.lng()}
        )
        this.$set(this.paths, this.selectedPath, updatedData)
      } else {
        this.openSnackbar('Area not selected!', '#F1416C')
      }
    },

    pathsChanged: function (mvcPaths, index) {
      let paths = [];
      for (let i = 0; i < mvcPaths.getLength(); i++) {
        for (let j = 0; j < mvcPaths.getAt(i).getLength(); j++) {
          let point = mvcPaths.getAt(i).getAt(j);
          paths.push({lat: point.lat(), lng: point.lng()});
        }
      }

      this.$set(this.paths, index, paths)
      this.$set(this.markers, index, paths[0])
    },

    deletePath: function (e, index) {
      if (e.vertex != 0) {
        this.$refs['polygon' + index][0].$polygonObject.getPaths()
            .getAt(e.path)
            .removeAt(e.vertex)
      } else {
        this.openSnackbar('You can not delete the first point!', '#F1416C')
      }
    },

    getColor(index) {
      return this.colors[index] ?? "#fff"
    },

    selectPath(index) {
      if (getAdminPermissions().includes('update_hot_zone')) {
        this.selectedPath = index
      }
    },

    prepareAddArea() {
      this.addOrEditAreaDialog = true
      this.mode = 'create'
    },

    addArea() {
      this.selectedPath = Object.keys(this.paths).length;
      this.$set(this.paths, this.selectedPath, [])
      this.$set(this.markers, this.selectedPath, null)
      this.$set(this.pathsData, this.selectedPath, {
        name: this.newArea.name,
      })
      this.newArea.name = null
      this.addOrEditAreaDialog = false
    },

    saveAreas() {
      let areas = [];
      this.paths.forEach((item, index) => {
        areas.push({
          coordinates: item,
          id: this.pathsData[index]['id'],
          name: this.pathsData[index]['name'],
        })
      })
      this.$store.dispatch('updateHotZone', {areas}).then(() => {
        this.openSnackbar('Hot Zones Updated', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },

    getAreas() {
      this.$store.dispatch('getHotZone', this.paths).then((res) => {
        res.data.data.forEach((item, index) => {
          this.$set(this.paths, index, item.coordinates)
          this.$set(this.markers, index, item.coordinates[0])
          this.$set(this.pathsData, index, {
            id: item.id,
            name: item.name,
          })
        })
        if (this.paths.length) {
          this.selectedPath = 0
        }
      });
    },

    prepareDeleteArea(path) {
      this.deleteAreaDialog = true
      this.selectedPath = path
    },

    deleteArea() {
      this.$delete(this.paths, this.selectedPath)
      this.$delete(this.markers, this.selectedPath)
      this.selectedPath = null
      this.deleteAreaDialog = false
    },

    prepareEditArea(index) {
      this.addOrEditAreaDialog = true
      this.newArea.name = this.pathsData[index].name
      this.mode = 'edit'
      this.editingAreaIndex = index
    },

    updateArea() {
      this.pathsData[this.editingAreaIndex].name = this.newArea.name
      this.openSnackbar(this.newArea.name + ' Updated!', '#50cd89')
      this.newArea.name = null
      this.addOrEditAreaDialog = false
    }
  },
  computed: {
    google: gmapApi,
  },
}
</script>

<style scoped>
.map-guide-dialog-btn {
  box-shadow: none !important;
  letter-spacing: normal;
  position: absolute;
  right: 0 !important;
  z-index: 99;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 15px !important;
}
</style>