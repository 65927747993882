<template>
  <v-row class="mb-10 mt-10 rounded-15 mlr-1">
    <v-col cols="12" xl="3" lg="3" md="3">
      <v-text-field label="Search"
                    v-model="needle">
      </v-text-field>
    </v-col>
    <v-col cols="12" xl="3" lg="3" md="3">
      <v-select v-can="'index_permission'"
                color="#00b2ff"
                label="Permissions"
                v-model="permission_ids"
                :items="permissions"
                clearable
                multiple
                item-text="name"
                item-value="id">
      </v-select>
    </v-col>
    <v-col cols="12">
      <v-btn rounded @click="search" color="#00b2ff"
             class="white--text">
        Search
      </v-btn>
      <v-btn rounded @click="reset" color="#ffa600"
             class="white--text ml-3">
        Reset
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "RolesFilter",

  props: ['permissions'],

  data() {
    return {
      needle: null,
      permission_ids: [],
    }
  },
  methods: {
    search() {
      this.$emit('filter-applied', {
        needle: this.needle,
        permission_ids: this.permission_ids,
      })
    },
    reset() {
      this.needle = null
      this.permission_ids = []
      this.search()
    }
  }
}
</script>

<style scoped>

</style>