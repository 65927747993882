<template>
  <div>
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-row class="mt-16 align-content-center">
      <v-col cols="12">
        <v-row class="mt-16 justify-center align-content-center">
          <v-col cols="12" xl="4" lg="4" md="6" sm="12" class="pl-10 pr-10">
            <v-toolbar-title class="title-styles font-weight-bold" style="text-align: center; font-size: x-large">Forgot
              Password?
              <br>
              <div class="mt-6 text-decor">Enter your email to reset your password.</div>
            </v-toolbar-title>
            <v-text-field
                dense
                rounded
                filled
                hide-details
                v-model="admin.email"
                class="elevation-0 mt-12"
                :rules="rules.required"
                label="Email"
            ></v-text-field>
            <v-btn class="mt-12 white--text"
                   block
                   x-large
                   color="#00b2ff"
                   rounded @click="forgetPassword()">
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ForgetPassword",
  data() {
    return {
      admin: {
        email: null,
        password: null,
      },
      rules: {
        required: [(value) => !!value || "this field is required"]
      }
    }
  },
  methods: {
    forgetPassword() {
      this.$store.dispatch("forgetPassword", {
        email: this.admin.email,
      }).then(() => {
        this.openSnackbar('Reset Password Link Sent. Please Check Your Email.', '#50cd89')
      }).catch((err) => {
        console.log(err)
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  }
}
</script>

<style scoped>
.btn {
  text-decoration-color: white !important;
  height: 5.3vh !important;
  font-size: x-large;
}

.font-color {
  color: #00b2FF;
  cursor: pointer;
}

.text-decor {
  color: #A1A5B7;
  font-size: medium;
}
</style>