<template>
  <v-row class="mb-10 mt-10 rounded-15 mlr-1">
    <v-col cols="12" xl="3" lg="3" md="3">
      <v-text-field label="Search"
                    v-model="needle">
      </v-text-field>
    </v-col>
    <v-col cols="12" xl="3" lg="3" md="3">
      <v-select v-can="'index_role'"
                color="#00b2ff"
                label="Role"
                v-model="role_id"
                :items="roles"
                clearable
                item-text="name" item-value="id">
      </v-select>
    </v-col>
    <v-col cols="12">
      <v-btn rounded @click="search" color="#00b2ff"
             class="white--text">
        Search
      </v-btn>
      <v-btn rounded @click="reset" color="#ffa600"
             class="white--text ml-3">
        Reset
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AdminsFilter",

  props: ['roles'],

  data() {
    return {
      needle: null,
      role_id: null,
    }
  },

  methods: {
    search() {
      this.$emit('filter-applied', {
        needle: this.needle,
        role_id: this.role_id,
      })
    },
    reset() {
      this.needle = null
      this.role_id = null
      this.search()
    }
  }
}
</script>

<style scoped>

</style>