import {MapElementFactory} from "vue2-google-maps";

export default MapElementFactory({
    name: "DirectionRendererSingle",
    ctr() {
        return window.google.maps.DirectionsRenderer;
    },
    events: [],
    mappedProps: {},
    props: {
        origin: {type: [Object, Array]},
        destination: {type: [Object, Array]},
        travelMode: {type: String},
        color: {type: String}
    },

    afterCreate(directionsRenderer) {
        let directionsService = new window.google.maps.DirectionsService();

        let {origin, destination, travelMode} = this;
        if (!origin || !destination || !travelMode) {
            directionsRenderer.set('directions', null);
        } else {
            directionsService.route(
                {
                    origin,
                    destination,
                    travelMode,
                },
                (response, status) => {
                    if (status !== "OK") return;
                    directionsRenderer.setDirections(response);
                    directionsRenderer.setOptions({
                        preserveViewport: false,
                        polylineOptions: {
                            strokeColor: this.color ?? 'blue'
                        }
                    });
                }
            );
        }
    },
});