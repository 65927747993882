<template>
  <div>
    <div class="center-screen">
      <h2 class="mb-6">
        403 Unauthorized
      </h2>
      <v-icon color="#F1416C" size="200">mdi-block-helper</v-icon>
      <p class="mt-6">
        Sorry ! You don't have appropriate permission to visit this page :(
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnAuthorized",
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.center-screen {
  width: 100%;
  height: 100vh;
  text-align: center;
  align-content: center !important;
  display: grid !important;
}
</style>
