<template>
  <v-row class="mb-10 mt-10 rounded-15 mlr-1">
    <v-col cols="12" xl="3" lg="3" md="3">
      <v-text-field label="Search"
                    v-model="needle">
      </v-text-field>
    </v-col>
    <v-col cols="12" xl="2" lg="2" md="3">
      <v-text-field label="Search Value"
                    v-model="value">
      </v-text-field>
    </v-col>
    <v-col cols="12">
      <v-btn rounded @click="search" color="#00b2ff"
             class="white--text">
        Search
      </v-btn>
      <v-btn rounded @click="reset" color="#ffa600"
             class="white--text ml-3">
        Reset
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "VehicleTypesFilter",

  props: ['roles'],

  data() {
    return {
      needle: null,
      value:null,
    }
  },

  methods: {
    search() {
      this.$emit('filter-applied', {
        needle: this.needle,
        value:this.value,
      })
    },
    reset() {
      this.needle = null
      this.value=null
      this.search()
    }
  }
}
</script>

<style scoped>

</style>