<template>
  <v-row>
    <v-col class="pb-0" cols="4">
      <v-autocomplete
          hide-details
          v-model="filter_driver"
          :items="drivers"
          :search-input.sync="driverInput"
          no-filter
          hide-selected
          clearable
          return-object
          label="Driver"
          @click:clear="filter_driver = null; drivers = []"
          @change="filterBy">
        <template slot="item" slot-scope="data">
          {{ data.item.first_name }} {{ data.item.last_name }} ({{ data.item.phone_number }})
        </template>
        <template slot="selection" slot-scope="data">
          {{ data.item.first_name }} {{ data.item.last_name }} ({{ data.item.phone_number }})
        </template>
      </v-autocomplete>
    </v-col>
    <v-col class="pb-0" cols="4">
      <v-autocomplete
          hide-details
          v-model="filter_passenger"
          :items="passengers"
          :search-input.sync="passengerInput"
          no-filter
          hide-selected
          clearable
          return-object
          label="Passenger"
          @click:clear="filter_passenger = null; passengers = []"
          @change="filterBy">
        <template slot="item" slot-scope="data">
          {{ data.item.name }} ({{ data.item.phone_number }})
        </template>
        <template slot="selection" slot-scope="data">
          {{ data.item.name }} ({{ data.item.phone_number }})
        </template>
      </v-autocomplete>
    </v-col>
    <v-col class="pb-0" cols="4">
      <v-select label="Status"
                hide-details
                :items="states"
                clearable
                v-model="filter_status"
                @change="filterBy">
      </v-select>
    </v-col>
    <v-col class="pt-0" cols="12">
      <v-checkbox hide-details v-model="filter_only_emergencies" @change="filterBy" label="Only Emergencies"></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ActiveTripsFilter",
  props: ["trips", "statuses"],
  data() {
    return {
      filteredTrips: null,

      filter_driver: null,
      filter_passenger: null,
      filter_status: null,
      filter_only_emergencies: false,

      states: [
        {text: 'idle', value: 'idle'},
        {text: 'Searching For Ride', value: 'searching_ride'},
        {text: 'Driver Going To Passenger', value: 'wait_for_ride'},
        {text: 'In Trip', value: 'in_trip'},
        {text: 'Trip Finished', value: 'trip_finished'},
        {text: 'Cancelled By User', value: 'cancelled_by_user'},
        {text: 'Cancelled By Driver', value: 'cancelled_by_driver'},
      ],

      drivers: [],
      driverInput: null,
      driverDebounce: null,

      passengers: [],
      passengerInput: null,
      passengerDebounce: null,
    }
  },

  watch: {
    driverInput(val) {
      if (val) {
        clearTimeout(this.driverDebounce)
        this.driverDebounce = setTimeout(() => {
          this.$store.dispatch("getDrivers", '?needle=' + val).then((res) => {
            this.drivers = res
          })
        }, 1000)
      } else {
        clearTimeout(this.driverDebounce)
        this.drivers = []
      }
    },
    passengerInput(val) {
      if (val) {
        clearTimeout(this.passengerDebounce)
        this.passengerDebounce = setTimeout(() => {
          this.$store.dispatch("getUsers", '?needle=' + val).then((res) => {
            this.passengers = res
          })
        }, 1000)
      } else {
        clearTimeout(this.passengerDebounce)
        this.passengers = []
      }
    },
  },

  methods: {
    filterBy() {
      let data = this.trips

      if (this.filter_driver !== null) {
        data = data.filter(item => item.trip.driver_id === this.filter_driver.id);
      }
      if (this.filter_passenger !== null) {
        data = data.filter(item => item.trip.user_id === this.filter_passenger.id);
      }
      if (this.filter_status !== null) {
        data = data.filter(item => item.trip.states === this.filter_status);
      }
      if (this.filter_only_emergencies !== false) {
        data = data.filter(item => item.trip.emergency_by !== null);
      }

      this.$emit('filter-applied', data)
    },
  },
}
</script>