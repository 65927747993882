<template>
  <div>
    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Activities Log</div>
        <!--SNACK BAR  -->
        <div class="text-center">
          <v-snackbar
              multi-line
              width="500px"
              centered
              top
              v-model="snackbarStatus"
              :color="snackbarColor"
          >
            <p v-html="snackbarText" class="snackbar-p"></p>
            <template v-slot:action="{ attrs }">
              <v-btn
                  fab
                  text
                  v-bind="attrs"
                  @click="snackbarStatus = false"
              >
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>
        <!--SHOW ACTIVITY DIALOG-->
        <v-dialog v-if="selectedActivity" v-model="ShowActivitiesDetailsDialog" min-width="20vw" centered width="500px" overlay-color="black">
            <v-card>
              <v-card-actions class="userTrips-close-dialog">
                <v-spacer></v-spacer><v-icon large color="#F1416C" @click="ShowActivitiesDetailsDialog=false">mdi-close</v-icon>
              </v-card-actions>
              <v-card-title class="dialog-user-Trips-text">
              </v-card-title>
              <v-card-text>
            {{this.selectedActivity.data}}
              </v-card-text>
            </v-card>
          </v-dialog>

        <!--DELETE ALL ACTIVITIES-->
        <v-dialog v-model="deleteActivitiesDialog" centered min-width="25vw" width="500px">
          <v-card>
            <v-card-title class="delete-dialog-text">
              Are you sure about deleting activities log ?
            </v-card-title>
            <v-card-text>
              <v-row class="mt-4">
                <v-col cols="6" class="text-center">
                  <v-btn block rounded @click="deleteActivities" color="#F1416C" width="100%"
                         class="white--text">
                    Delete
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteActivitiesDialog = false" color="#50cd89" width="100%"
                         class="white--text">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Activities TABLE-->
        <v-row>
          <v-col cols="12">
            <activities-filter  v-on:filter-applied="applyFilter"></activities-filter>
            <v-btn v-can="'delete_activity'" color="#F1416C" class="white--text mb-4"
                   @click="deleteActivitiesDialog=true">
              Delete all activities log
            </v-btn>
            <v-data-table
                align="center"
                :headers="headers"
                :items="activities.data"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @update:sort-by="applySort"
                @update:sort-desc="applySort"
                :items-per-page="10"
                class="elevation-5"
                :custom-sort="(items) => items"
                hide-default-footer>
              <template
                  v-slot:[`item.created_at`]="{ item }">
                <span>{{ item.created_at}}</span>
              </template>
              <template
                  v-slot:[`item.updated_at`]="{ item }">
                <span>{{ item.updated_at}}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn v-can="'show_activity'" class="mx-2" fab color="#00b2ff" x-small elevation="0"
                       @click="showActivityDetail(item)">
                  <v-icon color="white">mdi-paperclip</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
          <v-col col="3"></v-col>
          <v-col cols="6">
            <v-pagination
                color="#00b2ff"
                v-model="activities.current_page"
                :length="activities.last_page"
                circle
                @input="getActivities"
            ></v-pagination>
          </v-col>
          <v-col col="3"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {getAdminPermissions} from "@/middleware/hasPermission";
import ActivitiesFilter from "@/components/Activities/ActivitiesFilter";
export default {
  name: "ActivitiesIndex",
  components:{ActivitiesFilter},
  data() {
    return {
      created_at: null,
      selectedActivities: null,
      deleteActivitiesDialog: false,
      filterParams: null,
      sortBy: null,
      sortDesc: false,
      sortParams: null,
      activities: {
        data: []
      },
      headers: [
        {text: 'Name', value: 'admin.name', align: 'center'},
        {text: 'API', value: 'api', align: 'center'},
        {text: 'Method', value: 'method', align: 'center'},
        {text: 'Action', value: 'action', align: 'center'},
        {text: 'Created at', value: 'created_at', align: 'center'},
        {text: 'Updated at', value: 'updated_at', align: 'center'},
        {text: 'Show', value: 'actions', align: 'center', sortable: false},
      ],
      ShowActivitiesDetailsDialog:false,
      selectedActivity:null,
    }
  },
  mounted() {
    if (this.ShowActivitiesDetailsDialog === 'true') {
      this.ShowActivitiesDetails()
    }
    let adminPermissions = getAdminPermissions()
    if (adminPermissions.includes('index_goal')) {
      this.getActivities()
    }
  },
  methods: {
    applySort() {
      if (this.sortBy && this.sortDesc !== undefined) {
        let sortDirection = this.sortDesc ? 'desc' : 'asc';
        this.sortParams = `&sort[field]=${this.sortBy}&sort[direction]=${sortDirection}`
      } else {
        this.sortParams = null;
      }
      this.getActivities()
    },
    makeQueryParameters() {
      if (this.filterParams != null && this.sortParams != null) {
        return this.filterParams + this.sortParams
      } else if (this.filterParams != null) {
        return this.filterParams;
      } else if (this.sortParams != null) {
        return this.sortParams
      }
      return null
    },
    showActivityDetail(item) {
      this.selectedActivity = item
      this.ShowActivitiesDetailsDialog = true
      this.ShowActivitiesDetails()
    },
    async getActivities() {
      await this.$store.dispatch("getActivities", {
        page: this.activities.current_page,
        filter: this.makeQueryParameters()
      }).then((res) => {
        this.activities = res
      }).catch((err) => {
        console.log(err.response.data)
      })
    },
    deleteActivities() {
      this.$store.dispatch('deleteActivities').then(() => {
        this.getActivities()
        this.deleteActivitiesDialog = false
        this.openSnackbar('Activities Deleted Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
    applyFilter(params) {
      this.activities.current_page = 1
      this.filterParams = this.objectParametersToString(params)
      this.getActivities()
    },
    ShowActivitiesDetails() {
      this.$store.dispatch('ShowActivitiesDetails', this.selectedActivity.id).then((res) => {
        console.log(res)
        this.activity = res
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  }
}
</script>

<style scoped>

</style>