<template>
  <div v-if="admin" class="mt-16">
    <v-row class="mt-16 ml-1 mr-1" v-can="['show_admin']">
      <v-col cols="12">
        <div class="text-center title-container">Admin Details</div>
        <v-row class="mlr-1">
          <v-col cols="12" class="pa-0">
            <v-btn text @click="$router.replace('/admins')" class="pa-0">
              <v-icon x-large color="#00b2ff">mdi-arrow-left</v-icon>
              &nbsp; Admins List
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mx-5">
          <v-col cols="12">
            <div class="mt-5 mb-10">
              <h2 class="section-title">Personal Information</h2>
              <v-divider></v-divider>
            </div>
            <v-row>
              <v-col v-if="admin.name" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Full Name:</span> &nbsp;{{ admin.name }}
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Email:</span> &nbsp;{{ admin.email }}
              </v-col>
              <v-col v-if="admin.email_verified_at" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Email Verified At:</span>
                &nbsp;{{ admin.email_verified_at.substring(0, 10) }}
              </v-col>
              <v-col v-if="admin.created_at" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Created At:</span> &nbsp;{{ admin.created_at }}
              </v-col>
              <v-col v-if="admin.updated_at" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Updated At:</span> &nbsp;{{ admin.updated_at }}
              </v-col>
            </v-row>
            <div v-if="admin.role" class="mt-16 mb-10">
              <h2 class="section-title">Role Information</h2>
              <v-divider></v-divider>
            </div>
            <v-row v-if="admin.role">
              <v-col v-if="admin.role.name" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Role Name:</span> &nbsp;{{ admin.role.name }}
              </v-col>
              <v-col v-if="admin.role.description" cols="12" xl="3" lg="3" md="6" sm="12">
                <span class="title-font-size">Description:</span> &nbsp;{{ admin.role.description }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import {getAdminPermissions} from "@/middleware/hasPermission";

export default {
  name: "ShowAdminDetails",
  data() {
    return {
      admin: null,
    }
  },
  mounted() {
    let adminPermissions = getAdminPermissions()
    if (adminPermissions.includes('show_admin')) {
      this.showAdminDetails()
    }
  },
  methods: {
    showAdminDetails() {
      this.$store.dispatch('showAdminDetails', this.$route.params.id).then((res) => {
        this.admin = res.data.data
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  },
}
</script>

<style scoped>
.section-title {
  width: fit-content;
}

.title-font-size {
  font-size: 18px
}
</style>