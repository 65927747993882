<template>
<div>
<goals-crud></goals-crud>
</div>
</template>

<script>
import GoalsCrud from "@/components/Goals/GoalsCrud";
export default {
  name: "GoalsSetting",
  components:{GoalsCrud},
}
</script>

<style scoped>

</style>