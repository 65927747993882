import { render, staticRenderFns } from "./ServicesNotificationIndex.vue?vue&type=template&id=5bfb396b&scoped=true&"
import script from "./ServicesNotificationIndex.vue?vue&type=script&lang=js&"
export * from "./ServicesNotificationIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bfb396b",
  null
  
)

export default component.exports