<template>
  <div>
    <!--SNACK BAR-->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <!--MAIN CONTENT-->
    <v-row class="mt-16">
      <v-col cols="12">
        <!-- GUIDE DIALOG-->
        <div class="text-center title-container">User Initial Data</div>
        <v-dialog v-model="guideDialog" width="500px">
          <v-card rounded color="white" class="text--black">
            <v-card-title><img
                src="../../assets/earth-mark.svg" width="35px" height="35px" alt="help"/>How to work
            </v-card-title>
            <v-card-text>
              <ul>
                <li>Left Click : Add a marker</li>
                <li>Right Click : Remove a marker</li>
              </ul>
              <p class="mt-6" style="text-align: justify">
                Select a point from bottom of screen and add or modify that point in the map.
              </p>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!--MAP-->
        <v-row>
          <v-col cols="12" xl="12" lg="12">
            <v-btn color="#8bdcff" @click="guideDialog=true" class="my-0 py-0 map-guide-dialog-btn"><img
                src="../../assets/story-map-earth.svg" width="35px" height="35px" alt="help"/>&nbsp;How to work
            </v-btn>
            <GmapMap
                :clickable="true"
                :center="center"
                :zoom="12"
                map-type-id="terrain"
                style="width: 100%; height: 650px"
                :options="mapOptions"
                @click="addPoint"
            >
              <gmap-marker
                  v-for="(marker, index) in markers"
                  :key="index + 'm'"
                  :label="index === 'north_east' ? '1' : '2'"
                  :position="marker"
                  :draggable="false"
                  :animation="2">
              </gmap-marker>
            </GmapMap>
          </v-col>
        </v-row>
      </v-col>
      <!--CRUD-->
      <v-col cols="12">
          <v-col cols="12">
            support link for passengers
            <v-textarea outlined v-model="supportLink"></v-textarea>
          </v-col>
        <v-col cols="12">
          <v-chip
              v-for="(_, index) in markers" :key="index"
              @click="selectMarker(index)"
              :color="selectedMarker == index ? '#50cd89' : ''"
              style="justify-content: center">
            {{ index === 'north_east' ? '1 : North East' : '2 : South West' }}
          </v-chip>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn rounded v-can="'update_user_initial_data'" color="#50cd89" small @click="saveInitData">
            Save
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps';
import {getAdminPermissions} from "@/middleware/hasPermission";

export default {
  name: "UserInitData",

  data() {
    return {
      markers: {
        north_east: null,
        south_west: null,
      },
      supportLink: null,
      guideDialog: false,
      center: {lat: 49.252646732280425, lng: -123.02589291692055},
      mapOptions: {
        disableDefaultUI: true,
        zoomControl: true,
      },

      selectedMarker: null,
    }
  },

  mounted() {
    this.getUserInitData()
  },

  methods: {
    addPoint(e) {
      if (this.selectedMarker !== null) {

        let count = 0
        Object.entries(this.markers).forEach((item) => {
          if (item.lat) {
            count = count + 1
          }
        })
        if (count >= 4) {
          this.openSnackbar('You can only select up to maximum of 4 points!', '#F1416C')
        }

        this.$set(this.markers, this.selectedMarker, {lat: e.latLng.lat(), lng: e.latLng.lng()})
      } else {
        this.openSnackbar('Please select a point from bottom of screen', '#F1416C')
      }
    },

    selectMarker(index) {
      if (getAdminPermissions().includes('update_user_initial_data')) {
        this.selectedMarker = index
      }
    },

    saveInitData() {
      let data = {
        support_link: this.supportLink,
        north_east_latitude: String(this.markers.north_east.lat),
        north_east_longitude: String(this.markers.north_east.lng),
        south_west_latitude: String(this.markers.south_west.lat),
        south_west_longitude: String(this.markers.south_west.lng),
      }
      this.$store.dispatch('updateUserInitData', data).then(() => {
        this.openSnackbar('User Initial Data Updated', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },

    getUserInitData() {
      this.$store.dispatch('getUserInitData', this.paths).then((res) => {
        this.supportLink = res.data.data.supportLink
        this.markers.north_east = {
          lat: Number(res.data.data.bounds.north_east_latitude),
          lng: Number(res.data.data.bounds.north_east_longitude)
        }
        this.markers.south_west = {
          lat: Number(res.data.data.bounds.south_west_latitude),
          lng: Number(res.data.data.bounds.south_west_longitude)
        }
      });
    },
  },
  computed: {
    google: gmapApi,
  },
}
</script>

<style scoped>
.map-guide-dialog-btn {
  box-shadow: none !important;
  letter-spacing: normal;
  position: absolute;
  right: 0 !important;
  z-index: 99;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 15px !important;
}
</style>