<template>
  <div>
    <!--  SNACK BAR  -->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          top
          v-model="snackbarStatus"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              fab
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-row class="mt-16 ml-1 mr-1">
      <v-col cols="12">
        <div class="text-center title-container">Goals</div>
        <!--DELETE Goal DIALOG-->
        <v-dialog v-if="selectedGoal" v-model="deleteGoalDialog" centered min-width="25vw" width="500px">
          <v-card>
            <v-card-title class="delete-dialog-text">
              Are you sure about deleting {{ selectedGoal.title }} ?
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteGoal" color="#F1416C" width="100%"
                         class="white--text">
                    Delete
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn rounded @click="deleteGoalDialog = false" color="#50cd89" width="100%" class="white--text">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!--CREATE OR UPDATE DIALOG-->
        <v-dialog style="align-items: center" v-model="createOrUpdateGoalDialog"
                  width="500px"
                  overlay-color="black">
          <v-card>
            <v-card-title>
              <span>{{ mode === 'create' ? 'Add New Goal' : 'Edit ' + selectedGoal.title }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-col cols="12">
                    <v-text-field color="#00b2ff" label="Title" v-model="newGoal.title"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field color="#00b2ff" label="Title Button" v-model="newGoal.title_button"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select color="#00b2ff" label="Action Type" v-model="newGoal.action_type" :items="actionTypes"
                              item-text="label" item-value="name"></v-select>

                  </v-col>
                  <v-col cols="12">
                    <v-text-field color="#00b2ff" label="Count" v-model="newGoal.count"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input color="#00b2ff" label="Start Image" v-model="newGoal.start_image"></v-file-input>
                    <v-file-input color="#00b2ff" label="End Image" v-model="newGoal.end_image"></v-file-input>
                    <v-file-input color="#00b2ff" label="Qr Code Image" v-model="newGoal.qr_code"></v-file-input>
                  </v-col>
                  <!--                SHOW SAVED IMAGES-->
                  <v-row v-if="mode === 'update'" class="mx-16">
                    <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                      <div>Start Image</div>
                      <img width="100%" class="image-size" :src="selectedGoal.start_image">
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                      <div>End Image</div>
                      <img width="100%" class="image-size" :src="selectedGoal.end_image">
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                      <div>Qr Code Image</div>
                      <img width="100%" class="image-size" :src="selectedGoal.qr_code">
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="text-center">
                      <v-btn rounded v-if="mode === 'create'" @click="createGoal()" color="#50cd89" width="100%"
                             class="white--text">
                        Create
                      </v-btn>
                      <v-btn rounded v-if="mode === 'update'" @click="updateGoal()" color="#ffa600" width="100%"
                             class="white--text">
                        Update
                      </v-btn>
                    </v-col>
                    <v-col cols="6" class="text-center">
                      <v-btn rounded @click="createOrUpdateGoalDialog = false"
                             color="#F1416C" width="100%" class="white--text">
                        Cancel
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!--ADD NEW GOAL BTN-->
        <v-row class="mlr-1" v-can="['index_goal','index_action_types', 'create_goal']">
          <v-col cols="12" class="pa-0">
            <v-btn text @click="prepareCreateGoal()" class="pa-0">
              <v-icon x-large color="#00b2ff">mdi-plus</v-icon>
              Add New Goal
            </v-btn>
          </v-col>
        </v-row>

        <goals-filter :action_types="actionTypes" v-on:filter-applied="applyFilter"></goals-filter>
        <!--GOALS TABLE-->
        <v-data-table
            align="center"
            :custom-sort="(items) => items"
            :headers="headers"
            :items="goals.data"
            :items-per-page="10"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @update:sort-by="applySort"
            @update:sort-desc="applySort"
            class="elevation-5"
            hide-default-footer>
          <template
              v-slot:[`item.show_start_image`]="{ item }">
            <img width="100%" class="image-size" :src="item.start_image">
          </template>
          <template
              v-slot:[`item.show_end_image`]="{ item }">
            <img width="100%" class="image-size" :src="item.end_image">
          </template>
          <template
              v-slot:[`item.show_qr_code`]="{ item }">
            <img width="100%" class="image-size" :src="item.qr_code">
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn class="mx-2" fab color="#ffa600" x-small elevation="0" @click="editGoal(item)"
                   v-can="['update_goal','index_action_types']">
              <v-icon color="white">mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="mx-2" fab color="#F1416C" x-small elevation="0" @click="deleteGoalPermission(item)"
                   v-can="'destroy_goal'">
              <v-icon color="white">mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
      <v-col col="3"></v-col>
      <v-col cols="6">
        <v-pagination
            color="#00b2ff"
            v-model="goals.current_page"
            :length="goals.last_page"
            circle
            @input="getGoals"
        ></v-pagination>
      </v-col>
      <v-col col="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import GoalsFilter from "@/components/Goals/GoalsFilter";
import {getAdminPermissions} from "@/middleware/hasPermission";

export default {
  name: "GoalsCrud",
  components: {GoalsFilter},
  data() {
    return {
      goals: {
        data: [],
        actionTypes: null,
      },
      sortBy: null,
      sortDesc: false,
      sortParams: null,
      headers: [
        {text: '#', value: 'id', align: 'center'},
        {text: 'Title', value: 'title', align: 'center'},
        {text: 'Action Type', value: 'action_type', align: 'center'},
        {text: 'Count', value: 'count', align: 'center'},
        {text: 'Start Image', value: 'show_start_image', align: 'center', sortable: false},
        {text: 'End Image', value: 'show_end_image', align: 'center', sortable: false},
        {text: 'Qr Code Image', value: 'show_qr_code', align: 'center', sortable: false},
        {text: 'Title Button', value: 'title_button', align: 'center', sortable: false},
        {text: 'Actions', value: 'actions', align: 'center', sortable: false},
      ],
      actionTypes: [],
      selectedGoal: null,
      deleteGoalDialog: false,
      start_image: null,
      end_image: null,
      mode: 'create',
      action_type: null,
      createOrUpdateGoalDialog: null,
      newGoal: {
        title: null,
        title_button: null,
        count: null,
        action_type: null,
        start_image: null,
        end_image: null,
        qr_code: null,
      },
      filterParams: null
    }
  },
  mounted() {
    let adminPermissions = getAdminPermissions()
    if (adminPermissions.includes('index_goal')) {
      this.getGoals()
      this.getActionTypes()
    }
  },
  methods: {
    applySort() {
      if (this.sortBy && this.sortDesc !== undefined) {
        let sortDirection = this.sortDesc ? 'desc' : 'asc';
        this.sortParams = `&sort[field]=${this.sortBy}&sort[direction]=${sortDirection}`
      } else {
        this.sortParams = null;
      }
      this.getGoals()
    },
    makeQueryParameters() {
      if (this.filterParams != null && this.sortParams != null) {
        return this.filterParams + this.sortParams
      } else if (this.filterParams != null) {
        return this.filterParams;
      } else if (this.sortParams != null) {
        return this.sortParams
      }
      return null
    },
    async getGoals() {
      await this.$store.dispatch("getGoals", {
        page: this.goals.current_page,
        filter: this.makeQueryParameters()
      }).then((res) => {
        //console.log(res)
        this.goals = res.data
      }).catch((err) => {
        console.log(err.response.data)
      })
    },
    getActionTypes() {
      this.$store.dispatch("getActionTypes").then((res) => {
        //console.log(res.data, 'action types')
        this.actionTypes = res.data
      }).catch((err) => {
        console.log(err.response.data)
      })
    },
    deleteGoalPermission(goal) {
      this.selectedGoal = goal
      this.deleteGoalDialog = true
    },
    deleteGoal() {
      this.$store.dispatch('deleteGoal', this.selectedGoal.id).then(() => {
        this.getGoals()
        this.deleteGoalDialog = false
        this.openSnackbar(this.selectedGoal.title + ' Deleted Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
    editGoal(goal) {
      this.selectedGoal = goal
      this.mode = 'update'
      this.newGoal.title = goal.title
      this.newGoal.title_button = goal.title_button
      this.newGoal.count = goal.count
      this.newGoal.action_type = goal.action_type
      this.newGoal.start_image = null
      this.newGoal.end_image = null
      this.newGoal.qr_code = null
      this.createOrUpdateGoalDialog = true
    },
    updateGoal() {
      this.$store.dispatch('updateGoal', {
        newGoal: this.newGoal,
        id: this.selectedGoal.id,
      }).then(() => {
        this.getGoals()
        this.createOrUpdateGoalDialog = false
        this.openSnackbar(this.selectedGoal.title + ' Updated Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
    prepareCreateGoal() {
      this.newGoal = {
        title: null,
        title_button: null,
        count: null,
        action_type: null,
        start_image: null,
        end_image: null,
      }
      this.createOrUpdateGoalDialog = true
      this.mode = 'create'
    },
    createGoal() {
      console.log(this.newGoal)
      this.$store.dispatch('createGoal', this.newGoal).then(() => {
        this.getGoals()
        this.createOrUpdateGoalDialog = false
        this.openSnackbar('New Goal Created Successfully', '#50cd89')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      });
    },
    applyFilter(params) {
      this.goals.current_page = 1
      this.filterParams = this.objectParametersToString(params)
      this.getGoals()
    }
  }
}
</script>

<style scoped>
.image-size {
  max-width: 80px;
}
</style>